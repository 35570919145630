import React, { useContext, useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import Constants from '../../Constants';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `FirmTypeFilter` function is a React component that renders a filter for selecting firm types, with
 * options that can be expanded or collapsed.
 * @returns a JSX element.
 */
function FirmTypeFilter({ open, accordionClick, setFirmTypeOptions, FirmTypeOptions, customPathname, dataPath }) {
    const { firmTypeResponseData, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);
    console.log(firmTypeResponseData,"lkjjhkkkg",dataPath)

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.firmType?.map(value => value.id) ?? [];
        setFirmTypeOptions(firmTypeResponseData[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })));
    }, [customPathname, dataPath, firmTypeResponseData, refineSearchFilters, setFirmTypeOptions]);

    const handleFirmTypeChange = (e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentFirmTypes = FirmTypeOptions.map((type) =>
                ({ ...type, isChecked: checked })
            );
            setFirmTypeOptions(currentFirmTypes);
        } else {
            let currentFirmTypes = FirmTypeOptions.map((type) => {
                const isNumeric = /^[0-9]+$/.test(id);
                const parsedId = isNumeric ? parseInt(id) : id;
                return type.id === parsedId
                    ? { ...type, isChecked: checked }
                    : type;
            });
            setFirmTypeOptions(currentFirmTypes);
        }
    };

    const len = FirmTypeOptions?.filter(option => option.isChecked)?.length ?? 0;
console.log(FirmTypeOptions,"lkjjhkkkg 222")
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Firm Type"
                    onClick={accordionClick}
                    open={open}
                    definitionInfo={Constants.firmType}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleFirmTypeChange}
                        primaryEntity={FirmTypeOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    );
}

export default FirmTypeFilter;
