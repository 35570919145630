import React, { useState, useMemo, useEffect, useContext } from "react";
import { useParams, useLocation, matchPath } from 'react-router-dom';
import FundingTableInvestment from '../../Funding/NewFundingInvestmentTable';
import FundingTableExit from '../../Funding/NewExitTable'
import FundingTableAngelInvestment from "../../Funding/NewAngelInvestmentTable";
import FundingTableIncubation from "../../Funding/NewIncubationTable";
import routePathSegments from "../../../routeSegments";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, getCfsFundingApi, getFundingUrl } from "../../../Config/config";
import { FundingContext } from "../../../Contexts/FundingDataProvider";
import FundingTableBackedIpo from "../../Funding/NewBackedIpoTable";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";

const Funding = ({menu,companyName, funding, peCmpId}) => {

  const [openTab, setOpenTab] = React.useState();
  const [showPopup, setShowPopup] = useState(false);
  const { companyId } = useParams();
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { pevcData,existData,angelData,incubationData , ipoData} = useContext(FundingContext);
  const [fundingData, setFundingData] = useState(funding);
  const { pathname, state } = useLocation();
  
  const {getToken, isStudent} = useContext(UserContext);

  useEffect(() => {
    var paths = pathname.split('/')
    var last = paths[paths.length - 2]

    if (last === routePathSegments.incubation) {
      setOpenTab(5);
    } else if (last === routePathSegments.exists) {
      setOpenTab(2);
    } else if (last === routePathSegments.ipos) {
      setOpenTab(3);
    } else if (last === routePathSegments.angel_invesments) {
      setOpenTab(4);
    } else { 
      setOpenTab(1);
    }
  }, [pathname])

  useEffect(()=>{
    if(fundingData?.pe_investments?.length >0 ){
      setOpenTab(1)
    }
    else if (fundingData?.pe_exits?.length >0){
      setOpenTab(2)
    }
    else if (fundingData?.pe_vc_ipos?.length >0){
      setOpenTab(3)
    }
    else if (fundingData?.angel_investments?.length >0){
      setOpenTab(4)
    }
    else if (fundingData?.incubation_investments?.length >0){
      setOpenTab(5)
    }
  },[fundingData])

  /* The above code is defining a constant variable `xlDownloadRequest` using the `useMemo` hook in
  JavaScript. The `useMemo` hook is used to memoize the value of a variable and only recompute it
  when its dependencies change. */
  const xlDownloadRequest = useMemo(() => {
    let sub_section = "vc_investments";

    if (openTab === 2) {
      sub_section = "exits";
    } else if (openTab === 3) {
      sub_section = "ipos";
    } else if (openTab === 4) {
      sub_section = "angel";
    } else if (openTab === 5) {
      sub_section = "incubation";
    }
    return {
      "type": Constants.primaryMasterIds.cfsId,
      "export_from": "detail",
      "module_type": "investments",
      "section": "funding",
      "sub_section": sub_section,
      "company_id": [companyId]
    }
  }, [openTab, companyId])


  useEffect(() => {
    if (openTab === 1) {
      if (pevcData) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 2) {
      if (existData) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 3) {
      if (ipoData) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    else if (openTab === 4) {
      if (angelData) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 5) {
      if (incubationData) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    
  }, [openTab, pevcData, existData, incubationData, angelData])

  // console.log("Checksee", pevcData, existData, incubationData, angelData);

  /* The above code is defining a constant variable called `fileName` using the `useMemo` hook in
  JavaScript. The `useMemo` hook is used to memoize the result of a function so that it is only
  recomputed when its dependencies change. */
  console.log("vada ae machi ",companyId)
  const fileName = useMemo(() => {

    let module = "CFS";

    let fileName = Constants.exportNames.funding_investment(companyName, module);


    if (openTab === 2) {
      fileName = Constants.exportNames.funding_exit(companyName, module);
    } else if (openTab === 3) {
      fileName = Constants.exportNames.funding_ipo(companyName, module);
    } else if (openTab === 4) {
      fileName = Constants.exportNames.funding_angel(companyName);
    } else if (openTab === 5) {
      fileName = Constants.exportNames.funding_incubation(companyName);
    }
    return fileName;
  }, [companyName, openTab])

  const allCountZero = fundingData?.pe_investments?.length <=0 && fundingData?.pe_exits?.length <=0 && fundingData?.pe_vc_ipos?.length <=0 && fundingData?.angel_investments?.length <=0 && fundingData?.incubation_investments?.length <=0

  return (
    <>
      <div className="mx-4 ">
        <div className="bg-white border shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
          <div className=" pt-4 px-5 ">
            <div className="flex justify-between">
              <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">Funding</p>
             

              {isStudent=='false' && showXlDownload && <ExcelDownloader fileName={fileName} data={xlDownloadRequest} url={exportExcel} />}
            </div>
          </div>
          <div className={`flex flex-row overflow-x-auto space-x-9 scrollbar px-5  ${allCountZero ?'':'border-t border-t-[#F1F1F1]'}`}>
          {fundingData?.pe_investments?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 1
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              PE-VC Investments
            </a>}
            {fundingData?.pe_exits?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 2
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              PE-VC Exits
            </a>
            }
            {fundingData?.pe_ipos?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 3
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              PE-VC Backed IPOs
            </a>
            }
            {fundingData?.angel_investments?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 4
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "yellow-500 text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              Angel Investments
            </a>
            }
            {fundingData?.incubation_investments?.length >0 &&
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 5
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "yellow-500 text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(5);
              }}
              data-toggle="tab"
              href="#link5"
              role="tablist"
            >
              Incubation
            </a>}

          </div >
          <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <FundingTableInvestment menu={menu} data={fundingData?.pe_investments} companyId={peCmpId}/>
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <FundingTableExit menu={menu} data={fundingData?.pe_exits} companyId={peCmpId}/>
            </div>
             <div className={openTab === 3 ? "block" : "hidden"} id="link2">
              <FundingTableBackedIpo menu={menu} data={fundingData?.pe_ipos} companyId={peCmpId}/>
            </div> 
            <div className={openTab === 4 ? "block" : "hidden"} id="link3">
              <FundingTableAngelInvestment menu={menu} data={fundingData?.angel_investments} companyFundingId={peCmpId}/>
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link4">
              <FundingTableIncubation menu={menu} data={fundingData?.incubation_investments} companyFundingId={peCmpId}/>
            </div>

        </div>
      </div>
    </>
  )
}

export default Funding