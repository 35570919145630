    import React from 'react';
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faSort } from "@fortawesome/free-solid-svg-icons";

    const TableLoader = () => {
    return (
        <div className="text-center overflow-x-auto md:flex justify-center">
        <div className="mx-4 bg-[aliceblue] text-sm font-sans rounded-lg overflow-hidden md:text-xl md:leading-[3rem] lg:w-4/5">
            <div className="border-b border-[#A26F35]">
            <div className="text-[#A26F35] flex justify-between">
                <div className="py-2 px-8 font-semibold mt-1">Rank</div>
                <div className="text-left font-semibold mt-3">Company Name</div>
                <div className="px-8 font-semibold mt-3">
                #of Deals
                <span
                    className="mx-2 cursor-pointer"
                    // onClick={sortByDealsHandler}
                >
                    <FontAwesomeIcon
                    icon={faSort}
                    size="xs"
                    style={{ color: "#a26f35" }}
                    />
                </span>
                </div>
                <div className="px-8 py-[0.6rem] font-semibold ">
                Amount $M
                <span
                    className="mx-2 cursor-pointer"
                    // onClick={sortByAmountHandler}
                >
                    <FontAwesomeIcon
                    icon={faSort}
                    size="xs"
                    style={{ color: "#a26f35" }}
                    />
                </span>
                </div>
            </div>
            </div>
            <div className='animate-pulse'>
            {[1, 2, 3, 4, 5, 6,7,8,9,10].map((index) => (
                <div
                key={index}
                className="my-2 border-t-2 mx-4 text-[black] h-4 bg-gray-200 mt-3 mb-6 rounded  transition-colors duration-300 transform flex"
                >

                </div>
            ))}
            </div>
        </div>
        </div>
    );
    };

    export default TableLoader;
