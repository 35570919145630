import React, { useContext } from "react";
import SearchGif from "../../images/search.gif";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import moment from "moment";
import { useLocation } from "react-router";

/* NoDataPlaceholder component */
function NoDataPlaceholder({titleMessage="Sorry! No Results Found", message = "You may want to change different date range and check again.", showButton=true}) {

  const { monthPickerValue, refineSearchFilters, setMonthPickerValue } = useContext(PeFilterContext);
  const location = useLocation();
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0;
  const getCurrentModule =
    location.pathname.includes("/re/") || location.pathname.includes("/ma/");

  // Function to calculate date range for given years
  const getLastYears = (years) =>
    moment().subtract(years, "years").format("YYYY-MM");

  // Conditions for years
  const checkForYear = (years) =>
    moment(monthPickerValue[0]).format("YYYY-MM") === getLastYears(years);

  // Button configurations for different years
  const buttonConfig = [
    { label: "3 Years", years: 3, condition: checkForYear(1) },
    { label: "5 Years", years: 5, condition: checkForYear(1) || checkForYear(3) },
    {
      label: "All Years",
      years: getCurrentModule ? 20 : 25,
      condition: checkForYear(1) || checkForYear(5) || checkForYear(3),
    },
  ];

  // Function to set the range based on button click
  const setRange = (years) =>
    setMonthPickerValue([
      moment().subtract(years, "years"),
      moment().startOf("second"),
    ]);

    console.log(showButton, "showButton")

  return (
    <>
      {!isFromGlobalSearch() && showButton ? (
        <div className="flex flex-col items-center justify-center">
          <img className="w-20 h-20" src={SearchGif} alt="" />
          <p className="text-[18px] font-sans_medium_body text-[#333333]">
            {titleMessage}
          </p>
          <p className="text-[14px] font-sans_medium_body text-[#333333] opacity-[.6]">
            {message}
          </p>
            <div className="mt-6 flex items-center justify-center gap-12 text-white text-base">
              {buttonConfig
                .filter((btn) => btn.condition)
                .map((btn, idx) => (
                  <button
                    key={idx}
                    className="px-3 py-[0.10rem] bg-[#BC8B20] rounded-sm text-[13px]"
                    onClick={() => setRange(btn.years)}
                  >
                    {btn.label}
                  </button>
                ))}
            </div>
        </div> 
      ) : (
        <div className="">
          <p className="">
              No data available. Please add items to your list.
          </p>
        </div>
      )
      }
    </>
  );
}

export default NoDataPlaceholder