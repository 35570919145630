import React from 'react';
import './CardsContainer.css';
import ImageCard from "../card/ImageCard";

const CardsContainer = () => {
    const cardData = [
        {
            title: 'Insights',
            description: 'No. of Unicorn Startups in India Has Crossed 100 - View the Full List',
            link: "http://vi-gyan.com/",
            linkText: "Learn More →",
            ds_link: "/Indian-union-tracker",
        },
        {
            title: 'Press Release',
            description: 'Only 1 in 4 startups graduates from Seed to Series A: Report',
            link: "/Press-Release",
            linkText: "Learn More →",
            ds_link: "https://ventureintelligence.blogspot.com/2024/10/only-1-in-4-startups-graduates-from.html",
        },
        {
            title: 'Media Coverage',
            description: 'Venture Money Floods Into Indian Startups - Wall Street Journal (quoting Venture Intelligence data)',
            link: "https://www.google.co.in/search?hl=en&rls=org.mozilla:en-US:official&q=%22Venture+Intelligence%22&um=1&tbs=nws:1&tab=wn&ei=fDsuVYeBOou3uQSs4IDQAg",
            ds_link: "https://www.wsj.com/articles/venture-money-floods-into-indian-startups-1427748367",
            linkText: "Learn More →",
        },
        {
            title: 'Events',
            description: "APEX 25 Summit & Awards - Feb 18, Mumbai; Interact with the Who's Who of the PE-VC Ecosystem",
            link: "/Events",
            ds_link: "https://www.ventureintelligence.com/apex25.html",
            linkText: "Learn More →",
        },
    ];

    return (
        <div className="cards-container mt-8">
            {cardData.map((card, index) => (
                <ImageCard key={index} {...card} />
            ))}
        </div>
    );
};

export default CardsContainer;