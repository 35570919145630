import React,{useContext} from 'react'
import Table from '../components/Table'
import ReInvestmentCard from '../components/ReInvestmentCard'
import RefineSearch from '../../../../Components/RefineSearchComponent'
import FilterTag from '../../../../Components/Investor/Tag'
import Tabbar from '../components/Tabbar'
import Constants from '../../../../Constants'
import ReInvestorExitRefineSearch from '../../../../Components/RefineSearch/ReInvestorExitRefineSearch'
import { useLocation } from 'react-router-dom'
import { PeFilterContext } from '../../../../Contexts/PeFilterContext'

const ReInvestmentsColumns = [
    { accessor: 'Investor', label: 'Investor' },
    { accessor: 'ipos', label: 'IPOs' },
    { accessor: 'last_ipo_company_sort', label: 'Latest IPO' },
    { accessor: 'latest_ipo_size', label: 'Latest IPO Size (US$ M)',className:"justify-center" },
    { accessor: 'add', label: 'Add' },
]

/**
 * The `ReInvestorIpo` function is a React component that renders a page for displaying and filtering
 * real estate investor IPO data.
 * @returns JSX element.
 */
function ReInvestorIpo(props) {
    const {refineSearchFilters} = useContext(PeFilterContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    return (
        <div className='flex min-h-screen h-screen flex-col'>
            <ReInvestmentCard />
            <div className={`h-full min-w-0 flex-1 border-t border-gray-200 flex `}>
                <section aria-labelledby="primary-heading" className="flex min-w-0 flex-1 flex-col lg:order-last">
                    <FilterTag  menu="re-fund"/>
                    <Tabbar
                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                        subTypeMasterId={Constants.primaryMasterSubIds.re_investor_ipo_id}
                        typeId={Constants.moduleIds.investorsId}
                        exportFileName={Constants.exportNames.re_investor_ipos}
                        menu="re-ipo"
                    />
                    <div className='overflow-y-auto'>
                        <Table order="asc" orderBy="Investor" columns={ReInvestmentsColumns} category="re-ipo" sub_type_master_id={Constants.subCategoryIds.re_investor_ipo_id}/>
                    </div>
                </section>
                <aside className="order-first block flex-shrink-0">
                    <div className="relative flex h-[100%] mb-[4.25rem] flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch>
                        <ReInvestorExitRefineSearch customPathname={location.pathname} dataPath={props?.dataPath} menu="re-ipo"/>
                        </RefineSearch>
                    </div>
                </aside>

            </div>
        </div>
    )
}

export default ReInvestorIpo