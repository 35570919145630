import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

const UnicornTableLoader = () => {
return (
    <div className="text-center overflow-x-auto md:flex justify-center">
        <div className="mx-4 bg-[aliceblue] text-sm font-sans rounded-lg overflow-hidden md:text-xl md:leading-[3rem] lg:w-4/5">
            <div className="border-b border-[#A26F35]">
                <div className="text-[#A26F35] flex justify-between">
                        <div className=" px-4md:px-1 lg:px-1 xl:px-1 2xl:px-1">NO</div>
                        <div className="text-left">Company 
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                                    >
                                        <FontAwesomeIcon
                                        icon={faSort}
                                        size="xs"
                                        style={{ color: "#a26f35" }}
                                        />
                                    </span>
                        </div>
                        <div className="px-1">
                                    Sector
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                                        >
                                        <FontAwesomeIcon
                                        icon={faSort}
                                        size="xs"
                                        style={{ color: "#a26f35" }}
                                        />
                                    </span>
                        </div>  
                        <div className="px-1">
                                    Entry Valuation^^ ($B)
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                                        >
                                        <FontAwesomeIcon
                                            icon={faSort}
                                            size="xs"
                                            style={{ color: "#a26f35" }}
                                        />
                                    </span>
                        </div>
                        <div className="">
                                    Valuation ($B)
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                                        >
                                        <FontAwesomeIcon
                                            icon={faSort}
                                            size="xs"
                                            style={{ color: "#a26f35" }}
                                        />
                                    </span>
                        </div>
                        <div className="px-1">
                                    Entry
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                                    >
                                        <FontAwesomeIcon
                                            icon={faSort}
                                            size="xs"
                                            style={{ color: "#a26f35" }}
                                        />
                                    </span>
                        </div>
                        <div className="px-1">
                                    Location
                                    <span
                                        className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"

                                        >
                                            <FontAwesomeIcon
                                                icon={faSort}
                                                size="xs"
                                                style={{ color: "#a26f35" }}
                                            />
                                    </span>
                        </div>
                        <div className="px-1">Select Investors</div>
                </div>
            </div>
            <div className='animate-pulse'>
                {[1, 2, 3, 4, 5, 6,7,8,9,10].map((index) => (
                    <div
                    key={index}
                    className="my-2 border-t-2 mx-4 text-[black] h-4 bg-gray-200 mt-3 mb-6 rounded  transition-colors duration-300 transform flex"
                    >
                </div>
            ))}
            </div>
        </div>
    </div>
);
};

export default UnicornTableLoader;
