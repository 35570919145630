import React from "react";

import bg from "../../../assets/backgrounds/main_grid.png";
import Container from "../../../components/layout/Container";
import heroImg from "../../../assets/FundingHero.jpg";
// import phone from "../../../assets/phone.jpg";
// import groupImg from "../../../assets/groupImg.jpg";

import Customers from "../../../components/common/Customers";

const FundingHeroSec = () => {
  return (
    <div style={{ backgroundImage: `url(${bg})` }}>
      <Container>
        <div className="flex flex-col lg:flex-row lg:gap-8">
          <div className="flex flex-col items-start lg:w-2/5">
            <p className="my-2 text-blue-500 text-xs font-semibold">FUNDING</p>
            <h1 className="my-4 text-3xl leading-snug tracking-tighter font-bold md:text-4xl">
              Get insights on funding rounds as they happen
            </h1>
            <h2 className="my-4">
              Database of Funding Rounds to uncover latest funding trends in
              your mandate
            </h2>
            <button className="my-2 p-2 bg-blue-500 text-white text-sm rounded-md">
              Book a Demo
            </button>
          </div>
          <div className="lg:max-w-lg xl:max-w-2xl">
            <img src={heroImg} alt="" />
            {/* <div>
              <img src={phone} alt="" className="mb-4 w-72 h-80 rounded-lg" />
              <img src={groupImg} alt="" className="w-72" />
            </div> */}
          </div>
        </div>
        <Customers />
      </Container>
    </div>
  );
};

export default FundingHeroSec;
