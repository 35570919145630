import {React , useEffect} from 'react'
import Layout from '../../components/layout'
// import PrCards from './PrCards'
import CardsContainer from './CardsContainer'

const PressRelease = () => {
    
    useEffect(() => {
        // Check if there's a hash in the URL and scroll to the corresponding element
        if (window.location.hash) {
            const targetElement = document.getElementById(window.location.hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <Layout>
            <div className="flex-col mx-5">
                <div className='my-[22px] text-center mb-6'>
                    <h1 className='text-5xl font-medium text-[#a07924] mb-16'>PRESS RELEASES</h1>
                    <h3 className='text-2xl font-medium text-[#475569] '>Venture Intelligence Press Releases:</h3>
                </div>
                {/* <div className='mx-20 flex-col mt-8'> */}
                    {/* <div className="flex justify-between">
                        <PrCards title={'Jun-22'} link={'https://ventureintelligence.blogspot.com/2022/06/seed-series-investments-providing.html'} ds={'Seed & Series A investments providing strong support to Indian Startup ecosystem: Report'} />
                        <PrCards title={'Jan-22'} link={'https://ventureintelligence.blogspot.com/2022/01/pe-vc-investments-in-2021-reach-all.html'} ds={'PE-VC Investments in 2021 hit all time high of $63 Billion'} />
                    </div>
                    <div className="flex justify-between mt-8">
                        <PrCards title={'Feb-23'} link={'https://ventureintelligence.blogspot.com/2023/02/avendus-tops-league-table-for.html'} ds={'Avendus tops League Table for Transaction Advisors to Private Equity deals in 2022'} />
                        <PrCards title={'Mar-17'} link={'https://ventureintelligence.blogspot.com/2017/03/private-equity-backed-cos-outperform.html'} ds={'Private Equity-backed cos. outperform their peers: Study'} />
                    </div> */}
                    
                {/* </div> */}
                <CardsContainer />
            </div>

            <div id="media-coverage" className="flex-col mx-5  mt-[200px] pb-5 bg-[#F5F5F5] rounded-md">
                <div className='my-[22px] text-center  pt-10'>
                    <h1 className='text-5xl font-medium text-[#a07924] mb-10 '>MEDIA COVERAGE</h1>
                    <h3 className='text-2xl font-medium text-[#475569] '>Venture Intelligence Media Coverages:</h3>
                </div>
                <div className='mx-24 my-10 '>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>The Economic Times</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://economictimes.indiatimes.com/markets/stocks/news/pe-exits-drive-down-actual-fdi-in-april-november-2023-24/articleshow/107267623.cms?from=mdr" className='text-lg'>PE exits drive down actual FDI in April-November 2023-24</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'> The Hindu Business Line </p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.thehindubusinessline.com/economy/less-than-1-in-3-start-ups-follow-on-funding-in-series-a-round-venture-intelligence/article66927059.ece" className='text-lg'>Less than 1 in 3 start-ups follow-on funding in Series A round: Venture Intelligence</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>Money Control</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.moneycontrol.com/news/business/economy/inflows-from-private-equity-venture-c-to-domestic-companies-plunge-to-27-9-billion-in-2023-11961991.html" className='text-lg'>Inflows from private equity, venture c to domestic companies plunge to $27.9 billion in 2023</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>The Hindu</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.thehindu.com/news/national/tamil-nadu/pe-and-vc-investments-fell-in-tamil-nadu-during-second-quarter-of-2023/article67031828.ece" className='text-lg'>PE and VC investments fell in Tamil Nadu during second quarter of 2023 </a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>Financial Express</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.financialexpress.com/business/sme/pe-vc-funding-drops-38-in-2023-lowest-in-5-years-report/3354121/" className='text-lg'>PE-VC funding drops 38% in 2023, lowest in 5 years: Report</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>PWC India</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.pwc.in/assets/pdfs/services/startups/start-up-perspectives-india-start-up-deals-tracker-h1-cy23.pdf" className='text-lg'>Start-up Perspectives India start-up deals tracker H1 CY23</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>The Hindu Business Line</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.thehindubusinessline.com/economy/pe-vc-investments-in-q223-decline-33-to-99-b-venture-intelligence-data/article67034588.ece" className='text-lg'>PE-VC investments in Q2’23 decline 33% to $9.9 b: Venture Intelligence data</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>Money Control</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://www.moneycontrol.com/news/business/scott-shleifer-stepping-down-a-blow-but-tiger-zinda-hain-in-india-say-founders-and-vcs-11791261.html" className='text-lg'>Scott Shleifer stepping down a blow but in India Tiger zinda hai, say founders and VCs</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>Times Of India</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://timesofindia.indiatimes.com/city/chennai/pe-vc-inflows-dive-73-yoy-in-november-private-equity-and-venture-capital-news/articleshow/105669276.cms" className='text-lg'>PE-VC inflows dive 73% YoY in November</a> </p>
                    </div>
                    <div className='my-8'>
                        <h1 className='text-2xl text-[#ad7d64]'> <p className='text-[#ad7d64] font-bold'>Your Story</p></h1>
                        <p className='mx-8 my-3 hover:text-[#6c7ddd] text-[#4b596c]'> <a href="https://yourstory.com/2023/06/seed-funded-startups-raise-series-a-capital-venture-intelligence-study" className='text-lg'>ONLY 29% OF SEED-FUNDED STARTUPS RAISE SERIES A CAPITAL: VENTURE INTELLIGENCE </a> </p>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}

export default PressRelease
