import React, { useEffect, useMemo, useState } from 'react'
import { chain } from "lodash";
import downArrow from '../../images/Shape_12/png/Shape.png'
import moment from "moment"
import Loader from '../Loader';
import { useTable, useSortBy } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import { useCubeQuery } from '@cubejs-client/react';
import { cubejsApi } from '../..';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { DealRangeData } from './ReportsMain';
import ThousandSeperator from '../../Utils/ThousandSeperator';


/* The above code is defining a React component called `TableTabTable`. This component is responsible
for rendering a table based on the provided `columnData` and `rowData`. */

function TableTabTable({ columnData = [], rowData = [], isLoading, pvConfig, resultSet, masterData , isRenderingInitial }) {

 /* The above code is defining a function called `columns` using the `useMemo` hook in React. This
 function is responsible for formatting and generating an array of column objects that will be used
 in a table component. */
  const columns = useMemo(() => {
    const formattedColumns = [];
    columnData?.forEach((column, index) => {
      const bgColor = index % 2 === 1 ? "bg-white" : "bg-[#f2f2f2]"
      if (column.children) {
        let label = column.type === "time" ? moment(column.shortTitle).year() : column.shortTitle

        let type = "";

        if (column?.title?.includes('Cyqy')) {
          type = "CYQY"
        } else if (column?.title?.includes('Cyhy')) {
          type = "CYHY"
        } else if (column?.title?.includes('Cymy')) {
          type = "CYMY"
        } else if (column?.title?.includes('Fyqy')) {
          type = "FYQY"
        } else if (column?.title?.includes('Fyhy')) {
          type = "FYHY"
        } else if (column?.title?.includes('Fymy')) {
          type = "FYMY"
        } else if (column?.title?.includes('Fy')) {
          type = "FY"
        }

        if (type === "CYQY") {
          label = masterData?.filter(data => data['DateMasterCombo.cyqy'] == label)?.at(0)?.[`DateMasterCombo.cyqyDesc`]
        } else if (type === "CYHY") {
          label = masterData?.filter(data => data['DateMasterCombo.cyhy'] == label)?.at(0)?.[`DateMasterCombo.cyhyDesc`]
        } else if (type === "CYMY") {
          label = masterData?.filter(data => data['DateMasterCombo.cymy'] == label)?.at(0)?.[`DateMasterCombo.cymyDesc`]
        } else if (type === "FYQY") {
          label = masterData?.filter(data => data['DateMasterCombo.fyqy'] == label)?.at(0)?.[`DateMasterCombo.fyqyDesc`]
        } else if (type === "FYHY") {
          label = masterData?.filter(data => data['DateMasterCombo.fyhy'] == label)?.at(0)?.[`DateMasterCombo.fyhyDesc`]
        } else if (type === "FYMY") {
          label = masterData?.filter(data => data['DateMasterCombo.fymy'] == label)?.at(0)?.[`DateMasterCombo.fymyDesc`]
        } else if (type === "FY") {
          label = masterData?.filter(data => data['DateMasterCombo.fy'] == label)?.at(0)?.[`DateMasterCombo.fyDesc`]
        }

        console.log('type-09', type);

        formattedColumns.push({
          Header: label ?? "",
          accessor: column.title,
          id: column.dataIndex,
          disableSortBy: true,
          headerClassName: `text-center ${bgColor}`,
          columns: column.children?.map(childCol => {
            console.log(childCol,'childCol');
            let label = `${childCol.shortTitle}`;
            if (label?.trim() === "Count of DealId" || label?.trim() === "Deal Count" || label?.trim() === "Count" || label?.trim() === "Ma Deal Count") {
              label = "No. of Deals"
            } else if (label?.trim() === "Deal Amount" || label?.trim() === "Total Funding ($M)" || label?.trim() === "Amount" || label?.trim() ==="Ma Deal Amount") {
              label = "Amount ($M)"
            }
            return {
              Header: label,
              id: `${childCol.dataIndex}`,
              accessor: (data) => data[`${childCol.dataIndex}`],
              sortType: (rowA, rowB) => {
                if (rowA.original[`${childCol.dataIndex}`] < rowB.original[`${childCol.dataIndex}`]) return -1;
                if (rowB.original[`${childCol.dataIndex}`] < rowA.original[`${childCol.dataIndex}`]) return 1;
              },
              headerClassName: `${bgColor}`,
              className: `text-right p-2 pr-[32px] ${bgColor}`
            }
          })

        });
      } else {

        let label = column.shortTitle;
        if (label?.trim() === "Deal Date" || label?.trim() === "Date" || label?.trim() === "Deal Flat Deal Date" || label?.trim() === "Cy") {
          label = "Year"
        } else if (label === "Count of DealId" || label?.trim() === "Deal Count" || label?.trim() === "Count" || label?.trim() === "Deal Flat Count of DealId" || label?.trim() === "Ma Deal Count") {
          label = "No. of Deals"
        } else if (label?.trim() === "Acquirer Industry") {
          label = "Industry"
        } else if (label?.trim() === "Deal Amount" || label?.trim() === "Total Funding ($M)" || label?.trim() === "Amount" || label?.trim() === "Deal Flat Total Funding ($M)" || label?.trim() ==="Ma Deal Amount") {
          label = "Amount ($M)"
        } else if (label?.trim() === "Id") {
          label = "Range"
        }

        let className = "text-left"

        if (label === "Amount ($M)" || label === "No. of Deals") {
          className = "text-right pr-[32px]"
        }

        let type = "";

        if (column?.title?.includes('Cyqy')) {
          type = "CYQY"
        } else if (column?.title?.includes('Cyhy')) {
          type = "CYHY"
        } else if (column?.title?.includes('Cymy')) {
          type = "CYMY"
        } else if (column?.title?.includes('Fyqy')) {
          type = "FYQY"
        } else if (column?.title?.includes('Fyhy')) {
          type = "FYHY"
        } else if (column?.title?.includes('Fymy')) {
          type = "FYMY"
        } else if (column?.title?.includes('Fy')) {
          type = "FY"
        }

        if (type === "CYQY") {
          label = "Quarterly (CY)"
        } else if (type === "CYHY") {
          label = "Half yearly (CY)"
        } else if (type === "CYMY") {
          label = "Monthly (CY)"
        } else if (type === "FYQY") {
          label = "Quarterly (FY)"
        } else if (type === "FYHY") {
          label = "Half yearly (FY)"
        } else if (type === "FYMY") {
          label = "Monthly (FY)"
        } else if (type === "FY") {
          label = "Year (FY)"
        }



        formattedColumns.push({
          Header: label ?? "",
          id: column.dataIndex,
          enableRowSpan: true,
          accessor: data => {
            if (column.type === "time") {
              return moment(data[column.dataIndex]).year()
            }
            if (label === "Range") {
              return DealRangeData.find(range => range.id === data[column.dataIndex]).name;
            }
            if (type !== "") {
              let periodLabel = ""
              if (type === "CYQY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.cyqy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.cyqyDesc`]
              } else if (type === "CYHY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.cyhy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.cyhyDesc`]
              } else if (type === "CYMY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.cymy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.cymyDesc`]
              } else if (type === "FYQY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.fyqy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.fyqyDesc`]
              } else if (type === "FYHY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.fyhy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.fyhyDesc`]
              } else if (type === "FYMY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.fymy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.fymyDesc`]
              } else if (type === "FY") {
                periodLabel = masterData?.filter(filterData => filterData['DateMasterCombo.fy'] == data[column.dataIndex])?.at(0)?.[`DateMasterCombo.fyDesc`]
              }

              return periodLabel;
            }
            return data[column.dataIndex]
          },
          headerClassName: `sticky left-0 ${bgColor}`,
          className: `whitespace-nowrap p-2 sticky left-0 ${bgColor} ${className}`,
        });
      }
    })
    return formattedColumns
  }, [columnData, masterData])



/* The above code is using the `useMemo` hook from React to memoize the `rowData` variable. This means
that the `rowData` value will only be recalculated if the dependencies specified in the second
argument of `useMemo` (in this case, `[rowData]`) change. The memoized value is stored in the `data`
constant. */
  const data = React.useMemo(
    () => rowData,
    [rowData]
  )

  console.log("table-date", columns, data);
  console.log("master-data", masterData);

  const initialSort = useMemo(() => [
    {
      id: pvConfig?.x?.at(0),
      desc: false
    }
  ], [pvConfig?.x]);

  console.log('initialSort', initialSort);

/* The above code is using the `useTable` hook from the React-Table library to create a table instance.
It is passing in an object with the following properties: */
  const tableInstance = useTable({
    columns,
    data,
    disableSortRemove: true,
    initialState: {
      sortBy: initialSort,
    }
  },
    useSortBy,
  );

/* The above code is using destructuring assignment to extract specific properties from the
`tableInstance` object. These properties are `getTableProps`, `getTableBodyProps`, `headerGroups`,
`rows`, and `prepareRow`. These properties are likely being used in a React component to render a
table. */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

/**
 * The function formatNumberWithCommas takes a number as input and returns a string representation of
 * that number with commas separating every three digits.
 * @returns The function `formatNumberWithCommas` returns a string representation of the input number
 * with commas added as thousands separators.
 */
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
    {
      isRenderingInitial ? (

        <div className=" flex flex-col md:mt-5 md:mx-4 md:mb-4 my-1 mx-1 xl:mx-4 xl:my-4">
          <div className='flex justify-center'>
            {/* <h1 className=' text-lg my-1 font-medium'>Table Data</h1> */}
          </div>
          <div className="bg-[#ffffff] scrollbar  h-[400px] mt-1 px-4 flex items-center overflow-x-auto">
            {
              isLoading
                ? <div className='w-full flex items-center justify-center bg-white h-72'>
                  <Loader />
                </div>
                : rows.length === 0
                  ? <NoDataPlaceholder showButton={false} />
                  :<div className='bg-[#ffffff] m-2  '>
                    <table className="bg-white table-fixed border-y border-r shadow  rounded-xl   w-full" {...getTableProps()}>
                      <thead className=" ">
                        {
                          headerGroups.map(headerGroup => (

                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                              {
                                headerGroup.headers.map(column => (

                                  <th {...column.getHeaderProps([
                                    {
                                      className: `p-2 whitespace-nowrap font-sans text-[14px] ${column.placeholderOf != null ? "bg-[#f2f2f2] sticky left-0" : ""} ${column.headerClassName}`,
                                      style: column.headerStyle,
                                    },
                                    column.getSortByToggleProps(),
                                  ])} title="">
                                    {
                                      column.disableSortBy || !column.canSort
                                        ? column.render('Header')
                                        : <div className="flex items-center justify-center">
                                          {column.render('Header')}
                                          {column.disableSortBy
                                            ? null
                                            : column.isSorted
                                              ? column.isSortedDesc
                                                ? <SortAscendingIcon size={16} className="ml-2" />
                                                : <SortDescendingIcon size={16} className="ml-2" />
                                              : <SortIcon size={16} className="ml-2" />}
                                        </div>
                                    }

                                  </th>
                                ))
                              }
                            </tr>
                          ))
                        }
                      </thead>

                      <tbody {...getTableBodyProps()} className=" ">
                        {
                          rows.map(row => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()} className="border-b border-b-[#E9E9E9]">
                                {
                                  row.cells.map(cell => {
                                    let dataAtr = {};

                                    if (cell.column.Header === "Range") {
                                      dataAtr['data-t'] = "s";
                                      dataAtr['data-v'] = cell.value;
                                    }
                                    if (cell.column.Header === "Amount ($M)") {
                                      dataAtr['data-t'] = "n";
                                      dataAtr['data-v'] = cell.value;
                                      dataAtr['data-z'] = `${cell.value}`.includes(".")?"#,###.##":"#,###";
                                    }
                                    return (

                                      <td className='' {...cell.getCellProps([
                                        {
                                          className: `font-sans text-[14px] ${cell.column.className}`,
                                          style: cell.column.style,
                                          ...dataAtr
                                        },
                                      ])}>
                                      <div className='flex items-center justify-center'>
                                        {cell.value != 0 || cell.value != 0.00 ?
                                            cell.column.Header === "Amount ($M)" ? formatNumberWithCommas(cell.value) : cell.value : ''}

                                          {/* {
                                            cell.value ? cell.render('Cell') : " "
                                          } */}
                                      </div>
                                    


                                      </td>


                                    )
                                  })}

                              </tr>

                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div> 
            }
          </div>
        </div>

      ) : (

        <div className=" block m-3">
          <div className=" scrollbar overflow-x-auto">
            {
              isLoading
                ? <div className='w-full flex items-center justify-center bg-white h-72'>
                  <Loader />
                </div>
                : rows.length === 0
                  ? <NoDataPlaceholder showButton={false} />
                  : <table className="bg-white table-fixed border-y border-r rounded-lg shadow-lg " {...getTableProps()}>
                    <thead className=" ">
                      {
                        headerGroups.map(headerGroup => (

                          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {
                              headerGroup.headers.map(column => (

                                <th {...column.getHeaderProps([
                                  {
                                    className: `p-2 whitespace-nowrap font-sans text-[14px] w-1 ${column.placeholderOf != null ? "bg-[#f2f2f2] sticky left-0" : ""} ${column.headerClassName}`,
                                    style: column.headerStyle,
                                  },
                                  column.getSortByToggleProps(),
                                ])} title="">
                                  {
                                    column.disableSortBy || !column.canSort
                                      ? column.render('Header')
                                      : <div className="flex items-center justify-center">
                                        {column.render('Header')}
                                        {column.disableSortBy
                                          ? null
                                          : column.isSorted
                                            ? column.isSortedDesc
                                              ? <SortAscendingIcon size={16} className="ml-2" />
                                              : <SortDescendingIcon size={16} className="ml-2" />
                                            : <SortIcon size={16} className="ml-2" />}
                                      </div>
                                  }

                                </th>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </thead>

                    <tbody {...getTableBodyProps()} className="">
                      {
                        rows.map(row => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()} className="border-b border-b-[#E9E9E9]">
                              {
                                row.cells.map(cell => {
                                  let dataAtr = {};

                                  if (cell.column.Header === "Range") {
                                    dataAtr['data-t'] = "s";
                                    dataAtr['data-v'] = cell.value;
                                  }
                                  if (cell.column.Header === "Amount ($M)") {
                                    dataAtr['data-t'] = "n";
                                    dataAtr['data-v'] = cell.value;
                                    dataAtr['data-z'] = `${cell.value}`.includes(".")?"#,###.##":"#,###";
                                  }
                                  return (

                                    <td {...cell.getCellProps([
                                      {
                                        className: `font-sans text-[14px]  ${cell.column.className}`,
                                        style: cell.column.style,
                                        ...dataAtr
                                      },
                                    ])}>
                                      {cell.value != 0 || cell.value != 0.00 ?
                                        cell.column.Header === "Amount ($M)" ? formatNumberWithCommas(cell.value) : cell.value : ''}

                                      {/* {
                                        cell.value ? cell.render('Cell') : " "
                                      } */}


                                    </td>


                                  )
                                })}

                            </tr>

                          )
                        })
                      }
                    </tbody>
                  </table>
            }
          </div>
        </div>

      )
    }

    </>

    

  )
}

export default TableTabTable