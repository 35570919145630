import React, { useState, useContext, useEffect, useRef } from "react";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import { SearchMenuContext } from "../../Contexts/SearchMenuContext";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import v2logo from "../../images/Bitmap/png/Bitmap.png";
import shape from "../../images/Shape_13/png/Shape.png";
import MobileMenu from "../../Components/MobileMenu";
import Search from "../../images/Shape_13/png/Shape.png";
import Profile from "../../Components/Profile";
import PopOverSearch from "../../Components/PopOverSearch";
import "../../Components/HeaderComponent.css";
import useModule from "../../hooks/useModule";
import BellNotification from "./BellNotification";
import { Modal, Switch, Tooltip, Checkbox } from "antd";
import QueryBuilderTabs from "../QueryBuilder/QueryBuilderTabs";
import advanceSearchIcon from '../../images/advanceSearch.svg';
import NewSearchResult from "../NewSearchResult";
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { useDebounce } from "use-debounce";
import { routeNames } from "../../routeSegments";

/** The above  component  is responsible for rendering the
navigation bar of a web application. */
const NavbarWrapper = ({ children }) => {
  const navigate = useNavigate();

  const { searchText,setSearchText } = useContext(HeaderMenuContext);
  const PEHeaderSearchMenu = useContext(SearchMenuContext);
  const FilteredData = [PEHeaderSearchMenu.data];
  const [popOver, setPopOver] = useState(false);
  const [popOverAdvance, setPopOverAdvance] = useState(false);
  const [currentTab, setcurrentTab] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput] = useDebounce(searchInput.trim(), 500);
  const [searchType, setSearchType] = useState(0);
  const [activeModule, setActiveModule] = useState('dashboard');
  const [searchLabel, setsearchLabel] = useState(
    "Search for companies / Investors / Advisors / Investments / Exits / Merger & Acquisitions"
  );
  // const [sound, setSound] = useState(
  //   "https://soundbible.com/mp3/glass_ping-Go445-1207030150.mp3"
  // );
  const location = useLocation();
  const { isDadhboard, isPE, isCFS, isMA, isVC, isRE, isNotifications, isRequests, isAlerts} = useModule();
const encodeSearchTerm = (term) => {
  try {
    new URL(term);
    return encodeURIComponent(term);
  } catch {
    return encodeURIComponent(term);
  }
};

const getModuleType = () => {
  const pathSegment = location.pathname.split('/')[1];
  const isDashboardRoute = pathSegment === 'dashboard' || pathSegment === '';
  
  if (isDashboardRoute) return 'dashboard';
  if (pathSegment.includes('cfs')) return 'cfs';
  if (pathSegment.includes('ma')) return 'ma';
  if (pathSegment.includes('vc')) return 'vc';
  if (pathSegment.includes('re')) return 're';
  if (pathSegment.includes('pe')) return 'pe';
  return '';
};

// Function to construct search URL
const constructSearchURL = (searchTerm, type, moduleType) => {
  const encodedTerm = encodeSearchTerm(searchTerm);
  
  if (!moduleType || moduleType === 'dashboard') {
    return routeNames.globalSearch(encodedTerm, type);
  }
  
  const routeKey = `${moduleType}GlobalSearch`;
  const baseRoute = routeNames[routeKey];
  
  if (typeof baseRoute === 'function') {
    return baseRoute(encodedTerm, type);
  }
  
  return `/search?q=${encodedTerm}&type=${type}`;
};

useEffect(() => {
  const moduleType = getModuleType();
  setActiveModule(moduleType);
}, [location.pathname]);

  useEffect(() => {
    setSearchInput(searchText);
  }, [searchText]);

  useEffect(() => {
    if (debouncedSearchInput.length !== 0) {
      const searchURL = constructSearchURL(debouncedSearchInput, searchType, activeModule);
      
      const currentSearchParams = new URLSearchParams(location.search);
      const currentQuery = currentSearchParams.get('q');
      const currentType = currentSearchParams.get('type');
      
      if (currentQuery !== debouncedSearchInput || currentType !== searchType.toString()) {
        navigate(searchURL);
        searchRef.current.focus();
      }
    }
  }, [debouncedSearchInput, searchType, activeModule]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        searchRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (location.pathname.match("limited-partners")) {
      setsearchLabel("Type LP or Investor name..");
    } 
    else if (location.pathname.match("re-fund")) {
      setsearchLabel("Type Investor or Fund name..");
    }
    else if (location.pathname.match("funds") || location.pathname.match("fund")) {
      setsearchLabel("Type Investor or Fund name..");
    } else {
      if (isCFS) {
        setsearchLabel("Search for Companies - Enter Company name or CIN or Brand name");
      } else if (isMA) {
        setsearchLabel("Search for Companies / Acquirers / Legal Advisors...");
      } else if (isVC) {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
      } else if (isRE) {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
      } else {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
      }
       if (location.pathname.match("acquirer")) {
        setcurrentTab("acquirer");
      } else if (location.pathname.match("fund")) {
        setcurrentTab(isRE ? "re_fund":"fund");
      }else if (location.pathname.match("compan")) {
        setcurrentTab("company");
      } else if (location.pathname.match("investo")) {
        setcurrentTab("investor");
      } else if (location.pathname.match("transaction")) {
        setcurrentTab("t_advisor");
      } else if (location.pathname.match("advisor")) {
        setcurrentTab("l_advisor");
      }  
      else {
        setcurrentTab("company");
      }
    }
  }, [location.pathname]);
  const menuRef = useRef();
  const searchRef = useRef(null);
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    setSearchText(e.target.value)
  }
  const handlePaste =(e)=>{
    // setSearchInput(e.clipboardData.getData('Text'));
    // setSearchText(e.clipboardData.getData('Text'))
    const pastedValue= e.clipboardData.getData('Text')
    // console.log(pastedValue,'pastedValue');
    if(pastedValue.length+1 > 3) {
    setPopOver(true)
    }
    if(pastedValue.length-1 === 0) {
      setPopOver(false)
      }
  }

  const handleClear = () => {
    setSearchInput('');
    setSearchText('');
    searchRef.current.focus();
    setSearchType(0);
    setTimeout(() => {
      navigate("/dashboard");
    }, 0);
  };

  const searchTypes = [
    // {
    //   label: "Phrase Match",
    //   key: 2
    // },
    {
      label: "Broad Match",
      key: 0
    },
    {
      label: "Exact Match",
      key: 1
    }
  ];

  return (
    <>    
      {/* <NewSearchResult 
      show={popOver}
      searchInput={debouncedSearchInput}
      setSearchInput ={(txt)=>{
          setSearchInput(txt);
          setSearchText(txt)
        }}
      onClose={() => setPopOver(false)}
      searchLabel={searchLabel}
      setSearchText={setSearchText}
      setPopOver={setPopOver}
      menu={type}
      searchType={searchType}
      /> */}
      {/* <Modal
       
        style={{ top: "48px" ,padding:'0px'}}
        className="advanceSearchScroll"
        destroyOnClose={true}
        width={"80vw"}                
        footer={null}        
        open={popOverAdvance}
        onOk={() => setPopOverAdvance(false)}
        onCancel={() => setPopOverAdvance(false)}
      >
        <div className="query-builder max-h-[80vh] overflow-y-auto relative">
      <QueryBuilderTabs setPopOverAdvance= {setPopOverAdvance}/>
      </div>
      </Modal> */}

       {/* <PopOverAdvanceSearch
        show={popOverAdvance}
        onClose={() => setPopOverAdvance(false)}
        setPopOverAdvance= {setPopOverAdvance}
      />     */}

      <div className=" lg:fixed lg:z-[100] w-full lg:left-0 relative bg-white " >
        {/* <img src={beta} className='absolute z-[150] right-0 ' width={62}  />
        <div className="absolute hidden lg:flex items-center justify-center lg:z-[150] md:z-[150] z-[150] right-[-28px] h-[1.2rem] px-6 mt-3 lg:right-[-28px] md:right-[-27px] lg:mt-2 md:mt-2 rotate-45 bg-red-600 lg:px-6 xl:px-6 md:px-6 text-white">
          <h3 className="tracking-widest font-semibold text-sm ">BETA</h3>
        </div> */}

        {/* <div className=" bg-white"> */}
          <div className=" w-full fixed z-[10]">
            <div className="relative z-10 flex-shrink-0  bg-white border-b border-gray-200  ">
              <div>
                <div className="flex  lg:px-0 justify-between items-center lg:pr-2">
                  <div className="flex w-[55%]">
                    {/* <SideBarApp />   */}
                    <div className="md:hidden ">
                      <span className="sr-only">Open sidebar</span>
                      <MobileMenu />
                    </div>
                    <div
                      className="bg-white rounded-full px-2 py-1 flex text-sm cursor-pointer "
                      id="user-menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                      onClick={() => navigate("/dashboard")}>
                      <img
                        className="h-9 w-21 rounded-full"
                        src={v2logo}
                        alt=""
                      />
                    </div>

                    <p className="border-r border-gray-200 h-[3rem]"></p>
                    <div className="hidden md:flex pl-4 items-center space-x-2 w-full">
                      <div className="w-full relative pl-[2.5rem] rounded-md  border border-slate-500 py-2">
                        <div className=" flex absolute z-10 inset-y-0 left-0 pl-3 items-center text-[#989898] pointer-events-none">
                          <img className="h-[17px] w-[17px]" src={Search} alt="search"/>
                        </div>
                        <input
                          type="search"
                          value={searchInput}
                          ref={searchRef}
                          id="globalSearchInput"
                          onChange={handleChange}
                          onPaste={handlePaste}
                          className="w-[96%] text-[15px] outline-none shadow-none bg-inherit text-black leading-[15px] tracking-[-0.28] font-sans_book_body"
                          placeholder="Search for Companies, Investors, Advisors, Investments, Exits, M&A"
                          autoComplete="off"
                        />
                        {searchInput && (
                          <div className="w-36 flex items-center absolute right-3 top-1/2 transform -translate-y-1/2 font-normal">
                            <select
                              className="mr-[.5rem] border border-[#E5E7EB] rounded-sm shadow-sm text-[#333333] text-[13px] outline-none p-1"
                              onChange={(e) => {
                                const selectedType = searchTypes.find(type => type.label === e.target.value);
                                setSearchType(selectedType.key);
                              }}
                            >
                              {searchTypes.map(type => 
                                <option key={type.key} value={type.label}>
                                  {type.label}
                                </option>
                              )}
                            </select>
                            <button
                              type="button"
                              onClick={handleClear}
                              className="w-8 absolute right-0 top-1/2 transform -translate-y-1/2 text-red-600 font-medium"
                            >
                              &#x2715; {/* This is a Unicode character for a cross */}
                            </button>
                          </div>
                        )}
                      </div>
                      {/* { searchInput || isCFS || isRE || isMA || isNotifications || isRequests || isAlerts ? <></> :
                      <Tooltip title="Advanced Filter">
                        <img src={advanceSearchIcon} alt="advancesearch" className="h-[30px] cursor-pointer" onClick={() => setPopOverAdvance(true)}/>
                      </Tooltip>} */}
                      
                      

                    </div>
                  </div>
                      
                  <div className="flex items-center">
                  {/* <div>
                  <Tooltip title='This is a beta version. If you encounter any errors, technical issues, or data discrepancies while navigating through the platform, please report them, along with a screenshot, to tech@ventureintelligence.com'>
                      <div className="px-3 lg:hidden  cursor-pointer lg:ml-7 ml-4 bg-white border-red-500 border  font-medium text-red-500">
                      β
                      </div>
                      </Tooltip>
                  </div> */}
                  
                    <div className=" md:flex items-center justify-between xl:pl-0">
                      <nav className="hidden md:flex space-x-10 px-4">
                        <section>
                          <div
                            className="hidden lg:flex flex-col items-stretch items-center  w-full "
                            x-data="{tab: 1}"
                          >
                            <div className="flex flex-row items-center gap-5 w-full">
                              {children}
                            </div>
                          </div>
                        </section>
                      </nav>
                      <div className="flex space-x-4 mr-3 items-center">
                        <p className="md:border-r border-gray-200 h-[3rem] "></p>                        
                        <div className="hidden py-5">
                          <div className="">
                            <div className="">
                              <img
                                className=" text-gray-500 w-[1.5rem] float-right"
                                src={shape}
                                alt="shape"
                                onClick={() => setPopOver(!popOver)}
                              />                              
                            </div>
                          </div>
                        </div>
                        <BellNotification />                       
                        <div className="" ref={menuRef}>
                          <Profile />
                        </div>
                      </div>
                    </div>

                    {/* profile and notification end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default NavbarWrapper;
