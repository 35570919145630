import React from 'react'
import CountUp from "react-countup";
import OverflowToolTip from '../shared/OverflowToolTip';
import { Tooltip } from 'antd';

/**
 * The `TextCard` function is a React component that renders a card with a title, subtitle, image, and
 * loading state.
 * @returns a JSX element, which represents a text card component.
 */
function TextCard({ title, subtitle, image, isLoading, showCount ,subtext ,props,totalCount}) {
    return (
        <div className="">
            <section> 
                <div className=" bg-white px-3 py-1  rounded-md  border border-gray-200 ">
                    <div className="flex">
                        <div className="flex-grow overflow-clip overflow-ellipsis">
                            <div className="whitespace-nowrap text-[12pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">
                            { title === "Most Funded" ? (<>
                                <Tooltip 
                                     placement="bottom" 
                                    color="#ffffff"
                                    key="#ffffff"
                                    overlayStyle={{ maxWidth: '30%'  }}
                                    title={<span style={{ color: '#000000', fontSize:"14px"  }}>Sector which received more than 3 investments in the chosen period</span>}
                                >
                                    {title}
                                </Tooltip>
                            </>) : title}                         
                            </div>
                            {!isLoading ? (
                                subtitle != null && subtitle !== undefined && subtitle !== 0 && (showCount ? parseFloat(subtitle) > 0 : true) ? (
                                    <p className='text-[16pt] font-bold whitespace-nowrap leading-[21pt] lg:leading-[23px] tracking-[-0.3px] lg:text-[17px] text-[#A5711A]'>
                                        <OverflowToolTip 
                                            className="w-[12rem]" 
                                            isTopCard={true}
                                            component={
                                                showCount === true && typeof subtitle === "number" 
                                                    ? <CountUp
                                                        start={0}
                                                        prefix={""}
                                                        suffix={""}
                                                        separator={","}
                                                        end={subtitle}
                                                        decimals={0}
                                                        duration={2}
                                                    />
                                                    : subtitle 
                                            }
                                        />
                                    </p>

                                ) : (
                                    <div className="flex space-x-1 text-gray-500">
                                        <div>
                                            <span className='text-[16pt] font-bold whitespace-nowrap leading-[21pt] lg:leading-[23px] tracking-[-0.3px] lg:text-[17px] text-[#A5711A]'>
                                                {title === "Most Funded" ? "NA" : "--"}
                                            </span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="w-full bg-[#FFFFFF]">
                                    <div role="status" className="bg-white">
                                        <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                        {
                            title === "Most Funded" ? (

                        <Tooltip 
                                placement="bottom" 
                                color="#ffffff"
                                key="#ffffff"
                                overlayStyle={{ maxWidth: '30%'  }}
                                title={<span style={{ color: '#000000', fontSize:"14px"  }}>Sector which received more than 3 investments in the chosen period</span>}>
                            <div className="flex-shrink-0  pl-5 flex items-center justify-center">
                                <img alt='' className=" pointer-events-none h-[24px] max-w-[25px] mt-2"
                                    src={image} />
                            </div>
                            </Tooltip>
                            ) : (
                                <div className="flex-shrink-0  pl-5 flex items-center justify-center">
                                <img alt='' className=" pointer-events-none h-[24px] max-w-[25px] mt-2"
                                    src={image} />
                            </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TextCard