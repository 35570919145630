import React, { useEffect, useMemo, useCallback, useContext } from 'react'
import './dashboarcd.css'
import axios from 'axios';
import { groupListApi } from '../../Config/config';
import { primaryMasterValues } from '../../Constants/values';
import { useFilters, usePagination, useTable } from 'react-table';
import DashboardPagination from '../shared/DashboardPagination';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { UserContext } from '../../Contexts/UserContext';
import moment from "moment";
import { GrView } from 'react-icons/gr'
import { routeNames } from '../../routeSegments';
import { Tooltip } from 'antd';
import Constants from '../../Constants';

export const getPath = (value) => {
    let moduleId = value.primary_type_master_id;
    let type = value.type_name;
    if(moduleId == "1"){
        if(type == "Company"){
            let path = routeNames.pe_companies_pe_vc_investments 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investments_id) {
                    path = routeNames.pe_companies_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exits_id) {
                    path = routeNames.pe_companies_exists_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exitsma_id) {
                    path = routeNames.pe_companies_exists_ma_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exitspm_id) {
                    path = routeNames.pe_companies_exists_pm_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_backed_ipos_id) {
                    path = routeNames.pe_companies_backed_ipos_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_angel_invesments_id) {
                    path = routeNames.pe_companies_angel_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_incubation_id) {
                    path = routeNames.pe_companies_incubation;
                    break;
                } else {
                    path = routeNames.pe_companies_pe_vc_investments;
                }
            }
            return path;
        }else if(type == "Investor"){
            let path = routeNames.pe_investors_pe_vc_investments;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_investments_id) {
                    path = routeNames.pe_investors_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exits_id) {
                    path = routeNames.pe_investors_exists_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exitsma_id) {
                    path = routeNames.pe_investors_exists_ma_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exitspm_id) {
                    path = routeNames.pe_investors_exists_pm_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_backed_ipos_id) {
                    path = routeNames.pe_investors_backed_ipos_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_angel_invesments_id) {
                    path = routeNames.pe_investors_angel_investments;
                    break;
                } else {
                    path = routeNames.pe_investors_pe_vc_investments;
                }
            }
            return path;                
        }else if(type == "Legal Advisor"){
            let path = routeNames.pe_advisors_pe_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_investments_id) {
                    path = routeNames.pe_advisors_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exits_id) {
                    path = routeNames.pe_advisors_ma_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exitsma_id) {
                    path = routeNames.pe_advisors_ma_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exitspm_id) {
                    path = routeNames.pe_advisors_pm_pe_vc_advisors;
                    break;
                } else {
                    path = routeNames.pe_advisors_pe_vc_advisors;
                }
            }
            return path;  
        }else if(type == "Transaction Advisor"){
            let path = routeNames.pe_transaction_advisors_pe_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_investments_id) {
                    path = routeNames.pe_transaction_advisors_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exits_id) {
                    path = routeNames.pe_transaction_advisors_ma_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exitsma_id) {
                    path = routeNames.pe_transaction_advisors_ma_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exitspm_id) {
                    path = routeNames.pe_transaction_advisors_pm_pe_vc_advisors;
                    break;
                } else {
                    path = routeNames.pe_transaction_advisors_pe_vc_advisors;
                }
            }
            return path;  

        }else if(type == "Limited Partner"){
            let path = routeNames.pe_investors_pe_vc_limited_partners;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_investments_id) {
                    path = routeNames.pe_investors_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exits_id) {
                    path = routeNames.pe_investors_exists_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exitsma_id) {
                    path = routeNames.pe_investors_exists_ma_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exitspm_id) {
                    path = routeNames.pe_investors_exists_pm_pe_vc_limited_partners;
                    break;
                } else {
                    path = routeNames.pe_investors_pe_vc_limited_partners;
                }
            }
            return path; 
        }else if(type == "Fund"){
            return routeNames.pe_investors_pe_vc_funds 
        }else if(type == "Incubator"){
            return routeNames.pe_investors_incubation;
        }            
    }
    else if(moduleId == "2"){
        if(type == "Company"){                
            let path = routeNames.vc_companies_vc_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investments_id) {
                    path = routeNames.vc_companies_vc_investments;;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exits_id) {
                    path = routeNames.vc_companies_exists_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exitsma_id) {
                    path = routeNames.vc_companies_exists_ma_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exitspm_id) {
                    path = routeNames.vc_companies_exists_pm_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_backed_ipos_id) {
                    path = routeNames.vc_companies_backed_ipos_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_angel_invesments_id) {
                    path = routeNames.vc_companies_angel_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_incubation_id) {
                    path = routeNames.vc_companies_incubation;
                    break;
                } else {
                    path = routeNames.vc_companies_vc_investments;;
                }
            }
            return path;
        }else if(type == "Investor"){                
            let path = routeNames.vc_investors_vc_investments;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_investments_id) {
                    path = routeNames.vc_investors_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exits_id) {
                    path = routeNames.vc_investors_exists_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exitsma_id) {
                    path = routeNames.vc_investors_exists_ma_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exitspm_id) {
                    path = routeNames.vc_investors_exists_pm_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_backed_ipos_id) {
                    path = routeNames.vc_investors_backed_ipos_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_angel_invesments_id) {
                    path = routeNames.vc_investors_angel_investments;
                    break;
                } else {
                    path = routeNames.vc_investors_vc_investments;
                }
            }
            return path;  
        }else if(type == "Legal Advisor"){                
            let path = routeNames.vc_advisors_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_investments_id) {
                    path = routeNames.vc_advisors_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exits_id) {
                    path = routeNames.vc_advisors_ma_vc_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exitsma_id) {
                    path = routeNames.vc_advisors_ma_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exitspm_id) {
                    path = routeNames.vc_advisors_pm_vc_advisors;
                    break;
                } else {
                    path = routeNames.vc_advisors_vc_advisors;
                }
            }
            return path; 
        }else if(type == "Transaction Advisor"){                  
            let path = routeNames.vc_transaction_advisors_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_investments_id) {
                    path = routeNames.vc_transaction_advisors_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exits_id) {
                    path = routeNames.vc_transaction_advisors_ma_vc_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exitsma_id) {
                    path = routeNames.vc_transaction_advisors_ma_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exitspm_id) {
                    path = routeNames.vc_transaction_advisors_pm_vc_advisors;
                    break;
                } else {
                    path = routeNames.vc_transaction_advisors_vc_advisors;
                }
            }
            return path;  
      
        }else if(type == "Incubator"){
            return routeNames.vc_investors_incubation;
        }
    }else if(moduleId == "3"){
        if(type == "Company"){                 
            let path = routeNames.re_companies_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_investments_id) {
                    path = routeNames.re_companies_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_exitsma_id) {
                    path = routeNames.re_companies_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_others_ma_id) {
                    path = routeNames.re_companies_other_ma;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_backed_ipos_id) {
                    path = routeNames.re_companies_re_ipo;
                    break;
                } else {
                    path = routeNames.re_companies_re_investments;
                }
            }
            return path;
        }else if(type == "Investor"){                
            let path = routeNames.re_investors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_investment_id) {
                    path = routeNames.re_investors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_exits_id) {
                    path = routeNames.re_investors_exists;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_ipo_id) {
                    path = routeNames.re_investors_backed_ipos;
                    break;
                } else {
                    path = routeNames.re_investors_re_investments;
                }
            }
            return path;
        }else if(type == "Legal Advisor"){                
            let path = routeNames.re_advisors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_investment_id) {
                    path = routeNames.re_advisors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_exits_id) {
                    path = routeNames.re_advisors_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_otherma_id) {
                    path = routeNames.re_advisors_other_ma;
                    break;
                } else {
                    path = routeNames.re_advisors_re_investments;
                }
            }
            return path;
        }else if(type == "Transaction Advisor"){                
            let path = routeNames.re_transaction_advisors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_investment_id) {
                    path = routeNames.re_transaction_advisors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_exits_id) {
                    path = routeNames.re_transaction_advisors_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_otherma_id) {
                    path = routeNames.re_transaction_advisors_other_ma;
                    break;
                } else {
                    path = routeNames.re_transaction_advisors_re_investments;
                }
            }
            return path;
        }else if(type == "Fund"){
            return routeNames.re_investors_funds ;
        }
    }else if(moduleId == "4"){
        if(type == "Company"){
            return routeNames.ma_companies;
        }else if(type == "Legal Advisor"){
            return routeNames.ma_advisors;
        }else if(type == "Transaction Advisor"){
            return routeNames.ma_transaction_advisor;
        }else if(type == "Acquirer"){
            return routeNames.ma_acquires;
        }
    }else if(moduleId == "5"){
        if(type == "Company"){
            return routeNames.cfs;
        }
    }
}
function DashboardTableTwo({savedWishList,isLoading}) {    
    const {getToken} = useContext(UserContext);
    const navigate = useNavigate();    

    useEffect(() => {
        onModuleChange();
    })
    

    const onViewClick = (value) => {
       
        navigate(getPath(value),{state:{myList:{myList:[{id:0,group_id:value?.id,name:value?.group_name,type:value?.type_name}]},monthRange:['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")]}})

    }


   /* The above code is defining an array of columns for a table. Each column has a Header (displayed
   as the column title), an accessor (used to access the corresponding data in each row), and an
   optional Cell component (used to render custom content in each cell). The code also includes some
   conditional logic in the Cell component for the "Type" column, where it displays the value if it
   exists or "N/A" if it is null. There is also a commented out column for "Action" that includes a
   tooltip and a link to view more details, but it is currently disabled. */
   const columns = useMemo(() => [
        {
            Header: "List Name",
            accessor: "groupName",
            className: "text-center "
        },
        {
            Header: "Module",
            accessor: "entityName",
            className: "text-center",
            Cell: ({ value }) => {
                return primaryMasterValues.find(data => data.value == value)?.title
            },
        },        
        {
            Header: "Type",
            accessor: "type",
            className: "text-center ",
            Cell:({value})=>{
                if(value) return value
                else return "N/A"
            }
        },
        {
            Header: "Created Date",
            accessor: "createdAt",
        },
        {
            Header: "Action",
            accessor: "actions",
            className: "flex justify-center pt-[0.7rem]",
            Cell: ({ value }) => {                
                        return <Tooltip className='text-center' title="View List">
                        <GrView onClick={(e) => {
                            onViewClick(value)
                        }} className='cursor-pointer ' />
                    </Tooltip>
            }
        },
    ], [])

  /* The above code is using the `useMemo` hook in React to create a memoized version of the `data`
  variable. It is iterating over the `savedWishList` array and creating a new array called `rows`.
  For each item in `savedWishList`, it is pushing an object into `rows` with properties
  `entityName`, `groupName`, `type`, `createdAt`, and `actions`. The values for these properties are
  taken from the corresponding properties in `savedWish`. The `createdAt` property is formatted
  using the `moment` library to display the date in */
    const data = useMemo(() => {
        let rows = [];

        savedWishList?.forEach(savedWish => {
            console.log(savedWish,'cicuvoaislgblcsvdifud');
            rows.push({
                entityName: savedWish.primary_type_master_id                ,
                groupName: savedWish.group_name,
                type: savedWish.type_name,
                createdAt: moment(savedWish.created_at).format('DD MMM YYYY') ?? moment(new Date()).format('DD MMM YYYY'),
                actions: savedWish
            })
        })
        return rows;
    }, [savedWishList])

    /* The above code is using the `useTable` function from a library (possibly React-Table) to create
    a table instance. It is passing in an object with the `columns` and `data` properties, which
    define the structure and content of the table. It also includes an `initialState` property to
    set the initial page size of the table to 10. */
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    },
        useFilters,
        usePagination
    )

   /* The above code is destructuring an object called `tableInstance` and assigning its properties to
   variables. These properties include `getTableProps`, `getTableBodyProps`, `headerGroups`, `rows`,
   `prepareRow`, `page`, `gotoPage`, and `state`. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        // setFilter,
        state: { pageIndex, pageSize },
    } = tableInstance

/**
 * The `onPaginationChange` function is used to handle pagination changes by updating the current page.
 * @param page - The page parameter represents the current page number in the pagination. It indicates
 * which page the user has selected or navigated to.
 * @param pageSize - The pageSize parameter represents the number of items to be displayed on each page
 * of the pagination.
 */

    const onPaginationChange = (page, pageSize) => {
        gotoPage(page - 1)
    }

   /**
    * The function `onModuleChange` makes a POST request to an API endpoint with a specific payload and
    * headers, and logs the response data if the request is successful.
    * @param e - The parameter `e` is an event object that is passed to the `onModuleChange` function.
    * It is typically triggered by an event listener, such as a change event on an input element. The
    * event object contains information about the event that occurred, such as the target element that
    * triggered the event
    */
    const onModuleChange = (e) => {        
        // setselectedPrimaryModule(e?.target?.value ?? 'All');
        axios(`${groupListApi}`, {
            method: "POST",
            data: {
                primary_type_master_id: e?.target?.value ?? 'All',

            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
        .then((response) => {
            if (response?.data?.success == true) {
                console.log(response?.data, "list group api response");
                // setGroupList(response?.data);
                // setSelectedCategory(response?.data?.group[0].id);                
            }
        })
        .catch((error) => {
            console.log("API not working", error);
        });        
    }

    // const onCategoryChange = (e)=>{
    //     setSelectedCategory(e.target.value)
    // }

    return (  
    <>                  
        <div className=" overflow-x-auto lg:thin-scrollbar scrollbar-remove">
            <table className=" w-full h-full p-1 border-separate text-center border-spacing-y-1 " {...getTableProps()}>
                <thead className="bg-[#c3c3c3] font-sans_medium_body text-[14px] leading-[16px]  text-[#273746] h-[2rem]">
                    {
                        headerGroups.map(headerGroup => (

                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (

                                        <th {...column.getHeaderProps([
                                            { className: `whitespace-nowrap px-5 ${column.headerClassName}` }
                                        ])}>
                                            {
                                                column.render('Header')}
                                        </th>
                                    ))}
                            </tr>
                        ))}
                </thead>
                {!isLoading && savedWishList.length > 0 && page.length > 0 ? <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body text-center" {...getTableBodyProps()}>
                    {
                        page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="px-2 even:bg-[#E8E8E8] even:h-[2.3rem] odd:bg-white odd:h-8" {...row.getRowProps()} style={{height:'2.3rem'}}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: `whitespace-nowrap px-5 py-2 ${cell.column.className}`,
                                                        }])}
                                                >
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                </tbody> :
                <tbody className='h-[27.5rem]'>
                <td colSpan={headerGroups.map(headerGroup => { return headerGroup?.headers?.length })} className=''>
                {isLoading
                    ? <div className='bg-white w-full flex items-center justify-center h-full'>
                        <Loader />
                    </div>
                    : savedWishList.length < 1 || page.length < 1
                        ? <div className='bg-white w-full flex items-center justify-center h-full'>
                            <NoDataPlaceholder showButton={false} />
                        </div>
                        : <></>}
                </td>
                </tbody>
            }
            </table>            
             
        </div>   
        {!isLoading &&                   
            <div className='text-center mt-2 mb-2'>
                <DashboardPagination
                    current={pageIndex + 1}
                    total={rows.length}
                    pageSize={pageSize}
                    onChange={onPaginationChange}
                />
            </div>   
            }             
    </> )  
}

export default DashboardTableTwo