import React, { useState, useEffect } from 'react'
import Tab from '../../../../Components/shared/Tab'
import PELogo from '../../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import RELogo from '../../../../images/Shape_4/png/Shape.png';
import MALogo from '../../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import CfsLogo from '../../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import { SearchTrendOption } from '../../../../Constants/values';
import Loader from '../../../../Components/Loader';
import PopOverSearch from "../../../../Components/PopOverSearch";
import Constants from '../../../../Constants';
import SearchTrendItem from './SearchTrendItem';
import { useContext } from 'react';
import { HeaderMenuContext } from '../../../../Contexts/HeaderMenuContext';
import { cubejsApi } from '../../../..';


/**
 * The `SearchTrend` function is a React component that displays trending search results based on
 * selected modules and time periods.
 * @returns The component is returning JSX elements.
 */
const SearchTrend = () => {
 
    const [allowedModulesList, setAllowedModulesList] = useState([]);
    const [isActive, setIsActive] = useState('pe_primary')
    const [selectedPeriod, setselectedPeriod] = useState('7days');
    const [searchPopup, setSearchPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dateRange, setDateRange] = useState([]);
    const {setSearchText} = useContext(HeaderMenuContext)
    const [searchData, setSearchData] = useState([]);
    const [searchResult, setSearchResult] = useState('');
 
    // const [keywordCategory, setKeywordCategory] = useState('pe_primary')
    // const [dateRangeCube, setDateRangeCube] = useState('Last 7 Days')


/**
 * The function `composeDateRange` takes an option as input and returns a date range based on that
 * option.
 * @param option - The `option` parameter is a string that determines the date range to be composed. It
 * can have the following values:
 * @returns The function does not explicitly return anything.
 */
    const composeDateRange = (option) => {
        const today = new Date();
        const startDate = new Date();
        let endDate = new Date();
        switch (option) {
            case "7days":
                startDate.setDate(today.getDate() - 7);
                break;
            case "15days":
                startDate.setDate(today.getDate() - 15);
                break;
            case "thisMonth":
                startDate.setDate(1);
                break;
            case "thisYear":
                startDate.setMonth(0, 1);
                endDate = today;
                break;
            case "allTime":
                startDate.setFullYear(1900);
                break;
            default:
                return;
        }
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];
        setDateRange([formattedStartDate, formattedEndDate]);
    }

    /**
     * The handleChange function updates the search result and search text based on the input value,
     * and toggles the search popup based on the length of the search result.
     * @param e - The parameter "e" is an event object that represents the event that triggered the
     * handleChange function. In this case, it is likely an event object related to a change in an
     * input field, such as a text input.
     */
    const handleChange = (e) => {
        setSearchResult(e.target.value);
        setSearchText(e.target.value)
        if(searchResult.length+1 > 2) {
            setSearchPopup(true)
        }
        if(searchResult.length-1 === 0) {
            setSearchPopup(false)
          }
      }

    /**
     * The function `onTabClick` logs the `tabIndex` parameter and sets the `isActive` state to the
     * value of `tabIndex`.
     * @param tabIndex - The `tabIndex` parameter is the index of the tab that was clicked. It is used
     * to determine which tab is currently active.
     */
    const onTabClick = (tabIndex) => {
        console.log('tabIndex', tabIndex);
        // setIsLoading(true);
        setIsActive(tabIndex);
    }
    /**
     * The function `onPeriodChange` updates the selected period and composes a date range based on the
     * selected value.
     * @param e - The parameter "e" is an event object that is passed to the function when the event
     * occurs. In this case, it is likely an event object that is triggered when the value of an input
     * element changes.
     */
    const onPeriodChange = (e) => {
        setselectedPeriod(e.target.value);
        composeDateRange(e.target.value);
    }

    // 7days,15days,thisMonth,thisYear,allTime

    

    /**
     * The onViewResultClick function logs the searchResult to the console, sets the searchResult and
     * searchText variables to the value of searchResult, and sets the searchPopup variable to true.
     * @param searchResult - The `searchResult` parameter is the result of a search query. It could be
     * an object, an array, or any other data type that represents the search result.
     */
    const onViewResultClick = (searchResult) => {
        console.log('searchResult', searchResult);
        setSearchResult(searchResult);
        setSearchText(searchResult)
        // setSearchPopup(true);
    }

    // cfs   // funds  // limited_partners // ma  // pe_primary // re   // vc_primary

    useEffect(() => {
        setIsLoading(true);

        const query = {
            "dimensions": [
                "SearchLog.search_term"
            ],
            "order": [
                [
                    "SearchLog.count",
                    "desc"
                ]
            ],
            "measures": [
                "SearchLog.count"
            ],
            "filters": [
                {
                    "member": "SearchLog.created_at",
                    "operator": "inDateRange",
                    "values": dateRange
                },
                {
                    "member": "SearchLog.searchType",
                    "operator": "equals",
                    "values": [isActive.toString()]
                }
            ],
            "limit": 12
        };

        cubejsApi.load(query)
            .then(result => {
                setIsLoading(false);
                const data = result.rawData();
                if (data) {
                    console.log('search Data', data);
                    setSearchData(data);
                }
            })
            .catch(error => {
                setIsLoading(false);
            })

        // const fetchData = async () => {
        //     try {
        //         const response = await fetch(`${apiUrl}?query=${encodeURIComponent(JSON.stringify(query))}&queryType=multi`);
        //         const data = await response.json();
        //         if (data && data.results && data.results[0] && data.results[0].data) {
        //             console.log('search Data', data);
        //             setSearchData(data.results[0].data);
        //         }
        //         setIsLoading(false);
        //     } catch (error) {
        //         console.error(error);
        //         setIsLoading(false);
        //     }
        // };

        // fetchData();
    }, [isActive, dateRange]);
  
    useEffect(() => {
        composeDateRange('7days');
        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        setAllowedModulesList(JSON.parse(allowedModulesStr) ?? []);
    }, []);

    /**
     * The `onClose` function sets the `searchPopup` state to `false` and clears the `searchText`
     * state.
     */
    const onClose = ()=>{
        setSearchPopup(false);
        setSearchText("")
    }


    return (
        <>
            {/* <PopOverSearch
            show={searchPopup}
            searchInput={searchResult}
            handleChange={handleChange}
            setSearchInput ={setSearchResult}
            onClose={onClose}
            type={isActive} 
        /> */}
            <div className="border border-[#CECECE] rounded-[4px] m-2 px-2 pt-2 bg-white lg:w-[28%]">
                <div className='w-full bg-[#ffffff]'>
                    <div className="sm:flex h-[2.5rem] flex justify-between items-center px-2 py-2 bg-white border-b border-x-1 ">
                        <div className="contents space-x-3 items-center justify-between">
                            <div><p className="text-[#333333] text-lg leading-[17px] font-semibold "> Trending searches  </p></div>
                            <select onChange={onPeriodChange}
                                value={selectedPeriod}
                                className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                {
                                    SearchTrendOption.map((option,i) => (<option value={option.value} key={i}>
                                        {option.title}
                                    </option>))
                                }
                            </select>
                        </div>
                    </div>                    
                    <div className='text-center'>
                        <div className='flex justify-between items-center pl-3 lg:pl-0 pr-2 bg-white xl:mb-2'>
                            <div className="flex flex-row overflow-x-auto scrollbar space-x-4 lg:space-x-6 bg-white lg:px-2 lg:py-2 px-0 py-4 cursor-pointer sticky top-0  scrollbar-remove z-[1]">
                                {allowedModulesList.includes('pe') && <Tab active={isActive === 'pe_primary'} label={"PE"} onClick={() => onTabClick('pe_primary')} className={'flex items-center'} activeclassName={'pl-2 pr-[1.75rem]  py-2'} logo={PELogo} />}
                                {allowedModulesList.includes('cfs') && <Tab active={isActive === 'cfs'} label={"CFS"} onClick={() => onTabClick('cfs')} className={'flex items-center'} activeclassName={'pl-2 pr-[1.75rem]  py-2'} logo={CfsLogo} />}
                                {allowedModulesList.includes('re') && <Tab active={isActive === 're'} label={"RE"} onClick={() => onTabClick('re')} className={'flex items-center'} activeclassName={'pl-2 pr-[1.75rem]  py-2'} logo={MALogo} />}
                                {allowedModulesList.includes('ma') && <Tab active={isActive === 'ma'} label={"M&A"} onClick={() => onTabClick('ma')} className={'flex items-center'} activeclassName={'pl-2 pr-[1.75rem]  py-2'} logo={RELogo} />}
                            </div> 
                                                       
                        </div>
                        <div className='h-[27.4rem]'>
                            {isLoading ? (                               
                                    <div className='bg-white w-full h-full flex items-center justify-center'>
                                        <Loader />
                                    </div>                                
                            ) : (
                                searchData && searchData.length > 0 && (
                                    <>
                                        {isActive === 'pe_primary' && (
                                            <SearchTrendItem
                                                onViewResultClick={onViewResultClick}
                                                searchData={searchData}
                                                type={'pe_primary'}
                                            />
                                        )}
                                        {isActive === 'cfs' && (
                                            <SearchTrendItem
                                                onViewResultClick={onViewResultClick}
                                                searchData={searchData}
                                                type={'cfs'}
                                            />
                                        )}
                                        {isActive === 're' && (
                                            <SearchTrendItem
                                                onViewResultClick={onViewResultClick}
                                                searchData={searchData}
                                                type={'re'}
                                            />
                                        )}
                                        {isActive === 'ma' && (
                                            <SearchTrendItem
                                                onViewResultClick={onViewResultClick}
                                                searchData={searchData}
                                                type={'ma'}
                                            />
                                        )}
                                    </>
                                )
                            )}
                        </div>

                    </div>
                </div>
            </div>







        </>
    )
}

export default SearchTrend
