import React from "react";

import forbes from "../../assets/assets/customers/forbes.png";
import scmp from "../../assets/assets/customers/scmp.png";
import quartz from "../../assets/assets/customers/Quartz_logo.png";
import techCrunch from "../../assets/assets/customers/tech-crunch.png";
import ET from "../../assets/assets/customers/economics-times.png";
import mint from "../../assets/assets/customers/Mint_(newspaper)_logo.png";
import theFinancialExpress from "../../assets/assets/customers/TheFinancialExpress.png";
import businessLine from "../../assets/assets/customers/BusinessLine.png";
import moneycontrol from "../../assets/assets/customers/moneycontrol.png";
import CNBC from "../../assets/assets/customers/CNBC_logo.png";
import nikkei from "../../assets/assets/customers/Nikkei-Asia.jpeg";
import yourStory from "../../assets/assets/customers/yourstory_new.png";
import Container from "../layout/Container";
import Button from "../utils/Button";

const Media = () => {
  const media = [
    { name: "Forbes", logo: forbes, width: "w-24" },
    { name: "South China Morning Post", logo: scmp, width: "w-20" },
    { name: "Quartz", logo: quartz, width: "w-28" },
    { name: "Tech Crunch", logo: techCrunch, width: "w-32" },
    { name: "Economics Times", logo: ET, width: "w-22" },
    { name: "Mint", logo: mint, width: "w-26" },
    { name: "The Financial Express", logo: theFinancialExpress, width: "w-36" },
    { name: "Business Line", logo: businessLine, width: "w-30" },
    { name: "Moneycontrol", logo: moneycontrol, width: "w-22" },
    { name: "CNBC", logo: CNBC, width: "w-28" },
    { name: "Nikkei Asia", logo: nikkei, width: "w-20" },
    { name: "Your Story", logo: yourStory, width: "w-24" },
  ];

  return (
    <div className="w-full overflow-x-hidden">
  <Container>
    {/* Centered Header */}
    <h2 className="text-3xl font-bold text-center my-8 text-gray-900">
      Media Mentions
    </h2>
    <div className="flex flex-col-reverse justify-center gap-12 lg:flex-row lg:h-[25rem] items-center">
      <div className="grid grid-cols-4 gap-6 place-items-center w-full max-w-xl">
        {media.map((item) => (
          <div 
            key={item.name} 
            className="flex items-center justify-center h-24 w-full"
          >
            <img 
              src={item.logo} 
              alt={item.name} 
              className={`${item.width} max-h-full object-contain`}
            />
          </div>
        ))}
      </div>
      <div className="leading-5 lg:max-w-xl xl:max-w-lg">
        <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
          TRUSTED BY MEDIA
        </p>
        <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
          <div className=" font-medium headings leading-tight">
            Explore Our Impact,
          </div>
          <div className=" font-bold">
            Recognized by Top-Tier Media
          </div>
        </h1>
        <h2 className="text-gray-900 font-semibolds text-[1.10rem] tracking-tight leading-7">
          Our research, reports, and analysts are frequently cited by
          top-tier media organizations, solidifying our reputation as a
          trusted industry source.
        </h2>
        {/* Uncomment if needed */}
        {/* <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
          <Button className="my-4 text-sm">Learn More</Button>
        </div> */}
      </div>
    </div>
  </Container>
</div>

  );
};

export default Media;