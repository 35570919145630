import { React, useContext, useEffect, useMemo, useState } from 'react'
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Tooltip } from "antd";
import { getCompanyDetails, getProfileUrl } from "../../../Config/config"
import ProfileHighlight from '../../DetailPage/NewProfileHighlights'
import Funding from '../../DetailPage/NewFunding'
import MergerAcquisition from '../../DetailPage/NewMA' 
import Financials from '../../DetailPage/NewFinancials'
import CapTable from '../../DetailPage/CapTable'
import SimilarCompanies from '../../DetailPage/NewSimilarCos'
import NewsActivity from '../../DetailPage/NewNewsActivity'
import ComparePopUp from '../../../Pages/ComparePopUp'
import Constants from '../../../Constants';
import { UserContext } from '../../../Contexts/UserContext';
import axios from 'axios';
import DetailInformation from '../../DetailPage/NewCompanyInfo';
import { routeNames } from '../../../routeSegments';
import Tab from '../../shared/Tab';
import FundingDataProvider from '../../../Contexts/FundingDataProvider';
import CurrencyContextProvider from '../../../Contexts/CurrencyContext';
import { PeFilterContext } from '../../../Contexts/PeFilterContext';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from '../../../Contexts/TableDataContext';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext';
/**
 * The MACompanyDetail function is a React component that displays detailed information about a
 * company, including its overview, mergers and acquisitions, funding, financials, cap table, similar
 * companies, and recent news and activity.
 */
const MACompanyDetail = ({ menu="ma" }) => {
    const {setCname} = useContext(RaiseRequestContext);
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' }); }
    const funding = () => { document.getElementById("fund").scrollIntoView({ behavior: 'smooth' }); }
    const merger = () => { document.getElementById("merge").scrollIntoView({ behavior: 'smooth' }); }
    const financial = () => { document.getElementById("financial").scrollIntoView({ behavior: 'smooth' }); }
    const capTable = () => { document.getElementById("cap").scrollIntoView({ behavior: 'smooth' }); }
    const similarCompanies = () => { document.getElementById("similar").scrollIntoView({ behavior: 'smooth' }); }
    const newsActivity = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState();
    const [profileData, setProfileData] = useState();
    const {data, setData, tableCompanyId} = useContext(TableDataContext);
    const { getToken, logout, getAllowedModules } = useContext(UserContext);
    const {loadPreviousStateRef} = useContext(PeFilterContext);
    const location = useLocation();
    const { companyId } = useParams();
    const [skipScroll, setSkipScroll] = useState(false)
    const { capTableDataFound, similarCompaniesDataFound, setSimilarCompaniesDataFound, newsAndActivityDataFound, setNewsAndActivityDataFound} = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundingEl, isfundingVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [mergerEl, ismergerVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [financialEl, isfinancialVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [captableEl, iscaptableVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [similarCompaniesEl, issimilarCompaniesVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
   

     const getProfile = () => {
        setData([]);
        axios(`${getCompanyDetails}/${companyId}/`)
        .then((response) => {
            if (response.data) {
            setIsLoading(false);
            setData(response?.data);
            setProfileData(response?.data.profile);
            if(response.data.related_news?.length !== 0){
                setNewsAndActivityDataFound(true) 
            } else setNewsAndActivityDataFound(false)
            if(response?.data?.similar_cos.length !== 0){
                setSimilarCompaniesDataFound(true)
            }else setSimilarCompaniesDataFound(false)
            setCname(response?.data?.profile?.name);
            }
        })
        .catch(() => {
            setIsError(true);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getProfile();
    }, [companyId]);

    /**
     * The function `onTabClick` is used to handle tab clicks and perform different actions based on
     * the selected tab index.
     */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            merger();
        } else if (tabIndex === 3) {
           funding();
        } else if (tabIndex === 4) {
            financial();
        } else if (tabIndex === 5) {
            capTable()
        } else if (tabIndex === 6) {
            similarCompanies()
        } else if (tabIndex === 7) {
            newsActivity();
        }

        setIsActive(tabIndex);
    }

    /* The above code is using the `useMemo` hook in React to memoize the result of a function called
    `showSimilarCompanies`. */
    const showSimilarCompanies = useMemo(() => {
        const allowedModules = getAllowedModules();
        return allowedModules.includes('pe') || allowedModules.includes('vc')
    }, [getAllowedModules])


    /**
     * The function sets a flag indicating that the previous state should be loaded when a root
     * breadcrumb is clicked.
     */
    const onRootBreadCrumbClick = ()=>{
        loadPreviousStateRef.current = true;
    }

    /* The above code is using the `useMemo` hook in React to memoize a function called
    `setIsActiveOnScroll`. This function is created using the `debounce` function, which is likely
    imported from a library. The `debounce` function takes two arguments - a callback function
    (`index => setIsActive(index)`) and a delay time (100 milliseconds in this case). */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    


    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (ismergerVisible) {
            setIsActiveOnScroll(2);
        } else if  (isfundingVisible){
            setIsActiveOnScroll(3);
        } else if (isfinancialVisible) {
            setIsActiveOnScroll(4);
        } else if (iscaptableVisible) {
            setIsActiveOnScroll(5);
        } else if (issimilarCompaniesVisible) {
            setIsActiveOnScroll(6);
        } else if (isnewsVisible) {
            setIsActiveOnScroll(7);
        }
    }, [isOverviewVisible, iscaptableVisible, isfinancialVisible, isfundingVisible, ismergerVisible, isnewsVisible, issimilarCompaniesVisible, setIsActiveOnScroll])

    const { tableCompanyId: cmpId } = useContext(TableDataContext);

    const currentIndex = cmpId?.indexOf(Number(companyId));

    document.title = `${profileData?.name ? profileData?.name + " - " : ""}M&A Companies - Venture Intelligence`;

 
    return isLoading && !isError ? (
        <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
            <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                <center>
                <div role="status" className="bg-white  h-[16rem]">
                    <svg
                    className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                    </svg>
                </div>
                </center>
            </table>
            </div>
    ) : !isError ? (
          <div className='overflow-y-auto details-screen'> 
            <div className="bg-[#FFFFFF]  profile-detail relative">
                    <nav className="flex p-1 border-b " aria-label="Breadcrumb">
                        <ol className="flex items-center space-x-1">
                            <li className='flex items-center'>
                                <div className='flex items-center'>
                                    <NavLink
                                    onClick={onRootBreadCrumbClick}
                                    state={location.state?.prevPath }
                                    to={location.state?.prevPath != null && location.state?.prevPath == ""
                                        ? location.state?.prevPath
                                        : routeNames.ma_companies} 
                                        className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                                        Companies
                                    </NavLink>
                                </div>
                            </li>
                            <li className="flex items-center"><div className="flex items-center"><span className=" text-[12px] text-[#666666] mr-1">/</span><span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold" aria-current="page">{profileData?.name}</span></div></li>
                        </ol>
                    </nav>
                    {cmpId &&
                        <>
                            <Tooltip title="Prev">
                                <NavLink
                                className="absolute top-2 right-8"
                                to={routeNames.maCompanyById(cmpId[currentIndex - 1])}
                                >
                                {cmpId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Next">
                                <NavLink
                                className="absolute top-2 right-4"
                                to={routeNames.maCompanyById(cmpId[currentIndex + 1])}
                                >
                                {cmpId.length - 1 === currentIndex ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                )}
                                </NavLink>
                            </Tooltip>
                        </>
                    }
                    <DetailInformation
                        data={data}
                        id={companyId}
                        page="companyPage"
                        cname={profileData?.name}
                    />

                    <div className="bg-[#EDEDED]">
                        <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                            <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                            <Tab active={isActive === 2} label={"Mergers & Acquisitions"} onClick={() => onTabClick(2)} />
                            <Tab active={isActive === 3} label={"Funding"} onClick={() => onTabClick(3)} />
                            <Tab active={isActive === 4} label={"Financials"} onClick={() => onTabClick(4)} />
                            {capTableDataFound ? <Tab active={isActive === 5} label={"Cap Table"} onClick={() => onTabClick(5)} />:""}
                            {similarCompaniesDataFound ? showSimilarCompanies ? <Tab active={isActive === 6} label={"Similar Companies"} onClick={() => onTabClick(6)} /> : null : ''}
                            {newsAndActivityDataFound ? <Tab active={isActive === 7} label={"Recent News & Activity"} onClick={() => onTabClick(7)} /> : ""}
                        </div>
                        <div className="bg-[#EDEDED] h-full space-y-5 details-page">
                            <section ref={overviewEl} className="scroll-mt-14" id="profile">
                                <ProfileHighlight profile={profileData} financials={data} menu={menu}/>
                            </section>
                                <section ref={mergerEl} id="merge" className="scroll-mt-14">
                                <MergerAcquisition
                                    companyName={profileData?.name}
                                    data={data.merger_acquisition}
                                />
                                </section>
                                <section ref={fundingEl} id="fund" className="scroll-mt-14">
                                    <FundingDataProvider>
                                        <Funding
                                        menu={menu}
                                        companyName={profileData?.name}
                                        funding={data?.private_equity}
                                        companyId={data?.pe_company_id}
                                        />
                                    </FundingDataProvider>
                                    </section>
                            <section ref={financialEl} className="scroll-mt-14" id="financial">
                                <CurrencyContextProvider>
                                    <Financials companyName={profileData?.name} profile={profileData} data={data} menu={menu}/>
                                </CurrencyContextProvider>
                            </section>
                            <section ref={captableEl} className="scroll-mt-14" id="cap">
                                <CapTable companyName={profileData?.name} />
                            </section>
                            {similarCompaniesDataFound && (
                                <section
                                ref={similarCompaniesEl}
                                className="scroll-mt-14"
                                id="similar"
                                >
                                <SimilarCompanies
                                    companyName={profileData?.name}
                                    data={data.similar_cos}
                                    cmpIds={data.similar_cos?.map(val => val.id)}
                                    menu={menu}
                                />
                                </section>
                            )}
                            <section ref={newsEl} className="scroll-mt-14" id="news">
                                <NewsActivity menu="companydetail" news={data.related_news} />
                            </section>
                        </div>
                    </div>
                    {showModal ? (<ComparePopUp id={companyId} onClose={(e) => setShowModal(false)} />) : null}
                </div>
            </div>
    ) : (
        isError && (
          <div className="w-full h-full flex justify-center items-center">
            <h1>No Data Found for this company</h1>
          </div>
        )
    );

}

export default MACompanyDetail