    import React, { useEffect } from "react";

    const SubcategorySelector = ({ subCategory,toggleTableHandler, category, setSubCategory  }) => {
    useEffect(()=>{
        setSubCategory('pe')
    }, [category])

    return (
        <div className="btn-container2 mt-8 mr-8 h-[41px] md:h-[45px] lg:h-[45px] xl:h-[45px]">
        <input
            type="radio"
            name="subCategory"
            id="PE"
            checked={subCategory === "pe"}
            onChange={() => toggleTableHandler("pe")}
            hidden
        />
        <label
            htmlFor="PE"
            className={`rounded-md h-[35px] md:h-[40px] lg:h-[40px] xl:h-[40px]  mt-[2px]  ml-[2px] mb-[2px] font-semibold  w-[100px] text-[14px] md:text-[15px] tracking-[2px] lg:text-[18px] lg:w-[200px] md:w-[150px] md:mb-[2px] cursor-pointer ${
            subCategory === "PE" ? "color: #efefef;" : ""
            }`}
        >
            PE
        </label>

        <input
            type="radio"
            name="subCategory"
            id="MA"
            checked={subCategory === "M&A"}
            onChange={() => toggleTableHandler("M&A")}
            hidden
        />
        <label
            htmlFor="MA"
            className={`rounded-md h-[35px] md:h-[40px] lg:h-[40px] xl:h-[40px]  mt-[2px]  ml-[2px] mb-[2px] font-semibold  w-[100px] text-[14px] md:text-[15px] tracking-[2px] lg:text-[18px] lg:w-[200px] md:w-[150px] md:mb-[2px] cursor-pointer ${
            subCategory === "M&A" ? "color: #efefef;" : ""
            }`}
        >
            M&A
        </label>
        </div>
    );
    };

    export default SubcategorySelector;
