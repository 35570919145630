import React from "react";
import MAHeroSec from "./MAHeroSec";
import Layout from "../../../components/layout";
import Container from "../../../components/layout/Container";
import starterPurple from "../../../assets/starter-img-purple-section.png";
import starterGreen from "../../../assets/starter-img-green-section.png";
import starterRed from "../../../assets/starter-img-red-section-1-1.png";

const MA = () => {
  return (
    <Layout>
      <MAHeroSec />
      <div className="relative">
        <div className="bg-violet-600 w-2/5 h-full absolute top-0 z-0 rounded-r-full"></div>
        <Container className="mt-8 p-4 flex flex-col gap-8 lg:flex-row relative z-20">
          <div>
            <img src={starterPurple} alt="" />
          </div>
          <div className="mx-auto flex flex-col gap-3 items-start md:max-w-lg">
            <div>
              <h1 className="my-4 text-violet-600 font-bold text-3xl lg:w-4/5">
                Find companies of interest with advanced search
              </h1>
              <p className="text-base text-gray-600">
                Use unlimited search and robust filters to easily find companies
                that fit your specific criteria, without manually searching
                across the web.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="relative">
        <div className="bg-teal-500 w-2/5 h-full absolute right-0 z-0 rounded-l-full"></div>
        <Container className="mt-8 p-4 flex flex-col gap-8 lg:flex-row-reverse relative z-20">
          <div>
            <img src={starterGreen} alt="" />
          </div>
          <div className="mx-auto flex flex-col gap-3 items-start md:max-w-lg">
            <div>
              <h1 className="my-4 text-teal-500 font-bold text-3xl lg:w-4/5">
                Track and monitor company activity with automatic updates
              </h1>
              <p className="text-base text-gray-600">
                Create custom lists to keep track of the companies you’re
                interested in, then get automatic updates to know when they
                raise funding or hire new leadership.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="relative">
        <div className="bg-red-500 w-2/5 h-full absolute top-0 z-0 rounded-r-full"></div>
        <Container className="mt-8 p-4 flex flex-col gap-8 lg:flex-row relative z-20">
          <div>
            <img src={starterRed} alt="" />
          </div>
          <div className="mx-auto flex flex-col gap-3 items-start md:max-w-lg">
            <div>
              <h1 className="my-4 text-red-500 font-bold text-3xl lg:w-4/5">
                Leverage rich company data to inform business decisions
              </h1>
              <p className="text-base text-gray-600">
                Learn more about the companies you’re monitoring by digging into
                their profiles, which feature key highlights, recent news and
                activity, signals, firmographics, funding, and partner data.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-gray-50">
        <Container>
          <div className="py-12 text-center">
            <h1 className="mx-auto my-4 text-2xl text-black font-bold tracking-tighter leading-relaxed md:text-[32px] xl:max-w-4xl">
              “Crunchbase is a
              <i className="text-blue-600"> key part of our research </i>because
              of its depth and breadth around high-growth companies, and now we
              can search with Crunchbase’s feature-rich UI.”
            </h1>
            <p className="my-4 p-2 text-gray-700 text-xl font-semibold rounded-md">
              Dan Weiner, CEO, RevelOne
            </p>
          </div>
        </Container>
      </div>
      <div className="bg-blue-600">
        <Container>
          <div className="py-12 text-center">
            <h1 className="mx-auto my-4 text-2xl text-white font-bold leading-relaxed md:text-4xl xl:max-w-4xl">
              Get Started with Crunchbase Starter
            </h1>
            <p className="my-4 p-2 text-white text-xl font-semibold rounded-md">
              See how Crunchbase Starter can help you with your company search
              and more.
            </p>
            <button className="my-4 px-12 py-4 bg-yellow-500 text-gray-800 font-semibold text-sm rounded-md">
              TRY STARTER FOR FREE
            </button>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default MA;
