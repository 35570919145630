import React, { useMemo } from "react";
import moment from "moment";
import DataTable from "../../../Components/DataTables/DataTable";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import { useContext } from "react";

const AdvisorNewsActivity = ({ newsActivity }) => {
  const { newsAndActivityDataFound } = useContext(DataFoundContext);

  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a custom sorting
 function for string values in a DataTable component. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.news ?? defaultVal
      );
    };
  }, []);

  /* The `dealDateSort` constant is a custom sorting function that is used to sort the data in the
  "Release Date" column of the DataTable component. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment
        .utc(rowA?.values[columnId].date)
        .diff(moment.utc(rowB?.values[columnId].date));
    };
  }, []);

  /* The `columns` constant is an array of objects that define the columns for the DataTable component.
 Each object represents a column and contains properties such as `id`, `Header`, `accessor`,
 `className`, `headerClassName`, `Cell`, and `sortType`. */
  const columns = useMemo(
    () => [
      {
        id: "news",
        Header: "News",
        accessor: "news",
        className:
          "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
        headerClassName: "w-[15%]",
        Cell: ({ value }) => {
          return (
            <a href={value.link} target="_blank" rel="noreferrer">
              {value.news}
            </a>
          );
        },
        sortType: customStringSortMemo,
      },
      {
        id: "releaseDate",
        Header: "Release Date",
        accessor: "releaseDate",
        className:
          "text-[#333333] pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
        headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
        Cell: ({ value }) => {
          return moment(value?.date).format("MMM DD, YYYY");
        },
        sortType: dealDateSort,
      },
    ],
    []
  );

  /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
  the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    if (newsActivity) {
      newsActivity?.forEach((newsData) => {
        rows.push({
          news: {
            news: newsData?.title,
            link: newsData?.news_link,
          },
          releaseDate: { date: newsData?.published_at }
        });
      });
    }

    return rows;
  }, [newsActivity]);
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sort options for the
  DataTable component. It specifies that the initial sorting should be done based on the
  "releaseDate" column in descending order (`desc: true`). The empty dependency array (`[]`) ensures
  that the sort options are only calculated once and not re-evaluated on subsequent renders. */
  const initialSort = useMemo(
    () => [
      {
        id: "releaseDate",
        desc: true,
      },
    ],
    []
  );
  return (
    <>
      {newsAndActivityDataFound ? (
        <div className="mx-4 ">
          <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4  px-5">
              <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
                Recent News & Activity
              </p>
            </div>
            <DataTable columns={columns} data={rowData} sortBy={initialSort} />
          </div>
          <br />
          <br />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AdvisorNewsActivity;
