import { React, useContext, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import StageFilter from "../../Components/RefineSearchComponents/StageFilter";
import RoundFilter from "../../Components/RefineSearchComponents/RoundFilter";
import CompanyTypeFilter from "../../Components/RefineSearchComponents/CompanyTypeFilter";
import YearFoundedFilter from "../../Components/RefineSearchComponents/YearFoundedFilter";
import DealTypeFilter from "../../Components/RefineSearchComponents/DealTypeFilter";
import SyndicationFilter from "../../Components/RefineSearchComponents/SyndicationFilter";
import DealsInvolvingFilter from "../../Components/RefineSearchComponents/DealsInvolvingFilter";
import CountryFilter from "../../Components/RefineSearchComponents/CountryFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import StateFilter from "../../Components/RefineSearchComponents/StateFilter";
import CityFilter from "../../Components/RefineSearchComponents/CityFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../../Components/RefineSearchComponents/InvestorHeadquartersFilter";
import DealRangeFilter from "../../Components/RefineSearchComponents/DealRangeFilter";
import ExitStatusFilter from "../../Components/RefineSearchComponents/ExitStatusFilter";
import ValuationFilter from "../../Components/RefineSearchComponents/ValuationFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import { filterLocationUrl, filterSectorByIndutry, tagList, tagSearchUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import { FaInfoCircle } from "react-icons/fa"
import { Modal, Tooltip } from "antd";
import DefinitionModal from "../TagDefinitionModal";
import useModule from "../../hooks/useModule";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

/**
 * The component renders a form for refining search filters.
 * @returns a JSX element.
 */
export default function LimitedPartnerRefineSearch({ customPathname, menu }) {
    const {
        dbDropdown , setDbDropdown ,isMobile,
        onErrorHandler,open,setOpen,
        refineSearchFilters,SwitchState,setSwitchState,dropdown,Sectors, setSectors,
        InvestorOptions , setInvestorOptions ,dealsView,checkToggleBtn,setToggleDealsBtn,
        lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions , TargetCompanyOptions , setTargetCompanyOptions ,AllCities,
        setrefineSearchFilters ,setMonthPickerValue ,HeadquarterCityOptions,setHeadquarterCityOptions,selectedInvestorHeadquartersCity,setSelectedInvestorHeadquartersCity} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(21);
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false
    const isFromGroupList =()=> refineSearchFilters[location.pathname]?.myList?.length > 0 ? true : false


    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [StageOptions, setStageOptions] = useState([])
    const [RoundOptions, setRoundOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [DealTypeOption, setDealTypeOption] = useState([]);
    const [SyndicationOptions, setSyndicationOptions] = useState([])
    const [DealsInvolvingOptions, setDealsInvolvingOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [HeadquartersOptions, setHeadquartersOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [DealRange, setDealRange] = useState({ from: "", to: "" })
    const [ExitStatusOptions, setExitStatusOptions] = useState([])
    const [ValuationOptions, setValuationOptions] = useState([])
    const [TagSwitchState, setTagSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])


    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedStages = StageOptions.filter(option => option.isChecked);
        const selectedRounds = RoundOptions.filter(option => option.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedDealType = DealTypeOption?.filter(option => option.isChecked);
        const selectedSynd = SyndicationOptions?.filter(option => option.isChecked);
        const selectedDealInvolving = DealsInvolvingOptions?.filter(option => option.isChecked);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedHeadCity = HeadquarterCityOptions?.filter(option => option.isChecked);
        const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
        const selectedHeadNames = selectedHeadCity.map(option => option.name);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
        const selectedExitStatus = ExitStatusOptions?.filter(option => option.isChecked);
        const selectedValuations = ValuationOptions?.filter(option => option.isChecked);
        const selectedOperators = SwitchState;
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        
        checkToggleBtn();
        setSelectedInvestorHeadquartersCity(HeadquarterCityOptions?.filter(option => option.isChecked));
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                stage: selectedStages,
                round: selectedRounds,
                companyType: selectedCompanyType,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                dealType: selectedDealType,
                syndication: selectedSynd,
                dealsInvolving: selectedDealInvolving,
                countries: selectedCountries,
                region: selectedRegion,
                investor_headquaters_city: selectedHeadCity,
                state: selectedState,
                city: selectedCity,
                investorType: selectedInvestorType,
                investorHeadquarters: selectedHead,
                firmType: selectedFirm,
                dealRange: DealRange?.from != "" && DealRange?.to != "" ? [{ id: 0, name: DealRange?.from }, { id: 1, name: DealRange?.to }] : [],
                exitStatus: selectedExitStatus,
                valuations: selectedValuations,
                operator: TagSwitchState ? 1 : 0,
                deals_operator : selectedOperators,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
                tags: TagsOptions,
                // myList:isFromGroupList()? Object.keys(refineSearchFilters[location.pathname] ?? {}).map((filter) => {
                //     let tagList = [];
                //     tagList = refineSearchFilters[location.pathname][filter];
                //     return [{id:0,name:tagList?.name, group_id:tagList?.group_id}]

                // }):null

            },
        }))
    }

    const resetDealsByFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setHeadquarterCityOptions(HeadquarterCityOptions.map(option => {
            if (option.isChecked) {
                return { ...option, isChecked: false };
            }
            return option;
        }));
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setSectors([]);
        setToggleDealsBtn(true);
    }

    useEffect(()=>{
        setToggleDealsBtn(true);
    },[customPathname])



 console.log(InvestorOptions , "jjjij")
    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        if(isMobile){
            setOpen(!open);
        }
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.pe_companies_pe_vc_investments);
        // }
        applyFilter();
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        setSelected(false)

    }

    const onResetClick = (event) => {

        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.pe_companies_pe_vc_investments);
        // }
        resetDealsByFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val == select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onCountryChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setRegionOptions(response.data?.region)
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                console.log(response.data.LPCities,"hkhkgfgf")
                setCountryOptions(response?.data?.LPCountries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setCityOptions(response?.data?.LPCities?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters,AllCities])


    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})


    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };
    return (
        <div className="h-screen">
            <div className= {isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[80%]" : "flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
                
                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                        
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="limited_partners"
                                open={select === 16} />
                        
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                        
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(10)}
                                onChange={onCountryChange}
                                open={select === 10} />
                        
                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                                <h1 className='font-bold text-[16px] ml-1'>
                                    SHOW LP BY
                                </h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Limited Partners"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="General Partner"
                                    open={select === 32}
                                />

                        </ul>
                    </main>
                    <div className="px-3  py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler}/>}
        </div>
    );
}

