import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Linkedin, Twitter, Facebook, Youtube, Globe } from "lucide-react";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import googlePlayImage from "../../assets/assets/gpfooter.png"; 
import appstoreImage from "../../assets/assets/appstore.png"; 

const Footer = ({ navLinks }) => {

  const socialLinks = [
    { 
      icon: Linkedin, 
      url: "https://in.linkedin.com/in/ventureintelligence",
      name: "LinkedIn"
    },
    { 
      icon: Twitter, 
      url: "https://twitter.com/ventureindia",
      name: "Twitter"
    },
    { 
      icon: Facebook, 
      url: "https://www.facebook.com/ventureintelligence",
      name: "Facebook"
    },
    { 
      icon: Globe, 
      url: "http://ventureintelligence.blogspot.in/",
      name: "Blogspot"
    },
    { 
      icon: Youtube, 
      url: "https://www.youtube.com/VentureIntelligence",
      name: "YouTube"
    },
  ];

  return (
    <footer className="bg-gradient-to-br bg-slate-100 py-12 px-4 md:px-8" id="Contact">
      <div className="max-w-7xl mx-auto">
        
        {/* Logo and Title Section */}
        <div className="flex justify-center mb-8">
          <h2 className="text-3xl font-bold text-center text-gray-900">
            Contact Us
          </h2>
        </div>

        {/* Navigation and Links */}
        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-4 gap-8 mb-12">
          {navLinks?.map((link) => (
            <div key={link.name} className="space-y-4">
              <h3 className="text-lg font-bold text-[#bb8b1f] border-b-2 border-[#bb8b1f] pb-2">
                {link.name}
              </h3>
              <ul className="space-y-2">
                {link?.options?.map((option) => (
                  <li key={option.name}>
                    <a 
                      href={option.to} 
                      className="text-[#5c4610] hover:text-[#bb8b1f] transition-colors duration-200"
                    >
                      {option.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Contact Information */}
        <div className="mb-12 bg-white/50 rounded-xl p-8 shadow-md">
          <div className="flex flex-col sm:flex-row justify-around">
            <div className="space-y-4 mb-8 sm:mb-0">
              <h4 className="font-semibold text-xl text-[#bb8b1f] mb-6">
                Registered Office & Mail Address
              </h4>
              <div className="flex items-center space-x-3 text-[#5c4610] hover:text-[#bb8b1f] transition-colors">
                <FontAwesomeIcon icon={faLocationDot} className="text-[#bb8b1f]" />
                <a href="https://goo.gl/maps/MrVkwNd3qL4zNSLv8" target="_blank">
                  1, Maharani Chinnamba Road, Alwarpet, Chennai-600 018, INDIA
                </a>
              </div>
            </div>
            <div className="space-y-4">
              <h4 className="font-semibold text-xl text-[#bb8b1f] mb-6">
                Chennai OMR Office
              </h4>
              <div className="flex items-center space-x-3 text-[#5c4610] hover:text-[#bb8b1f] transition-colors">
                <FontAwesomeIcon icon={faLocationDot} className="text-[#bb8b1f]" />
                <a href="https://goo.gl/maps/1ehpk9JaReGKUxt66" target="_blank">
                  30, 1st Street, Srinivasa Nagar, Kottivakkam, Chennai-600 096, INDIA
                </a>
              </div>
            </div>
          </div>

          {/* Contact Info */}
          <div className="my-8 flex flex-col justify-center items-center">
            <div className="flex md:items-center space-x-3 text-[#5c4610]">
              <FontAwesomeIcon icon={faPhone} className="text-[#bb8b1f]" />
              <p>+91- 9176000521 / 9176033455</p>
            </div>
            <div className="flex md:items-center space-x-3 mt-4 mb-4 md:mb-0">
              <FontAwesomeIcon icon={faEnvelope} className="text-[#bb8b1f]" />
              <a
                href="mailto:bizdev@ventureintelligence.com"
                className="text-[#5c4610] hover:text-[#bb8b1f] transition-colors"
              >
                bizdev@ventureintelligence.com
              </a>
            </div>
          </div>

          {/* Social Links */}
          <div className="flex flex-col sm:flex-row justify-around border-t pt-6 mt-6">
            <div className="text-center sm:w-1/2 pt-3 mt-3">
              <h4 className="font-semibold text-xl text-[#bb8b1f] mb-6">
                Follow us on 
              </h4>
              <div className="flex justify-center space-x-6">
                {socialLinks.map((social) => (
                  <a
                    key={social.name}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#5c4610] hover:text-[#bb8b1f] transition-colors duration-200"
                    title={social.name}
                  >
                    <social.icon size={24} />
                  </a>
                ))}
              </div>
            </div>

            {/* App Store Links */}
            <div className="pt-6 md:pt-3 mt-3 sm:w-1/2 text-center">
              <h4 className="font-semibold text-xl text-[#bb8b1f] mb-6">
                Find us on Google Play and the App Store
              </h4>
              <div className="flex justify-center space-x-4">
                <a href="https://play.google.com/store/search?q=%22venture%20intelligence%22&c=apps" target="_blank" rel="noopener noreferrer">
                  <img src={googlePlayImage} width={150} height={50} />
                </a>
                <a href="https://apps.apple.com/in/app/pe-vc-deals-database/id1500244706" target="_blank" rel="noopener noreferrer">
                  <img src={appstoreImage} width={150} height={50} />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="flex justify-between items-center border-t border-[#d1b77f] pt-6">
          <div className="text-sm text-[#5c4610]">
            © TSJ Media Pvt Ltd. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
