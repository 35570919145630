import React,{useEffect, useState, useContext} from 'react'
import axios from 'axios'
import { LatestActivitiyApi } from '../../../../Config/config';
import { UserContext } from '../../../../Contexts/UserContext';
import Loader from '../../../../Components/Loader';

/**
 * The `LatestActivity` function is a React component that fetches and displays the latest activity
 * data using an API call and renders it in a formatted grid layout.
 * @returns The LatestActivity component is returning a JSX element, which represents the UI of the
 * component.
 */
const LatestActivity = () => {

    const {getToken, getUser} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(()=>{
        axios.post(LatestActivitiyApi, {
            
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(true);
            if (response.status === 200) {
                setData(response?.data?.metricsResponse);
                console.log(response?.data?.metricsResponse,'response?.data?.metricsResponse');
                setIsLoading(false);
            }
        })
    },[])


    return (
        <div className="block border border-[#CECECE] rounded-[4px] m-2 p-2 bg-white col-span-6 min-h-[22rem] lg:min-h-[26rem]">
            <div className='h-full w-full bg-[#ffffff]'>
                <div className="sm:flex flex  items-center px-2 py-1 pb-[0.8rem] border-b-[#CCCCCC] bg-white border-b border-x-1 justify-center ">
                    <div className="contents space-x-3 items-center justify-between">
                        <div><p className="pt-2 text-[#333333] text-lg leading-[17px] font-semibold "> In Last 15 days </p></div>
                    </div>
                </div>
                
                <div className='h-[90%]'>
                    {isLoading ? 
                    <div className='bg-white w-full h-full flex items-center justify-center'>
                        <Loader /> 
                    </div> :
                    data ? 
                <div className='w-full h-full divide-y-2 p-2 lg:p-4 relative z-0'>
                    <div className='grid  grid-cols-2 divide-x-2 h-[50%]'>
                        <div className='px-3 py-2 lg:py-0'>
                            <p className='text-center text-[#474747] text-[20px] leading-[17px] font-bold '>PE - VC</p>
                            <div className='grid md:grid-cols-2 place-content-stretch gap-4 h-[70%] '>
                                {data['pe']?.map((data,i)=>{
                                    return (
                                        <div key={i} className='flex flex-col justify-center my-4 col-span-1 bg-white z-10 shadow-lg p-1 lg:p-3 custom-box-shadow'>
                                            <p className='text-center text-[30pt] leading-[51pt] font-bold lg:text-[40px] lg:leading-[60px] tracking-[-0.3px] text-[#A5711A]'>{data?.value ?? 0}</p>
                                             <p className="text-[#666666] text-[12px] text-center">{data?.label}</p>
                                         </div> 
                                    )
                                })}
                                
                            </div>
                        </div>                    
                        <div className='px-3 py-2 lg:py-0'>
                            <p className='text-center text-[#474747] text-[20px] leading-[17px] font-bold '>M&A</p>
                            <div className='grid md:grid-cols-2 place-content-stretch gap-4 h-[70%] '>
                            {data['ma']?.map((data,i)=>{
                                    return (
                                        <div key={i} className='flex flex-col justify-center my-4 col-span-1 bg-white z-10 shadow-lg p-1 lg:p-3 custom-box-shadow'>
                                            <p className='text-center text-[30pt] leading-[51pt] font-bold lg:text-[40px] lg:leading-[60px] tracking-[-0.3px] text-[#A5711A]'>{data?.value ?? 0}</p>
                                             <p className="text-[#666666] text-[12px] text-center">{data?.label}</p>
                                         </div> 
                                    )
                                })}
                               
                            </div>
                        </div>
                    </div>                
                    <div className='grid grid-cols-2 divide-x-2 h-[50%]'>
                        <div className='p-3'>
                            <p className='text-center text-[#474747] text-[20px] leading-[17px] font-bold'>PE-RE</p>
                            <div className='grid md:grid-cols-2 place-content-stretch gap-4 h-[70%] '>
                            {data['re']?.map((data,i)=>{
                                    return (
                                        <div key={i} className='flex flex-col justify-center my-4 col-span-1 bg-white z-10 shadow-lg p-1 lg:p-3 custom-box-shadow'>
                                            <p className='text-center text-[30pt] leading-[51pt] font-bold lg:text-[40px] lg:leading-[60px] tracking-[-0.3px] text-[#A5711A]'>{data?.value ?? 0}</p>
                                             <p className="text-[#666666] text-[12px] text-center">{data?.label}</p>
                                         </div> 
                                    )
                                })}
                                
                            </div>
                        </div>                                
                        <div className='p-3'>
                            <p className='text-center text-[#474747] text-[20px] leading-[17px] font-bold'>CFS</p>
                            <div className='grid md:grid-cols-2 place-content-stretch gap-4 h-[70%] '>
                            {data['cfs']?.map((data,i)=>{
                                    return (
                                        <div key={i} className='flex flex-col justify-center my-4 col-span-1 bg-white z-10 shadow-lg p-1 lg:p-3 custom-box-shadow'>
                                            <p className='text-center text-[30pt] leading-[51pt] font-bold lg:text-[40px] lg:leading-[60px] tracking-[-0.3px] text-[#A5711A]'>{data?.value ?? 0}</p>
                                             <p className="text-[#666666] text-[12px] text-center">{data?.label}</p>
                                         </div> 
                                    )
                                })}
                                
                            </div>
                        </div>                
                    </div>
                </div>
                : <div className='bg-white w-full h-full flex items-center justify-center'>
                 <Loader /> 
                 </div>
                }
                </div>
            </div>
        </div>
    )
}

export default LatestActivity
