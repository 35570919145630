import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import bg from "../../assets/assets/bgimage.jpg";
import { ChevronRight, ChevronLeft, Building2, BarChart3, Target, CheckCircle2, LineChart, TrendingUp, Calendar, PieChart, Lock, Award } from "lucide-react";

const HeroSec = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      mainTitle: "Venture Intelligence",
      title: "Your Premier Source for Private Company Data in India",
      description: "Unlock opportunities, make informed decisions, and stay ahead in the dynamic world of private company investments, transactions, financials, and valuations.",
      features: [
        {
          icon: <Building2 size={20} className="md:w-8 md:h-8" />,
          title: "Company Data",
          description: "Comprehensive insights"
        },
        {
          icon: <BarChart3 size={20} className="md:w-8 md:h-8" />,
          title: "Transactions",
          description: "PE, VC & M&A deals"
        },
        {
          icon: <LineChart size={20} className="md:w-8 md:h-8" />,
          title: "Valuations",
          description: "Pre-Money / Post Money / EV"
        }
      ]
    },
    {
      mainTitle: "Your Trusted Partner",
      title: "In Private Company Intelligence",
      description: "Launched in 2002, the VI service is India's leading source for private company financials, transactions (PE, VC, M&A), and valuations",
      features: [
        {
          icon: <CheckCircle2 size={20} className="md:w-8 md:h-8" />,
          title: "Efficient Formats",
          description: "Spot the right business opportunities"
        },
        {
          icon: <Target size={20} className="md:w-8 md:h-8" />,
          title: "Wide Usage",
          description: "Industry practitioners & entrepreneurs"
        },
        {
          icon: <TrendingUp size={20} className="md:w-8 md:h-8" />,
          title: "Media Trust",
          description: "Educational & media reference"
        }
      ]
    },
    {
      mainTitle: "Our Differentiators",
      title: "Why Choose Us?",
      differentiators: [
        {
          icon: <Calendar size={20} className="md:w-7 md:h-7" />,
          title: "On ground presence since 2002"
        },
        {
          icon: <PieChart size={20} className="md:w-7 md:h-7" />,
          title: "Transaction Valuations Multiples"
        },
        {
          icon: <TrendingUp size={20} className="md:w-7 md:h-7" />,
          title: "Return Multiples of PE/VC Deals"
        },
        {
          icon: <Lock size={20} className="md:w-7 md:h-7" />,
          title: "Deals unavailable in public domain"
        },
        {
          icon: <Award size={20} className="md:w-7 md:h-7" />,
          title: "Independent. Accurate. Reliable.",
          centered: true
        }
      ]
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 8000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative h-[80vh] md:h-[90vh] flex items-center justify-center overflow-hidden">
      <div 
        className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
      </div>

      <div className={`relative z-10 container mx-auto px-3 md:px-4 flex flex-col h-full ${slides[currentSlide].mainTitle === "Our Differentiators" ? 'py-[3rem] md:py-[5rem]' : 'py-[5rem]'}`}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-6xl mx-auto space-y-3 md:space-y-6 text-center flex-1"
          >
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-2xl md:text-xl lg:text-4xl font-bold"
            >
              <span className="text-[#efefef]">{slides[currentSlide].mainTitle}</span>
            </motion.h1>
            
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="text-xl md:text-[30px] text-[#c59c40] font-semibold px-2"
            >
              {slides[currentSlide].title}
            </motion.h2>

            {currentSlide !== 2 && (
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="text-sm md:text-lg text-gray-300 max-w-4xl mx-auto px-2"
              >
                {slides[currentSlide].description}
              </motion.p>
            )}

            {currentSlide !== 2 && (
              <div className="grid grid-cols-3 gap-2 md:gap-8 mt-4 md:mt-8 px-2">
                {slides[currentSlide].features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 * index }}
                    className="bg-white/10 backdrop-blur-sm p-2 md:p-6 rounded-xl hover:bg-white/15 
                              transition-all duration-300 border border-white/10"
                  >
                    <div className="text-[#c59c40] mb-1 md:mb-3 flex justify-center">
                      {feature.icon}
                    </div>
                    <h3 className="text-[#efefef] text-xs md:text-lg font-semibold mb-0.5 md:mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-300 text-[10px] md:text-base">
                      {feature.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            )}

            {currentSlide === 2 && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mt-4 md:mt-8 max-w-4xl mx-auto px-2">
                {slides[2].differentiators.slice(0, 4).map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-2 md:p-4
                             hover:bg-white/15 transition-all duration-300 flex items-center gap-2 md:gap-4"
                  >
                    <div className="bg-[#c59c40] rounded-lg p-1.5 md:p-2 text-white">
                      {item.icon}
                    </div>
                    <h3 className="text-sm md:text-base text-white font-semibold">
                      {item.title}
                    </h3>
                  </motion.div>
                ))}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  className="col-span-1 md:col-span-2 bg-white/10 backdrop-blur-sm border border-white/10 
                           rounded-xl p-2 md:p-4 hover:bg-white/15 transition-all duration-300 
                           flex items-center gap-2 md:gap-4 max-w-md mx-auto"
                >
                  <div className="bg-[#c59c40] rounded-lg p-1.5 md:p-2 text-white">
                    {slides[2].differentiators[4].icon}
                  </div>
                  <h3 className="text-sm md:text-lg text-white font-semibold">
                    {slides[2].differentiators[4].title}
                  </h3>
                </motion.div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className={`flex justify-center mb-4 md:mb-8 mt-6 ${
          currentSlide === 2 ? "md:mt-8" : "md:mt-16"
        }`}>
          <div className="flex space-x-2 md:space-x-3">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 md:w-4 md:h-4 rounded-full transition-all duration-300 ${
                  currentSlide === index
                    ? "bg-[#c59c40] scale-125"
                    : "bg-gray-500 opacity-50 hover:opacity-75"
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        <button
          onClick={prevSlide}
          className="absolute left-1 md:left-8 top-1/2 transform -translate-y-1/2 bg-white/10 backdrop-blur-sm 
                     rounded-full p-1.5 md:p-3 text-white hover:bg-[#c59c40] transition-all duration-300 z-20"
          aria-label="Previous slide"
        >
          <ChevronLeft size={16} className="md:w-6 md:h-6" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-1 md:right-8 top-1/2 transform -translate-y-1/2 bg-white/10 backdrop-blur-sm 
                     rounded-full p-1.5 md:p-3 text-white hover:bg-[#c59c40] transition-all duration-300 z-20"
          aria-label="Next slide"
        >
          <ChevronRight size={16} className="md:w-6 md:h-6" />
        </button>
      </div>
    </div>
  );
};

export default HeroSec;