import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import {  useParams, matchPath, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import compare from '../../../images/compare.png'
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import {  exportPdf, getAdvisorDetails } from "../../../Config/config"
import AdvisorOverview from './NewAdvisorOverview'
import AdvisorCompaniesInvestments from './NewAdvisorToCos'
import AdvisorCompaniesExits from './NewAdvisorToExits'
import AdvisorTo_Investors from './NewAdvisorToInvestors'
import AdvisorTo_Acquirer from './NewAdvisorToAcquirer'
import AdvisorNewsActivity from './NewAdvisorNewsActivity'
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext'
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import ComparePopUp from '../../../Pages/ComparePopUp'
import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from "../../SendDealPopUp";
import AddNote from "../../../Pages/addNote"
import PdfDownloader from "../../shared/PdfDownloader";
import CompanyLogo from "../../NewLogo";
import Constants from "../../../Constants";
import { Tooltip } from "antd";
import routePathSegments, { routeNames } from "../../../routeSegments";
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import AddNotePopover from "../../shared/AddNotePopover";
import Tab from '../../shared/Tab';
import useModule from '../../../hooks/useModule';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


 
const AdvisorCompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' });; }
    const exits = () => { document.getElementById("exits").scrollIntoView({ behavior: 'smooth' });; }
    const funds = () => { document.getElementById("funds").scrollIntoView({ behavior: 'smooth' });; }
    const toAcquirer = () => { document.getElementById("acquirer").scrollIntoView({ behavior: 'smooth' });; }
    const similarCompanies = () => { document.getElementById("news").scrollIntoView(); }
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const { companyDetail, setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);
    const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
    const location = useLocation();
    const {primaryMasterId,isPE,isVC} = useModule();    
    const [note, setNote] = useState(false)
    const [showNote, setShowNote] = useState(false)
    const [open, setOpen] = useState()
    const { getToken, isStudent, isDirectoryUser } = useContext(UserContext);
    const { toCompaniesInvesmentsDataFound, 
        setToCompaniesInvesmentsDataFound, 
        toCompaniesExitsDataFound,
        setToCompaniesExitsDataFound, 
        toInvestorsDataFound, 
        setToInvestorsDataFound, 
        toAcquirersDataFound, 
        setToAcquirersDataFound,
        newsAndActivityDataFound,
        setNewsAndActivityDataFound } = useContext(DataFoundContext);

    const [profileHighlights, setProfileHighlights] = useState([]);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [isCompanyExit, setIsCompanyExit] = useState([])
    const [companyInvestment, setCompanyInvestment] = useState([])
    const [advisorAcquirer, setAdvisorAcquirer] = useState([]);
    const [toInvestor, setToInvestor] = useState([])
    const [advisorNews,setAdvisorNews]=useState([])
    const [skipScroll, setSkipScroll] = useState(false)
    const [overviewEl, isOverviewVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [investmentEl, isInvestmentVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [exitEl, isExitVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toInvestorEl, istoInvestorVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toAcquirerEl, istoAcquireVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })

   
    /**
     * The function `sendDetails` sends a POST request to the AdvisorDetailPage endpoint with specific
     * parameters and headers, and then sets various state variables based on the response data.
     */
    const getDetails = () => {
        axios.get(`${getAdvisorDetails}${advisorProfileId}/`).then(response => {
            if (response.status == 200) {
                setProfileHighlights(response.data?.profile)
                if (response.data?.to_cos.length > 0){
                    // console.log((response.data?.to_cos.length > 0));
                    setToCompaniesInvesmentsDataFound(true);
                    setCompanyInvestment(response.data?.to_cos)
                } else setToCompaniesInvesmentsDataFound(false);
                
                if(response.data?.to_cos_exits.length > 0){
                    setToCompaniesExitsDataFound(true);
                    setIsCompanyExit(response.data?.to_cos_exits)
                } else setToCompaniesExitsDataFound(false);
                
                if (response.data?.to_investors?.length > 0){
                    setToInvestorsDataFound(true);   
                    setToInvestor(response.data?.to_investors)
                }else setToInvestorsDataFound(false)
                
                if (response.data?.to_acquirers.length > 0){
                    setToAcquirersDataFound(true);
                    setAdvisorAcquirer(response.data?.to_acquirers)
                } else setToAcquirersDataFound(false)

                if (response.data?.related_news.length > 0){
                    setNewsAndActivityDataFound(true);
                    setAdvisorNews(response.data?.related_news);
                } else if (response.data?.related_news.length === 0 || response.data.success === false){
                    setNewsAndActivityDataFound(false);
                }
                setIsProfileLoading(false);
                setOpen(false);
            }
        }).catch(error => {
                setToCompaniesInvesmentsDataFound(false);
                setToCompaniesExitsDataFound(false);
                setToInvestorsDataFound(false);
                setToAcquirersDataFound(false);
                setNewsAndActivityDataFound(false);
        })
    }


    useEffect(() => {
        getDetails();
        return () => {
            setToCompaniesInvesmentsDataFound(false);
            setToCompaniesExitsDataFound(false);
            setToInvestorsDataFound(false);
            setToAcquirersDataFound(false);
            setNewsAndActivityDataFound(false);
        }

    }, [advisorProfileId])

    const menuRef = useRef();

    const getURL = localStorage.getItem("currenturl");
    const breadcrumb = (getURL == '/advisors/pe-vc-advisors') ? 'PE-VC Advisor'
        : (getURL == '/advisors/vc-advisors') ? 'VC Advisor'
            : (getURL == '/advisors/pm/vc-advisors') ? 'PM_VC-Advisor'
                : (getURL == '/advisors/ma/vc-advisors') ? 'MA_VC-Advisor'
                    : ''
    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);


   /**
    * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
    * selected tab index.
    * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
    * is used to determine which action to perform based on the clicked tab.
    */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            investments();

        } else if(tabIndex ===3 ){
            exits ()
        }
        else if (tabIndex === 4) {
            funds();
        }
        else if (tabIndex === 5) {
            toAcquirer();
        }

        else if (tabIndex === 6) {
            similarCompanies();
        }

        setIsActive(tabIndex);
    }
   /* The above code is using the `useMemo` hook in React to create a memoized version of the
   `setIsActiveOnScroll` function. This function is using the `debounce` function to delay the
   execution of the `setIsActive` function by 100 milliseconds. The `setIsActive` function is a
   callback function that takes an index as an argument and updates the state to set the active
   index. */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isInvestmentVisible) {
            setIsActiveOnScroll(2);
        } else if (isExitVisible) {
            setIsActiveOnScroll(3);
        } else if (istoInvestorVisible) {
            setIsActiveOnScroll(4);
        } else if (istoAcquireVisible) {
            setIsActiveOnScroll(5);        
        } else if (isnewsVisible) {
            setIsActiveOnScroll(6);
        }
    }, [isOverviewVisible, istoInvestorVisible, istoAcquireVisible, isInvestmentVisible, isExitVisible, isnewsVisible, setIsActiveOnScroll])

    const subTypeMasterId =() =>{
        if(isPE){ 
            if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_id){
            return Constants.subCategoryIds.pe_ladvisor_investments_id
            }
            else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_exits_id){
            return Constants.subCategoryIds.pe_ladvisor_exits_id
            }
            else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_ma_id){
            return Constants.subCategoryIds.pe_ladvisor_exitsma_id
            }
            else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_pm_id){
            return Constants.subCategoryIds.pe_tadvisor_exitspm_id
            }    
        }
        else if(isVC){
            if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_id){
                return Constants.subCategoryIds.vc_ladvisor_investments_id
              }
              else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_exits_id){
                return Constants.subCategoryIds.vc_ladvisor_exits_id
              }
              else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_ma_id){
                return Constants.subCategoryIds.vc_ladvisor_exitsma_id
              }
              else if(props?.section == Constants.primaryMasterSubIds.pe_vc_advisors_pm_id){
                return Constants.subCategoryIds.vc_ladvisor_exitspm_id
              }    
        }
      } 

    const { tableAdvisorId: advId } = useContext(TableDataContext);

    const currentIndex = advId?.indexOf(Number(companyId));

    document.title = `${profileHighlights?.name ? profileHighlights.name + " - " : ""}PE-VC Legal Advisors - Venture Intelligence`;

    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 border-b " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={location.state?.prevPath != null
                                   ? location.state?.prevPath
                                   : peMatch != null ? routeNames.PE_advisor : routeNames.VC_advisor} className="ml-1 text-[12pt] lg:text-[12px] text-[#666666]">
                                    Advisors</NavLink>
                            </div>
                        </li>
                        <li className='flex items-center'>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <a className="text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profileHighlights?.name ? profileHighlights?.name : "--"}</a>
                            </div>
                        </li>
                    </ol>
                </nav>
                 {advId && 
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={props.menu === 'vc' ? routeNames.vcAdvisorById(advId[currentIndex - 1]) : routeNames.peAdvisorById(advId[currentIndex - 1])} 
                            >
                            {currentIndex === 0 || advId.length === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={props.menu === "vc"
                                ? routeNames.vcAdvisorById(advId[currentIndex + 1])
                                : routeNames.peAdvisorById(advId[currentIndex + 1])
                            }
                            >
                            {advId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                {/* <!-- BarRaiser--> */}
                {Object.keys(profileHighlights).length <= 0 ?
                    <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                        <center><div role="status" className="bg-white  h-[16rem]">
                            <svg className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                        </div></center>
                    </table>
                    :
                    <div className="pl-5 pr-3 ">
                        <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4">
                            <div className=" md:col-span-6 col-span-5" title="Compare Company">
                                <button
                                    className={
                                        showModal
                                            ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                            : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                    }
                                    type="button"
                                    onClick={() => {
                                        setShowModal(!showModal)
                                        setCompanyDetail([])
                                        setCurrentPage('l_advisor')
                                    }
                                    }
                                >
                                    <img
                                        className={
                                            showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                        }
                                        src={compare}
                                        alt=""
                                    />
                                    <span>Compare</span>
                                </button>
                            </div>
                            <div className="flex md:col-span-2 col-span-5 space-x-3">
                                <div >
                                    <AlertPopUp cid={companyId} type_id={Constants.moduleIds.l_advisor_id} page="advisor"/>
                                </div>
                                <div >
                                    {/* <OutsideClickHandler onOutsideClick={() => { setShowNote(false); }} >
                                        <span > */}
                                    <img className=" cursor-pointer w-[24px] h-[22px]"
                                        src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                        alt=""
                                    />
                                    {showNote ? <AddNote module="Advisors" page="detailpage" type_id={Constants.moduleIds.l_advisor_id} cname={profileHighlights?.name ?? ""} cid={companyId} sub_type_master_id={subTypeMasterId()}/> : ""}
                                    {/* </span>
                                    </OutsideClickHandler> */}
                                </div>


                                <div className="col-span-1">
                                    <PENotesPopup note={note} cid={companyId} type_id={Constants.moduleIds.l_advisor_id} />
                                </div>
                                <div className="col-span-1">
                                    <SendDealPopUp data={profileHighlights?.name ?? ""} />
                                </div>
                                {isStudent == 'true' ? <></> : 
                                <PdfDownloader
                                    url={exportPdf}
                                    fileName={profileHighlights?.name?.replaceAll(/\s/g, '')}
                                    data={{
                                        entity_id: companyId,
                                        type_id: props.advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id,
                                    }} />
                                }

                                {/* <div >
                                    <img className=" w-5 h-6" src={pdf} alt="" />
                                </div> */}
                            </div>
                        </div>
                        <div className=" pb-4 pt-6 bg-[#FFFFFF]">
                            <ul role="list" >
                                <li className="w-full flex ">
                                    <div className=" rounded-md">
                                        <CompanyLogo
                                            name={profileHighlights?.name}
                                            id={advisorProfileId}
                                            menu="advisor"
                                            page="detail page"
                                        />
                                    </div>

                                    <div className="ml-3 flex flex-1 flex-col">
                                        <a className="text-[#333333] text-[22px] font-semibold leading-[24px]" href="#"> {profileHighlights?.name ? profileHighlights?.name : "--"} </a>
                                        <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">{profileHighlights?.advisor_type+" Advisor" ?? "--"}</span>
                                    </div>
                                    <div className="hidden lg:grid grid-cols-8  gap-2">
                                        <div className="col-span-3 mt-2">
                                            <Tooltip title="Compare Legal Advisor">
                                                <button

                                                    className={
                                                        showModal
                                                            ? "bg-[#BC8B20] text-white text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"
                                                            : "bg-[#FFFFFF] text-[#666666] text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                                    }
                                                    type="button"
                                                    onClick={() => {
                                                        setShowModal(!showModal)
                                                        setCompanyDetail([])
                                                        setCurrentPage('l_advisor')
                                                    }
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            showModal
                                                                ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                                : "w-[20px] h-[18px] pr-1 blur-btn"
                                                        }
                                                        src={compare}
                                                        alt=""
                                                    />
                                                    <span>Compare</span>
                                                </button>
                                            </Tooltip>
                                        </div>
                                        {/* <div className="col-span-2 ">
                                            <button className={showAdd ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center" : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"}
                                                type="button" onClick={() => setShowAdd(!showAdd)}>
                                                <img className={showAdd ?
                                                    "pr-2 brightness-0 invert-[1]" : "pr-2"} src={addNoted} alt="" />
                                                <span>Add</span>
                                            </button>
                                        </div> */}
                                        <div className="col-span-5 mt-[5px]">
                                            <div className="grid grid-cols-5 gap-1 flex items-center">
                                                <div className="col-span-1 ">
                                                    <AlertPopUp cid={companyId} type_id={Constants.moduleIds.l_advisor_id} page="advisor"/>{" "}
                                                </div>

                                                <div className="col-span-1" ref={menuRef}>
                                                    <AddNotePopover
                                                        title="Save List"
                                                        companyId={companyId}
                                                        companyName={profileHighlights?.name ?? ""}
                                                        type_id={Constants.moduleIds.l_advisor_id} 
                                                        sub_type_master_id={subTypeMasterId()}
                                                        IsDetailed={true}/>
                                                    {/* <Tooltip title="Save List">
                                                        <img
                                                            className="w-[22px] h-[22px] cursor-pointer"
                                                            src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                                            alt=""
                                                        />
                                                        {showNote ? <AddNote module="Advisors" page="detailpage" type_id={Constants.moduleIds.l_advisor_id} cname={profileHighlights?.name ?? ""} cid={companyId} /> : ""}
                                                    </Tooltip> */}
                                                </div>
                                                <div className="col-span-1 mt-1">
                                                    <PENotesPopup note={note} cid={companyId} type_id={Constants.moduleIds.l_advisor_id} />
                                                </div>
                                                <div className="col-span-1">
                                                    <SendDealPopUp data={profileHighlights?.name ?? ""} />
                                                </div>
                                                {isStudent =='true' ? "" :
                                                <div className="col-span-1">
                                                    <PdfDownloader
                                                        url={exportPdf}
                                                        fileName={profileHighlights?.name?.replaceAll(/\s/g, '')}
                                                        data={{
                                                            entity_id: companyId,
                                                            type_id: props.advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id,
                                                        }} />
                                                </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- tags--> */}
                        <div className="bg-[#FFFFFF]"></div>
                    </div>
                }
                

            </div>
            <div className="bg-[#EDEDED]  ">

            <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    {toCompaniesInvesmentsDataFound ? <Tab active={isActive === 2} label={"To Companies (Investments)"} onClick={() => onTabClick(2)} /> : ""}
                    {toCompaniesExitsDataFound ? <Tab active={isActive === 3} label={"To Companies (Exits)"} onClick={() => onTabClick(3)} /> : ''}
                    {toInvestorsDataFound ? <Tab active={isActive === 4} label={"To Investors"} onClick={() => onTabClick(4)} /> : ""}
                    {toAcquirersDataFound ? <Tab active={isActive === 5} label={"To Acquirers"} onClick={() => onTabClick(5)} /> : ""}
                    {newsAndActivityDataFound ? <Tab active={isActive === 6} label={"Recent News & Activity"} onClick={() => onTabClick(6)} />: ''}
                </div>

                <div className="space-y-5">
                    <section ref={overviewEl}  className="scroll-mt-14" id="profile"><AdvisorOverview isLoading={isProfileLoading} profileHighlights={profileHighlights} /></section>
                    <section ref={investmentEl} className="scroll-mt-14" id="investments"><AdvisorCompaniesInvestments companyInvestment={companyInvestment} advisorName={profileHighlights?.name} menu={props.menu} /></section>
                    <section ref={exitEl} className="scroll-mt-14" id="exits"><AdvisorCompaniesExits companyExits={isCompanyExit} advisorName={profileHighlights?.name} menu={props.menu}/></section>
                    <section ref={toInvestorEl} className="scroll-mt-14" id="funds"><AdvisorTo_Investors toInvestor={toInvestor} advisorName={profileHighlights?.name} menu={props.menu}/></section>
                    <section ref={toAcquirerEl} className="scroll-mt-14" id="acquirer"><AdvisorTo_Acquirer toAquirer={advisorAcquirer} advisorName={profileHighlights?.name} menu={props.menu}/></section>
                    <section ref={newsEl} className="scroll-mt-14" id="news"><AdvisorNewsActivity newsActivity={advisorNews} /></section>
                </div>
            </div>
            {showModal ? (<ComparePopUp onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default AdvisorCompanyDetail