import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import TableLoader from "./TableLoader";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// Initialize pdfMake with fonts
if (typeof pdfMake !== 'undefined') {
  pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts;
}

const Table = ({ loading, tableData, subCategory, showSearchForm, setShowSearchForm }) => {
  const [sortedData, setSortedData] = useState(null);
  const [sortedDirection, setSortedDirection] = useState(null);

  useEffect(() => {
    if (tableData && Array.isArray(tableData)) {
      setSortedData([...tableData]);
    }
  }, [tableData]);

  const sortByDealsHandler = () => {
    if (!sortedData) return;
    
    let sorted = [...sortedData];
    if (sortedDirection === "asc") {
      sorted.sort((a, b) => b.deal_count - a.deal_count);
      setSortedDirection("desc");
    } else {
      sorted.sort((a, b) => a.deal_count - b.deal_count);
      setSortedDirection("asc");
    }
    setSortedData(sorted);
  };

  const sortByAmountHandler = () => {
    if (!sortedData) return;
    
    let sorted = [...sortedData];
    if (sortedDirection === "asc") {
      sorted.sort((a, b) => b.amount_sum - a.amount_sum);
      setSortedDirection("desc");
    } else {
      sorted.sort((a, b) => a.amount_sum - b.amount_sum);
      setSortedDirection("asc");
    }
    setSortedData(sorted);
  };

  // Safely access the table data
  const renderTableData = sortedData || [];

  return (
    <div>
      {loading ? (
        <TableLoader />
      ) : (
        <div className="text-center overflow-x-hidden md:flex justify-center">
          <table
            id="table-to-export"
            className="md:mx-4 lg:mx-4 xl:mx-4 2xl:mx-4 bg-[aliceblue] text-xs font-sans rounded-lg overflow-hidden md:text-xl md:leading-[3rem] lg:w-4/5"
          >
            <thead className="border-b border-[#A26F35]">
              <tr className="text-[#A26F35]">
                <th className="py-2 px-4 md:px-8 lg:px-8 xl:px-8 2xl:px-8">Rank</th>
                <th className="text-left">Company Name</th>
                <th className="px-8">
                  #of Deals
                  <span
                    className="md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 ml-1 cursor-pointer"
                    onClick={sortByDealsHandler}
                  >
                    <FontAwesomeIcon
                      icon={faSort}
                      size="xs"
                      style={{ color: "#a26f35" }}
                    />
                  </span>
                </th>
                <th className="px-8 py-[0.6rem]">
                  Amount $M
                  <span
                    className="mx-2 cursor-pointer"
                    onClick={sortByAmountHandler}
                  >
                    <FontAwesomeIcon
                      icon={faSort}
                      size="xs"
                      style={{ color: "#a26f35" }}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderTableData.map((league, index) => (
                <tr
                  className="my-2 border-t-2 text-[black] hover:text-[#A26F35] hover:bg-gray-100 transition-colors duration-300 transform hover:scale-[1.0001] hover:shadow-md"
                  key={index + 1}
                >
                  <td>{index + 1}</td>
                  <td className="text-left">{league.advisor_name}</td>
                  <td>{league.deal_count}</td>
                  <td>{league?.amount_sum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;