import { React, useState, useEffect, useContext, useMemo } from "react";
import { useParams, useLocation, matchPath } from "react-router-dom";
import { exportExcel, getFinancialUrl } from "../../Config/config";
import FilingsFinancialTable from '../CFS/DetailsPage/CFSFinancial/Filings'
import FormMCSite from '../CFS/DetailsPage/CFSFinancial/FormMCSite'
import ExcelDownloader from "../shared/ExcelDownloader";
import routePathSegments from "../../routeSegments";
import { CurrencyContext } from '../../Contexts/CurrencyContext'
import { UserContext } from "../../Contexts/UserContext";
import PLStatement from "./FinancialComponents/PLStatement";
import Constants from "../../Constants";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import useModule from "../../hooks/useModule";
import { Tooltip } from "antd";
import Loader from "../Loader";
import downloadIcon from '../../images/Download 3.svg'
import PL from "./FinancialComponents/PL";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import { useGetRatio } from "./FinancialComponents/ratios";
import Ratio from "./FinancialComponents/Ratio";
import UpdateFinancial from "./FinancialComponents/UpdateFinancial";
import updateFinacialIcon from '../../images/updateFinancials.png'
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";


const FinancialTabs = ({companyName, profile, data, menu, page }) => {

  const location = useLocation();

  const cfsMatch = matchPath(`${routePathSegments.cfs}/*`, location.pathname);

  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(true);
  const { currencyType, currency, setIsDetailed} = useContext(CurrencyContext);
  const { openRequestPopup } = useContext(RaiseRequestContext);
  const [financialData, setFinancialData] = useState();

  const {isTrialExport, isStudent, isDirectoryUser } = useContext(UserContext);
  const [isDetail, setIsDetail] = useState(menu === "cfs");


  const { companyId } = useParams();
  const [alert, setalert] = useState({ message: "", show: false });
  const { primaryMasterId } = useModule();
  const [isLoading, setIsLoading] = useState(false);
  let companyProfileId = parseInt(companyId);
  const [isData, setIsData] = useState(false);
  const [companyLabel, setCompanyLabel] = useState("XBRL");
  const [isExport, setIsExport] = useState(true);
  const [isFiling,setIsFilling] = useState()
  const [showFilings , setShowFilings] = useState(false)

 const ratioData = useGetRatio(data);

 /**
  * The function `onDetailsClick` checks if a module called "cfs" is included in a list called
  * `allowedModulesList`, and if so, it sets the value of `isDetail` to the provided `value`. If "cfs"
  * is not included in `allowedModulesList`, it displays an alert message.
  * @param value - The value parameter is a variable that represents the value to be passed to the
  * setIsDetail function.
  */
 useEffect(()=>{
   if(allowedModulesList.includes("cfs")){
    setShowFilings(false)
   }else{
    setShowFilings(true)
   }
 },[])
  const onDetailsClick = (value) => {
    if ( allowedModulesList.includes('cfs')) {
      setIsDetail(value);
    } else {
      setalert({ show: true, message: Constants.cfsSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }
  }

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })
  }

  const { plStandardKeys, balanceSheetKeys, cashFlowKeys, ratioKeys, nonDetailedPLKeys, nonDetailedBSKeys, nonDetailedCFKeys, nonDetailedRatioKeys } = useContext(TableHeaderContext);
console.log(data?.cfs_profile?.full_name,"kjhkhk")
   const fileName = useMemo(() => {
    // isDataClick(openTab,financialData)
    if (openTab === 1) {
      return Constants.exportNames.plStatement(profile?.full_name || data?.cfs_profile?.full_name || companyName)
    } else if (openTab === 2) {
      return Constants.exportNames.balanceSheet(profile?.full_name || data?.cfs_profile?.full_name || companyName)
    } else if (openTab === 3) {
      return Constants.exportNames.cashflow(profile?.full_name || data?.cfs_profile?.full_name || companyName)
    } else if (openTab === 4) {
      return Constants.exportNames.ratios(profile?.full_name || data?.cfs_profile?.full_name || companyName)
    } else {
      return Constants.exportNames.plStatement(profile?.full_name || data?.cfs_profile?.full_name || companyName)
    }
  }, [openTab, profile?.full_name])

  return (
    <div>
      <div className="mx-4">
        <div className=" bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className={`py-3 px-5 border-b border-b-[#F1F1F1]`}>
            <div className="mt-3 lg:mt-0 flex md:flex justify-between space-y-2 md:space-y-0">
              <h3 className="text-[14pt] pr-2 md:pr-0 leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] lg:mt-3 flex items-end">
                Financials
              </h3>
              {openTab === 4 || openTab === 5 || openTab === 6 || data?.length === 0
                ? <div></div>
                  :
                <span className="flex items-center md:justify-center justify-between md:space-x-4 space-x-2 md:pr-2">
                  <div className="flex space-x-4">
                  {isStudent == 'false' && data?.profit_loss?.length !== 0 && isExport ? (
                    <>
                      <ExcelDownloader
                        url={exportExcel}
                        fileName={fileName}
                        data={{
                          "export_from": "detail",
                          "module_type": "investments",
                          "section": "financials",
                          "type": primaryMasterId,
                          "is_detailed": isDetail,
                          "currency_cd": currencyType,
                          "display_in": "A",
                          "company_id": [companyProfileId],

                        }} >
                        <Tooltip title="Export">
                          <img src={downloadIcon} className="cursor-pointer h-[26px] w-[24px]" alt="" />
                        </Tooltip>  

                      </ExcelDownloader> 
                      {menu !== "cfs" && allowedModulesList.includes("cfs") &&
                        <Tooltip title="Update financials">
                          <img src={updateFinacialIcon} className="cursor-pointer  h-[25px] w-[24px]" alt="" onClick={() => openRequestPopup("UpdateFinancials", "CFS")}/>
                        </Tooltip>
                      }
                    </>
                  )  : (
                    <></>
                  )}
                    </div>
                </span>
              }
            </div>
          </div>
          {
            isLoading
              ? <div className="w-full h-40 flex items-center justify-center">
                <Loader />
              </div>
              : <>
                {/* Fiancial  Header Section */}
                <div className="justify-between flex">
          <div className="flex flex-row overflow-x-auto space-x-9 scrollbar px-5 border-b border-b-[#F1F1F1] scrollbar-remove">
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[15px] block cursor-pointer " +
                (openTab === 1
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "  text-[#2B2A29] ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              role="tablist"
            >
              Profit & Loss Statement
            </a>

            <a
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px] block lg:leading-[15px]  cursor-pointer` +
                (openTab === 2
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              role="tablist"
              // disabled={isDirectoryUser}
            >
              Balance Sheet
            </a>

            <button
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px]   block lg:leading-[15px] ${companyLabel !='XBRL' || data?.cash_flow?.length == 0 ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 3
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              disabled={data?.cash_flow?.length == 0}
              data-toggle="tab"
              role="tablist"
              
            >
              Cash Flow
            </button>

            <button
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px]   block lg:leading-[15px] ${isDirectoryUser && !allowedModulesList.includes('cfs') || data?.balance_sheet?.length == 0 ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 4
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(4);

              }}
              data-toggle="tab"
              role="tablist"
              disabled={data?.balance_sheet?.length == 0 || (isDirectoryUser && !allowedModulesList.includes('cfs')) || (!allowedModulesList.includes('cfs'))}
            >
              Ratios
            </button>
          {
            (data?.basic_filings?.length !== 0 || !isDirectoryUser && !allowedModulesList.includes('cfs')) ? 
            <button
              className={
                `whitespace-nowrap py-3 text-[12pt] leading-[17pt] lg:text-[14px]   block lg:leading-[15px] ${ isDirectoryUser && !allowedModulesList.includes('cfs') || data?.basic_filings?.length == 0  || showFilings ? 'cursor-not-allowed':'cursor-pointer'}` +
                (openTab === 5
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(5);
              }}
              disabled={data?.basic_filings?.length == 0 || (isDirectoryUser && !allowedModulesList.includes('cfs')) || (!allowedModulesList.includes('cfs'))  || showFilings}
              data-toggle="tab"
              role="tablist"
            >
              Filings
            </button>
            : ""
          }


            <button
              className={
                `whitespace-nowrap py-3 text-[12pt] leading-[17pt] lg:text-[14px]   block lg:leading-[15px] ${(isDirectoryUser && !allowedModulesList.includes('cfs')) || (!allowedModulesList.includes('cfs'))  ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 6
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(6);
              }}
              data-toggle="tab"
              href="#link6"
              role="tablist"
              disabled={(isDirectoryUser && !allowedModulesList.includes('cfs')) || (!allowedModulesList.includes('cfs')) }
            >
              {/* <Link to="/financial/mca-site"> */}
              From MCA Site
              {/* </Link> */}
            </button>


          </div>

        </div>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                   <PL data={data?.profit_loss} 
                        isDetails={isDetail}
                        setIsDetail={onDetailsClick}
                        openTab={openTab}
                        isExport={isExport}
                        companyLabel={profile?.is_xbrl}
                        cfsFinKeys={isDetail ? plStandardKeys : nonDetailedPLKeys}
                        companyName={companyName}
                        fCompanyName={data?.cfs_profile?.full_name || profile?.full_name}
                />
                </div>

                {/* Balance Sheet */}

                { <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <PL data={data?.balance_sheet} 
                    isDetails={isDetail}
                    setIsDetail={onDetailsClick}
                    openTab={openTab}
                    isExport={isExport}
                    companyLabel={profile?.is_xbrl}
                    cfsFinKeys={isDetail ? balanceSheetKeys : nonDetailedBSKeys}
                    companyName={companyName}
                    fCompanyName={data?.cfs_profile?.full_name || profile?.full_name}
                />
                </div>}

               {/* Cash Flow */}
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <PL data={data?.cash_flow} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} isExport={isExport} companyLabel={profile?.is_xbrl} 
                    cfsFinKeys={isDetail ? cashFlowKeys : nonDetailedCFKeys} companyName={companyName} fCompanyName={data?.cfs_profile?.full_name || profile?.full_name}/>
                </div>

                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <Ratio data={ratioData} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} isRatio={true} companyLabel={profile?.is_xbrl} cfsFinKeys={isDetail ? ratioKeys : nonDetailedRatioKeys}/>
                </div>
              </>
          }
          <div className={openTab === 5 ? "block" : "hidden"} id="link5">
            <FilingsFinancialTable filings={data?.basic_filings} />
          </div>
          <div className={openTab === 6 ? "block" : "hidden"} id="link6">

            <FormMCSite />
          </div>
        </div>

      </div>
      {/* {open && <ChatPopup page="pe" component="financial" data={false} /> } */}
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
  );

};


export default FinancialTabs;
