import React, { useContext } from "react";
import "./profile.css";
import OverflowToolTip from "../shared/OverflowToolTip";
import linkedin from "../../Utils/linkedin.png";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import Highlights from "./Highlights";
import { Tooltip } from "antd";

function ProfileHighlight({ profile, financials, menu }) {

  const { profilekeys } = useContext(TableHeaderContext);

  const renderBoardInfo = (boardInfo) => {
    if (!boardInfo || boardInfo.length === 0) return null;

    
    const concatenatedInfo = boardInfo
    .map(info => `${info.name} (${info.designation})`)
    .join(", ");

    if (concatenatedInfo?.length > 29) {
      return (
        <Tooltip
          color="white"
          title={
            <div style={{ color: 'black' }}>
              {boardInfo.map((info, index) => {
                const name = info.name;
                const additionalInfo = [info.designation, info.belongs_to_firm_name].filter(Boolean).join(', ');
                const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : `${name} site:linkedin.com`;
                return (
                  <p key={index} className="my-1">
                    <a
                      href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#a07924] hover:text-[#a07924]"
                    >
                      {name}
                    </a>
                    {additionalInfo && `, ${additionalInfo}.`}
                  </p>
                );
              })}
            </div>
          }
          placement="topLeft"
        >
          <a className="text-[#a07924]">
            {boardInfo[0].name},
          </a>
          <span> {boardInfo[0].designation}{concatenatedInfo.length > 23 && "..."}</span>
        </Tooltip>
      );
    } else {
      return boardInfo.map((info, index) => {
        const name = info.name;
        const additionalInfo = [info.designation, info.belongs_to_firm_name].filter(Boolean).join(', ');
        const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : `${name} site:linkedin.com`;
        return (
          <p key={index} className="my-1">
            <a
              href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#a07924]"
            >
              {name}
            </a>
            {additionalInfo ? ', ' + additionalInfo : ""}
          </p>
        );
      });
    }
  };

  return (
    <div className="lg:mt-4 px-4 w-[100%]">
      <div className="lg:bg-white rounded-[6px]" x-show="tab === 1">
        <div className="w-full lg:grid lg:grid-cols-5 rounded-[6px] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
    <div className="mt-3 lg:mt-0 rounded-lg lg:rounded col-span-3 lg:border-r bg-white ">
            <div className="mx-5 py-4 ">
              <p className="text-[14pt] flex leading-[19pt] lg:text-[17px] text-[#333333] font-semibold  lg:leading-[22px]">
                Profile
                {profile?.linkedin ? ( // Check if LinkedIn profile value exists
                  <a
                    href={`${profile?.linkedin}`}
                    target="_blank"
                    className="mt-[0.20rem] ml-2"
                  >
                    <img src={linkedin} width={16} alt="linkedin logo" />
                  </a>
                ) : (
                  ""
                )}
              </p>
              <div className="mt-2">
                {profile?.length === 0 ? (
                  <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                    <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                      <center>
                        <div role="status" className="bg-white  h-[16rem]">
                          <svg
                            className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      </center>
                    </table>
                  </div>
                ) : (
                  <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                    <div className="w-full bg-[#FFFFFF]">
                      <div className="grid md:grid-cols-3 grid-cols-2  break-words">
                        {profilekeys.map((keyObj, index) => {
                          const key = keyObj.accessor;
                          const label = keyObj.label;
                          let valueToRender = null;

                          if (key === "management_info") {
                            const managementInfo = profile[key];
                            if (managementInfo && managementInfo.length > 0) {
                              valueToRender = managementInfo
                                .map(
                                  (info) => `${info.name}, ${info.designation}`
                                )
                                .join("; ");
                            } else valueToRender = menu === "ma" ? null : "--";
                          } else if (key === "board_info") {
                            valueToRender = renderBoardInfo(profile[key]);
                          } else if (key === "city") {
                            const city = profile[key];

                            if (city && city.name || city?.city) {
                                // Initialize an array to hold the parts of the address
                                const parts = [];

                                // Check each property and add it to the parts array if it's not null or empty
                                if (city.name) parts.push(city.name);
                                if (city.state) parts.push(city.state);
                                if (city.region) parts.push(city.region);
                                if (city.country) parts.push(city.country);

                                // Join the parts with a comma and space, or use "--" if no valid parts exist
                                valueToRender = parts.length > 0 ? parts.join(', ') : '--';
                            } else {
                                valueToRender = '--';
                            }
                          } else if (key === "website"){
                            valueToRender = menu === "ma" && profile[key] == "" ? "--" : profile[key] === "" ? null : profile[key];
                          }else {
                            valueToRender =
                              profile[key] && profile[key] !== ""
                                ? profile[key]
                                : null;
                          }
                          if (valueToRender !== null) {
                            return (
                              <div key={index} className="pr-4">
                                <>
                                  {valueToRender.length > 30 ? (
                                    <div className="leading-[21px] font-sans_book_body mt-1">
                                      <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                        <span
                                          className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                          readOnly
                                        >
                                          {label}
                                        </span>
                                      </div>
                                      <Tooltip
                                        title={
                                          <>
                                            <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                              <span
                                                className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                                readOnly
                                              >
                                                {label}
                                              </span>
                                            </div>
                                            {label === "Website" ? (
                                              <a
                                                href={valueToRender}
                                                className="mt-1 mb-2 hover:text-[#bc8b20] text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                                target="_blank"
                                              >
                                                {valueToRender}
                                              </a>
                                            ) : (
                                              <p
                                                className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                                readOnly
                                              >
                                                {valueToRender}
                                              </p>
                                            )}
                                          </>
                                        }
                                        color="white"
                                        placement="topLeft"
                                      >
                                        {label === "Website" ? (
                                          <p
                                            className="w-[90%] overflow-ellipsis overflow-hidden whitespace-nowrap mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                            readOnly
                                          >
                                            <a
                                            href={valueToRender}
                                            target="_blank"
                                            >
                                              {valueToRender}
                                            </a>
                                          </p>
                                        ) : (
                                          <p
                                            className="w-[90%] overflow-ellipsis overflow-hidden whitespace-nowrap mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                            readOnly
                                          >
                                            {valueToRender}
                                          </p>
                                        )}
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <div className="leading-[21px] font-sans_book_body mt-1">
                                      <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                        <span
                                          className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                          readOnly
                                        >
                                          {label}
                                        </span>
                                      </div>
                                      {label === "Website" ? (
                                        <a
                                          href={valueToRender}
                                          className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                          target="_blank"
                                        >
                                          {valueToRender}
                                        </a>
                                      ) : (
                                        <p
                                          className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                          readOnly
                                        >
                                          {valueToRender}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <!--first half end--> */}
          <Highlights financials={financials} menu={menu} />

          {/* <!--end--> */}
        </div>
      </div>
    </div>
  );
}

export default ProfileHighlight;
