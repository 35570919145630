import React from 'react';
import '../SearchIconComponents/SearchIcon.css';

const FilterButton = () => {


    return (
        <div className="img mr-[3px]  ml-6 transition ease-in-out delay-150 button">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 26 13">
                <g fill="none" fillRule="evenodd" transform="translate(5 2)">
                    <rect width="9" height=".7" x="7" y="1" fill="#b5844c" opacity="1" rx=".5" />
                    <rect width="2" height=".7" y="1" fill="#b5844c" opacity="1" rx=".5" />
                    <rect width="8" height=".7" y="6" fill="#b5844c" opacity="1" rx=".5" />
                    <rect width="3" height=".7" x="13" y="6" fill="#b5844c" opacity="1" rx=".5" />
                    <rect width="7" height=".7" x="9" y="11" fill="#b5844c" opacity="1" rx=".5" />
                    <rect width="4" height=".7" y="11" fill="#b5844c" opacity="1" rx=".5" />
                    <circle cx="4.5" cy="1.5" r="1.5" fill="#b5844c" />
                    <circle cx="10.5" cy="6.5" fill="#b5844c" r="1.5" />
                    <circle cx="6.5" cy="11.5" r="1.5" fill="#b5844c" />
                </g>
            </svg>
        </div>
    );
};

export default FilterButton;
