import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { DealRangeData } from '../../../../Components/Reports/ReportsMain';
import NoDataPlaceholder from '../../../../Components/shared/NoDataPlaceholder';
 

/**
 * The PieChart function takes in data, colors, title, chartFor, and reportsFilter as parameters, and
 * returns a pie chart component with the provided data.
 * @returns The PieChart component is being returned.
 */
const PieChart = ({ dataColors, resultSet, title, chartFor, reportsFilter }) => {

    console.log('from bar chart resultSet', resultSet)

    const product = reportsFilter.product;
    console.log('product', product);

    var chartPieBasicColors = getChartColorsArray(dataColors);
 
    /**
     * The function "numberWithCommas" takes a number as input and returns a string representation of
     * that number with commas separating every three digits.
     * @param x - The parameter "x" is a number that you want to format with commas.
     * @returns the input number with commas added as thousands separators.
     */
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const dataSet = resultSet?.loadResponse?.results[0]?.data;


    let dataSetSource = '';
    let dataSetSourcePeriod = '';

    if (resultSet?.loadResponse?.results[0].query.dimensions?.length >= 3) {
        console.log('in - if');
        dataSetSource = resultSet?.loadResponse?.pivotQuery?.dimensions[resultSet?.loadResponse?.pivotQuery?.dimensions.length - 1];
        dataSetSourcePeriod = resultSet?.loadResponse?.pivotQuery?.dimensions[resultSet?.loadResponse?.pivotQuery?.dimensions.length - 2];
    } else {
        console.log('in - else');
        dataSetSource = resultSet?.loadResponse?.results[0].query ? resultSet?.loadResponse?.results[0].query.dimensions[1] : resultSet?.loadResponse?.results[0].pivotQuery.dimensions[1];
        dataSetSourcePeriod = resultSet?.loadResponse?.results[0].query ? resultSet?.loadResponse?.results[0].query.dimensions[0] : resultSet?.loadResponse?.results[0].pivotQuery.dimensions[0];
    }
 
    
    /* Extracting unique industries from the resultSet */
    const dataSetSources = [...new Set(dataSet.map((data) => data[dataSetSource]))];

    // console.log('industries', industries);

    // Creating series data

    let filterMeasure = '';

    console.log('chartFor', chartFor); 
    if (product == 'PE' || product == 'VC') {
        if (chartFor == 'amount') {
            filterMeasure = 'DealFlat.AmountM';
        } else { 
            filterMeasure = 'DealFlat.deal_id';
        }

    } else if (product == 'MA') {
        if (chartFor == 'amount') {
            filterMeasure = 'MaInvestmentsFlat.ma_dealAmount';
        } else {
            filterMeasure = 'MaInvestmentsFlat.ma_dealCount';
        }
    } else {
        if (chartFor == 'amount') {
            filterMeasure = 'REinvestments.amount';
        } else {
            filterMeasure = 'REinvestments.count';
        }
    }
  
    // if (chartFor == 'amount') {
    //     filterMeasure = 'DealFlat.AmountM';
    // } else { 
    //     filterMeasure = 'DealFlat.deal_id';
    // }

    const labels = Array.from(new Set(dataSet.map(item => item[dataSetSource])));
    const series = labels.map(label => dataSet.filter(item => item[dataSetSource] === label)
                                .reduce((sum, item) => sum + item[filterMeasure], 0));

    const seriesWithRound =[];
     series.map(value=>{
        seriesWithRound.push(Math.round((value + Number.EPSILON) * 100) / 100)   
    })


    var options = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: reportsFilter?.filterViewOption === "dealRange" ? labels.map(id => DealRangeData.find(data => data.id === id)?.name ?? id) : labels, //['wee'],
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return numberWithCommas(val);
                }
            }
        },
        colors: chartPieBasicColors
    };

    return (
        <div className='bg-white border shadow rounded block w-full border-gray-100 md:ml-3 md:mr-3'>
        <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> {title}</h3>
        <div className='p-1'>
            {dataSet.length >0 ?
        <ReactApexChart
            className="apex-charts"
            series={seriesWithRound}
            options={options}
            type="pie"
            height={450}
        />
        : 
        <div className='my-3'><NoDataPlaceholder showButton={false} /></div>
        // <p className='py-10 text-center'>No results found</p>

         }
        </div>
        </div>
    );
};


 
 

export { PieChart }