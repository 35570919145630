import React, { useState, useMemo, useContext } from "react";
import { useParams, Link, useLocation, matchPath } from "react-router-dom";
import moment from "moment";
import { routeNames } from "../../../routeSegments";
import DataTable from "../../DataTables/DataTable";
import routePathSegments from "../../../routeSegments";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import OverflowToolTip from "../../shared/OverflowToolTip";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

const AdvisorTo_Investors = (props) => {
  // const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  // const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  let advisorType = "L";
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId);
  const location = useLocation();
  const { primaryMasterId } = useModule();
  const { isStudent, isDirectoryUser, isTrialExport } = useContext(UserContext);
  const { toInvestorsDataFound } = useContext(DataFoundContext);

  const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(portfolio?.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - pageNum is the page number that we want to set as the current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  /* The above code is defining a custom sorting function called `customStringSortMemo` using the
 `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
 boolean value `desc` indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const row1 = rowA?.values[columnId].map((inv) => inv.Investor).join(" ");
      const row2 = rowA?.values[columnId].map((inv) => inv.Investor).join(" ");
      const defaultVal = desc ? "--" : "A";
      return (row1 ?? defaultVal).localeCompare(row2 ?? defaultVal);
    };
  }, []);
  /* The `customNameSortMemo` constant is a memoized function that is used as a sorting function for a
specific column in a data table. It compares the `name` values of two rows (`rowA` and `rowB`) in
the specified column (`columnId`) and returns the result of the `localeCompare` function, which
compares the strings in a locale-sensitive manner. */
  const customNameSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.name ?? defaultVal
      );
    };
  }, []);

  /* The `dealDateSort` constant is a memoized function that is used as a sorting function for a specific
column in a data table. It compares the `date` values of two rows (`rowA` and `rowB`) in the
specified column (`columnId`) and returns the difference between the two dates. This function is
used to sort the data in ascending or descending order based on the `date` values. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment
        .utc(rowA?.values[columnId].date)
        .diff(moment.utc(rowB?.values[columnId].date));
    };
  }, []);
  /* The `decimalNumberSort` constant is a memoized function that is used as a sorting function for a
specific column in a data table. */
  const decimalNumberSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const rowAValue = Number(rowA.values[columnId]);
      const rowBValue = Number(rowB.values[columnId]);
      if (rowAValue > rowBValue) return 1;
      if (rowBValue > rowAValue) return -1;
      return 0;
    };
  }, []);
  /* The `investorSort` constant is a memoized function that is used as a sorting function for a specific
column in a data table. */
  const investorSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";

      const valueA = rowA?.values[columnId]
        .map((data) => data.Investor_name)
        .join("");
      const valueB = rowB?.values[columnId]
        .map((data) => data.Investor_name)
        .join("");

      return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    };
  }, []);

  /**
   * The addCommasToNumber function takes a number as input and returns a string representation of the
   * number with commas added for thousands separators.
   * @param number - The `number` parameter is the number that you want to add commas to.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added to separate thousands.
   */
  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /* The above code is defining an array of columns for a table. Each column has properties such as id,
 Header (column header text), accessor (data accessor), className (CSS class for the column),
 headerClassName (CSS class for the column header), Cell (rendering component for the cell), and
 sortType (sorting type for the column). The columns array is created using the useMemo hook, which
 memoizes the array so that it is only recomputed when its dependencies change. */
  const columns = useMemo(
    () => [
      {
        id: "investors",
        Header: "Investors",
        accessor: "investors",
        className: "clickable  lg:relative text-left sticky left-0  bg-white",
        headerClassName:
          "w-[14%] lg:relative text-left sticky left-0   w-[3rem] bg-[#EDEDED] ",
        Cell: ({ value }) => {
          return (
            <OverflowToolTip
              className="w-[12rem] lg:w-auto max-w-[15rem]"
              component={value.map((inv, index) => {
                if (inv.id === 9) {
                  return (
                    <p className=" text-start flex lg:inline">{inv.name}</p>
                  );
                }
                return (
                  <Link
                    className="hover:text-[#BC8B20]"
                    state={{ cameFrom: location.state?.prevPath }}
                    to={
                      peMatch != null
                        ? routeNames.peInvestorById(inv.id)
                        : routeNames.vcInvestorById(inv.id)
                    }
                  >
                    {`${inv?.name}${index == value.length - 1 ? "" : ", "}`}
                  </Link>
                );
              })}
            />
          );
        },
        sortType: investorSort,
      },
      {
        id: "dealDate",
        Header: "Deal Date",
        accessor: "dealDate",
        Cell: (cell) => {
          return (
            <Link
              target="_blank"
              state={{ cameFrom: location.state?.prevPath ?? "" }}
              onClick={(e) => {
                if (isDirectoryUser) {
                  e.preventDefault();
                  e.stopPropagation();
                  onBlurColumnClick();
                }
              }}
              to={
                peMatch != null
                  ? routeNames.peCompanyInvestmentDealById(
                      cell.value.id,
                      cell?.value?.dealId
                    )
                  : routeNames.vcCompanyInvestmentDealById(
                      cell.value.id,
                      cell?.value?.dealId
                    )
              }
            >
              {cell?.value?.date != ""
                ? moment(cell?.value?.date).format("MMM YYYY")
                : "--"}
            </Link>
          );
        },
        sortType: dealDateSort,
      },
      {
        Header: "Deal Amount (US$ M)",
        accessor: "dealAmountInUS",
        className: "px-0 text-right pr-[2.75rem] w-[10%]",
        headerspanClassName: "ml-auto",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
      },
      {
        Header: "Company Name",
        accessor: "companyName",
        Cell: ({ value }) => {
          return (
            <OverflowToolTip
              className="w-[10rem] lg:w-auto"
              component={
                <Link
                  className="hover:text-[#BC8B20]"
                  state={{ cameFrom: location.state?.prevPath }}
                  to={
                    peMatch != null
                      ? routeNames.peCompanyFundById(value?.id)
                      : routeNames.vcCompanyFundById(value?.id)
                  }
                >
                  {value.name ?? "--"}
                </Link>
              }
            />
          );
        },
        sortType: customNameSortMemo,
      },
      {
        Header: "Post Money Valuation (INR Cr)",
        accessor: "postMoney",
        className: "px-0 text-right pr-[2.75rem] w-[15%]",
        headerspanClassName: "ml-auto",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
        // sortType:decimalNumberSort
      },
      {
        Header: "Revenue Multiple",
        accessor: "revenueMultiple",
        className: "px-0 text-right pr-[2.5rem] w-[12%]",
        headerspanClassName: "ml-auto",
        headerClassName: "w-[10%]",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
        // sortType:decimalNumberSort
      },
    ],
    []
  );

  /* The `rowData` variable is a memoized function that transforms the `props.toInvestor` data into a
 format that can be used by the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toInvestor?.forEach((company) => {
      const { special, others } = company?.investors?.reduce(
        (acc, inv) => {
          if (inv.id === 9) {
            acc.special.push(inv);
          } else {
            acc.others.push(inv);
          }
          return acc;
        },
        { special: [], others: [] }
      );

      const sortedInvestors = [...others, ...special];
      rows.push({
        investors: sortedInvestors,
        dealDate: {
          date: company?.deal_date,
          dealId: company?.id,
          id: company?.company?.id,
        },
        dealAmountInUS: company?.amount,
        companyName: { name: company?.company?.name, id: company?.company?.id },
        postMoney:
          company?.company_valuation_post_money > 0
            ? company?.company_valuation_post_money
            : "",
        revenueMultiple:
          company?.revenue_multiple_post_money > 0
            ? company?.revenue_multiple_post_money
            : "",
      });
    });
    setshowXlDownload(rows.length != 0);

    return rows;
  }, [props.toInvestor]);

  const { state } = useLocation();

  const isVcCompanyMatch = matchPath(
    { path: `${routePathSegments.vc}/*` },
    state?.prevPath ?? ""
  );
  /* The `initialSort` variable is used to specify the initial sorting configuration for the data table.
 It is an array that contains an object with two properties: `id` and `desc`. */
  const initialSort = useMemo(
    () => [
      {
        id: "dealDate",
        desc: true,
      },
    ],
    []
  );

  const [alert, setalert] = useState({ message: "", show: false });

  const onBlurColumnClick = () => {
    setalert({
      show: true,
      message: Constants.peSubscriptionMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

  const onConfirmation = () => {
    setalert({ show: false, message: "" });
  };

  return (
    <>
      {toInvestorsDataFound ? (
        <div className=" px-4 bg-[#EDEDED] w-[100%]">
          <div className="bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
              <div className="flex justify-between mb-2">
                <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold mb-2">
                  To Investors
                </h3>
                {!isDirectoryUser
                  ? isStudent == "false" &&
                    isTrialExport != "false" &&
                    showXlDownload && (
                      <ExcelDownloader
                        url={exportExcel}
                        fileName={Constants.exportNames.to_investors(
                          props.advisorName
                        )}
                        data={{
                          type: primaryMasterId,
                          export_from: "detail",
                          module_type: "advisors",
                          advisor_type: "L",
                          section: "to_investors",
                          advisor_id: [advisorProfileId],
                        }}
                      />
                    )
                  : ""}
              </div>
            </div>
            <div className="space-y-6">
              <div className="bg-white pb-2 rounded-[6px]">
                <DataTable
                  columns={columns}
                  data={rowData}
                  sortBy={initialSort}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </>
  );
};

export default AdvisorTo_Investors;
