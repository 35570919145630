import React, { useState , useEffect } from 'react'
// import Container from '../../components/layout/Container';
import Layout from '../../components/layout';
import UnicornTable from './unicornTable';

const UnicornTracker = () => {
    const [loading , setLoading ] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
    
                const response = await fetch('https://api-hub.ventureintelligence.com/public/unicorn-tracker/', {
                    method: 'POST',
                    // Optionally, include headers or a request body if needed
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ key: 'value' }), // Include a request body if needed
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
    
                const result = await response.json();
    
                // Process the data as needed
                // console.log(data)
                const data = result.data;
                setTableData(data);
    
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const sortByCompany = () => {
        // Implement sorting logic for the 'Company' column
    
        // Assuming your data structure looks like this:
        // { company: 'Company A', ...other fields }
        
        // Use Array.sort() to sort the tableData array by the 'company' field
        const sortedData = [...tableData].sort((a, b) => {
            const companyA = a.company.toLowerCase();
            const companyB = b.company.toLowerCase();
    
            // Compare the company names
            if (companyA < companyB) {
                return -1;
            } else if (companyA > companyB) {
                return 1;
            } else {
                return 0;
            }
        });
    
        // Set the sorted data to the state
        setTableData(sortedData);
    };

    const sortBySector = ()=>{
        const sortedData = [...tableData].sort((a,b) => {
            const sectorA = a.sector.toLowerCase();
            const sectorB = b.sector.toLowerCase();

            if(sectorA < sectorB){
                return -1;
            }else if( sectorA > sectorB ){
                return 1;
            }else{
                return 0;
            }
        });
        setTableData(sortedData);
    }

    const sortByEntryValuation = () => {
        // Implement sorting logic for the 'Entry Valuation' column
    
        // Assuming your data structure looks like this:
        // { entry_valuation: 10, ...other fields }
    
        // Use Array.sort() to sort the tableData array by the 'entry_valuation' field
        const sortedData = [...tableData].sort((a, b) => {
            const entryValuationA = parseFloat(a.entry_valuation);
            const entryValuationB = parseFloat(b.entry_valuation);
    
            // Compare the entry valuations
            return entryValuationA - entryValuationB;
        });
    
        // Set the sorted data to the state
        setTableData(sortedData);
    };
    
    const sortDataByValuation = () => {
        // Assuming your data structure looks like this:
        // { valuation: 20, ...other fields }
    
        // Use Array.sort() to sort the tableData array by the 'valuation' field
        const sortedData = [...tableData].sort((a, b) => {
            const valuationA = parseFloat(a.valuation);
            const valuationB = parseFloat(b.valuation);
    
            // Compare the valuations
            return valuationA - valuationB;
        });
    
        // Set the sorted data to the state
        setTableData(sortedData);
    };
    
    const sortDataByEntry = () => {
        // Assuming your data structure looks like this:
        // { entry_date: '2023-01-01', ...other fields }
    
        // Use Array.sort() to sort the tableData array by the 'entry_date' field
        const sortedData = [...tableData].sort((a, b) => {
            const entryDateA = new Date(a.entry_date);
            const entryDateB = new Date(b.entry_date);
    
            // Compare the entry dates
            return entryDateA - entryDateB;
        });
    
        // Set the sorted data to the state
        setTableData(sortedData);
    };

    const sortDataByLocation = () => {
        // Assuming your data structure looks like this:
        // { location: 'City A', ...other fields }
    
        // Use Array.sort() to sort the tableData array by the 'location' field
        const sortedData = [...tableData].sort((a, b) => {
            const locationA = a.location.toLowerCase();
            const locationB = b.location.toLowerCase();
    
            // Compare the locations
            if (locationA < locationB) {
                return -1;
            } else if (locationA > locationB) {
                return 1;
            } else {
                return 0;
            }
        });
    
        // Set the sorted data to the state
        setTableData(sortedData);
    };
    

    return (
        <Layout>
                <div className="flex-col">
                    <div className='my-[22px] text-center'>
                        <h1 className='text-5xl font-medium text-[#a07924] mb-16'>Unicorn Tracker</h1>
                        <h3 className='text-2xl font-medium text-[#475569]'>List of Indian Startups valued at $1 Billion or more.</h3>
                    </div>
                    <div className="table w-[100%]">
                        <UnicornTable loading={loading} tableData={tableData} sortByCompany={sortByCompany} sortBySector={sortBySector} sortByEntryValuation={sortByEntryValuation} sortByValuation={sortDataByValuation} sortByEntry={sortDataByEntry} sortByLocation={sortDataByLocation} />
                    </div>
                    <div className="text-content ml-[5%] mt-5   ">
                        <div className='my-4'>
                            <ul>
                                <li><b>Unicorn:</b> A unicorn company is any private company that is valued at $1-Billion or more and is Venture Capital funded.</li>
                                <li><b>^ Graduated Unicorn:</b>A startup that has now become Publicly Listed (or) has been acquired by a Publicly Listed company.</li>
                                <li><b>* Former Unicorn:</b>A startup that lost its Unicorn status due to a valuation degrading event.</li>
                                <li>^^ The company valuation at the time it first became an Unicorn.</li>
                                <li># The company valuation at the time of the latest round of funding.</li>
                            </ul>
                        </div>
                        <div className='my-4'>
                            <p><b><a href="https://www.ventureintelligence.com/india_unicorn_report_form.php">Click Here</a>to download the latest edition of the Indian Unicorns report.</b></p>
                        </div>
                        <div className='my-4'>
                            <p>Interested in more information of Unicorns - including investment and valuation details, financial's, return multiples, etc?</p>
                            <p><a href="https://www.ventureintelligence.com/trial.htm">Click Here</a>to request a trial to our Databases.</p>
                        </div>
                    </div>
                </div>
        </Layout>
    )
}

export default UnicornTracker;
