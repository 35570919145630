import { React, useState, useMemo, useContext } from "react";
import { useParams, Link, useLocation, matchPath } from "react-router-dom";
import moment from "moment";
import NewLogo from "../../../Components/NewLogo";
import { routeNames } from "../../../routeSegments";
import DataTable from "../../../Components/DataTables/DataTable";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from "../../shared/OverflowToolTip";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

const AdvisorCompaniesInvestments = (props) => {
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  let advisorType = "L";
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId);
  const location = useLocation();
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { primaryMasterId } = useModule();
  const { isStudent, isDirectoryUser, isTrialExport } = useContext(UserContext);
  const { toCompaniesInvesmentsDataFound } = useContext(DataFoundContext);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(portfolio?.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log(pageNumbers, "pageNumbers");
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - pageNum is a parameter that represents the page number that you want to set as
   * the current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  /* The above code is defining a custom sorting function called `customStringSortMemo` using the
   `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
   boolean value `desc` indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.name ?? defaultVal
      );
    };
  }, []);

  /* The above code is defining a custom sorting function called `dealDateSort`. This function is
    used to compare two rows (`rowA` and `rowB`) based on a specific column (`columnId`) that
    contains date values. The function uses the `moment.utc` function to convert the date values to
    UTC format and then compares them using the `diff` method. The result of the comparison is
    returned, which will be a negative number if `rowA` is earlier than `rowB`, a positive number if
    `rowA` is later than `rowB`, or zero if they are */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment
        .utc(rowA?.values[columnId].date)
        .diff(moment.utc(rowB?.values[columnId].date));
    };
  }, []);

  /* The above code is defining a function called `investorSort` using the `useMemo` hook in
    JavaScript. This function is used for sorting rows in a table based on the values in a specific
    column. */
  const investorSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";

      const valueA = rowA?.values[columnId]
        .map((data) => data.investor_name)
        .join("");
      const valueB = rowB?.values[columnId]
        .map((data) => data.investor_name)
        .join("");

      return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    };
  }, []);

  const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

  /**
   * The function "addCommasToNumber" takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number that you want to add commas to.
   * @returns The function `addCommasToNumber` returns a string with commas added to the number.
   */
  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /* The above code is defining an array of columns for a table. Each column has a Header (column
   name), accessor (data key), and a Cell component that determines how the data is rendered in the
   table. The code also includes sorting functions for some of the columns. */
  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName",
        className: "clickable  text-left sticky left-0 lg:relative bg-white",
        headerClassName:
          "text-left sticky left-0 lg:relative  w-[3rem] bg-[#EDEDED] ",
        Cell: ({ value }) => {
          return (
            <div className=" leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
              <NewLogo id={value?.id} name={value?.name} page="company page" />

              <OverflowToolTip
                className="w-[11rem] lg:w-auto"
                component={
                  <Link
                    className="text-start lg:inline flex hover:text-[#BC8B20]"
                    state={{ cameFrom: location.state?.prevPath }}
                    to={
                      peMatch != null
                        ? routeNames.peCompanyFundById(value?.id)
                        : routeNames.vcCompanyFundById(value?.id)
                    }
                  >
                    {value?.name ?? "--"}
                  </Link>
                }
              />
            </div>
          );
        },
        sortType: customStringSortMemo,
      },
      {
        id: "dealDate",
        Header: "Deal Date",
        accessor: "dealDate",

        Cell: (cell) => {
          return (
            <Link
              state={{ cameFrom: location.state?.prevPath ?? "" }}
              target="_blank"
              onClick={(e) => {
                if (isDirectoryUser) {
                  e.preventDefault();
                  e.stopPropagation();
                  onBlurColumnClick();
                }
              }}
              to={
                peMatch != null
                  ? routeNames.peCompanyInvestmentDealById(
                      cell.value.id,
                      cell?.value?.dealId
                    )
                  : routeNames.vcCompanyInvestmentDealById(
                      cell.value.id,
                      cell?.value?.dealId
                    )
              }
            >
              {cell?.value?.date != ""
                ? moment(cell?.value?.date).format("MMM YYYY")
                : "--"}
            </Link>
          );
        },
        sortType: dealDateSort,
      },
      {
        Header: "Deal Amount (US$ M)",
        accessor: "dealAmountInUS",
        className: "px-0 text-right pr-[2.5rem] w-[12%]",
        headerspanClassName: "ml-auto",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
      },
      {
        Header: "Investors",
        accessor: "investors",
        Cell: (cell) => {
          return (
            <OverflowToolTip
              className="w-[14rem] lg:w-auto"
              component={cell.value.map((inv, index) => {
                if (inv.id === 9) {
                  return (
                    <p className=" text-start flex lg:inline">{inv.name}</p>
                  );
                }
                return (
                  <Link
                    className=" text-start flex lg:inline hover:text-[#BC8B20]"
                    state={{ cameFrom: location.pathname }}
                    to={
                      peMatch != null
                        ? routeNames.peInvestorById(inv?.id)
                        : routeNames.vcInvestorById(inv?.id)
                    }
                  >
                    {`${inv?.name}${
                      index == cell.value.length - 1 ? "" : ", "
                    }`}
                  </Link>
                );
              })}
            />
          );
        },
        sortType: investorSort,
      },
      {
        Header: "Post Money Valuation (INR Cr)",
        accessor: "enterpriseValue",
        className: "px-0 text-right pr-[2.7rem] w-[10%]",
        headerspanClassName: "ml-auto",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
      },
      {
        Header: "Revenue Multiple",
        accessor: "revenueMultiple",
        className: "px-0 text-right pr-[2.7rem] w-[12%]",
        headerspanClassName: "ml-auto",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value);
        },
      },
    ],
    []
  );
  console.log(props.menu, "is_vc")

  /* The above code is a JavaScript function that is using the `useMemo` hook to create a memoized
    version of the `rowData` variable. */
  const rowData = useMemo(() => {
    const rows = [];
    {
      props.companyInvestment?.length > 0 &&
        props.companyInvestment?.forEach((company, i) => {
          if (props.menu === "vc" && !company.is_vc) {
            return;
          }

          const { special, others } = company?.investors?.reduce(
            (acc, inv) => {
              if (inv.id === 9) {
                acc.special.push(inv);
              } else {
                acc.others.push(inv);
              }
              return acc;
            },
            { special: [], others: [] }
          );

          const sortedInvestors = [...others, ...special];

          rows.push({
            companyName: {
              name: company?.company?.name,
              id: company?.company?.id,
              logo_path: company?.logo_path,
            },
            dealDate: {
              date: company?.deal_date,
              dealId: company?.id,
              id: company?.company?.id,
            },
            dealAmountInUS: !company.is_amount_hide ? company?.amount : "",
            investors: sortedInvestors,
            enterpriseValue:
              company?.company_valuation_post_money > 0
                ? company?.company_valuation_post_money
                : "",
            revenueMultiple:
              company?.revenue_multiple_post_money > 0
                ? company?.revenue_multiple_post_money
                : "",
          });
        });
    }
    setshowXlDownload(rows.length != 0);
    return rows;
  }, [props.companyInvestment, props.menu]);
  /* The `initialSort` constant is used to specify the initial sorting configuration for the data
    table. It is an array that contains an object with two properties: `id` and `desc`. */
  const initialSort = useMemo(
    () => [
      {
        id: "dealDate",
        desc: true,
      },
    ],
    []
  );
  const { pathname, state } = useLocation();

  const isVcCompanyMatch = matchPath(
    { path: `${routePathSegments.vc}/*` },
    state?.prevPath ?? ""
  );

  const [alert, setalert] = useState({ message: "", show: false });

  const onBlurColumnClick = () => {
    setalert({
      show: true,
      message: Constants.peSubscriptionMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

  const onConfirmation = () => {
    setalert({ show: false, message: "" });
  };

  return (
    <>
      {toCompaniesInvesmentsDataFound ? (
        <div className="px-4 bg-[#EDEDED]">
          <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
              <div className="flex justify-between mb-2">
                <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">
                  To Companies - Investments
                </p>
                {!isDirectoryUser
                  ? isStudent == "false" &&
                    isTrialExport != "false" &&
                    showXlDownload && (
                      <ExcelDownloader
                        url={exportExcel}
                        fileName={Constants.exportNames.to_companies_investments(
                          props.advisorName
                        )}
                        data={{
                          type: primaryMasterId,
                          export_from: "detail",
                          module_type: "advisors",
                          advisor_type: "L",
                          section: "company_investments",
                          advisor_id: [advisorProfileId],
                        }}
                      />
                    )
                  : ""}
              </div>
            </div>
            <div className="space-y-6">
              <div className="bg-white pb-2 rounded-[6px]">
                <DataTable
                  columns={columns}
                  data={rowData}
                  sortBy={initialSort}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </>
  );
};

export default AdvisorCompaniesInvestments;
