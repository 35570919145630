import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

const DatabaseDocs = () => {
  const [openSections, setOpenSections] = useState({
    pevc: false,
    definitions: false,
    angel: false,
    exits: false,
    publicMarket: false,
    ipos: false,
    vc: false,
    ma: false,
    pere: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const Section = ({ id, title, children }) => (
    <div className="">
      <button
        onClick={() => toggleSection(id)}
        className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
      >
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        {openSections[id] ? (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronRight className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {openSections[id] && (
        <div className="px-6 pb-6 text-gray-700">
          {children}
        </div>
      )}
    </div>
  );

  return (
    <div className="mt-12 min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-xl overflow-hidden shadow-[0_4px_24px_rgba(0,0,0,0.1)] ring-1 ring-gray-200">
        <div className="bg-gradient-to-r from-[#BA8A23] to-[#c59c40] p-4">
          <h1 className="text-2xl font-bold text-white text-center">Definitions</h1>
        </div>

        <div className="divide-y divide-gray-200 p-6 space-y-5">
          <Section id="pevc" title="PE-VC Database">
            <div className="space-y-6">
              <div>
                {/* <p className="font-semibold mb-4">For the purpose of this database:</p> */}
                <div className="space-y-2">
                  <p>In general, only investments by firms structured as PE/VC funds are INCLUDED</p>
                  <p>Real Estate deals - both entity level and project-level are EXCLUDED</p>
                  <p>In general, hedge funds / sovereign wealth funds investments into listed cos. are EXCLUDED</p>
                  <p>Pre-IPO deals (6-months within IPO-filing date) by hedge funds / sovereign wealth funds / family offices / individuals / public market investors are EXCLUDED</p>
                  <p>Strategic Investments and Barter Deals are EXCLUDED</p>
                  <p>Follow-on investments by the same investor(s) into a company are included as separate transactions as long as the investments fall in different months. Newer investments in the same month (for example, multiple investments via the public markets) are updated as part of the same transaction.</p>
                </div>
              </div>

              <div>
                <h2 className="font-semibold mb-2">Definitions of Stages-of-company-development used (Private Equity):</h2>
                
                <div className="space-y-6">
                  <div className="space-y-2">
                    <h3 className="font-semibold mb-2">Venture Capital:</h3>
                    <div className="pl-4">
                      <p>First to Fourth Round of institutional investments into companies that are:</p>
                      <p>{`Less than < 10 years old, AND`}</p>
                      <p>Investment amount is less than $20 M</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold mb-2">Growth-PE:</h3>
                    <div className="pl-4">
                      <p>{`First-to-Fourth Round Investments >$20 M into companies < 10 years old, OR`}</p>
                      <p>{`Fifth / Sixth rounds of institutional investments into companies < 10 years old`}</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold mb-2">Late Stage:</h3>
                    <div className="pl-4">
                      <p>Investment into companies that are over 10 years old, OR</p>
                      <p>Seventh or later rounds of institutional investments</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold mb-2">PIPEs:</h3>
                    <div className="pl-4">
                      <p>PE investments in publicly-listed companies via preferential allotments / private placements, OR</p>
                      <p>Acquisition of shares by PE firms via the secondary market</p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold mb-2">Buyout:</h3>
                    <div className="pl-4">
                      <p>Acquisition of controlling stake via purchase of stakes of existing shareholders</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h2 className="mt-6 text-xl font-semibold mb-4 text-center">Angel Database</h2>
                <p>By default, this database is restricted to investments by organized Angel Networks and Resident Individuals who have made active investments since 2004.</p>
              </div>

              <div>
                <h2 className="mt-6 text-xl font-semibold mb-4 text-center">Exits via M&A</h2>
                <div className="space-y-2">
                  <p>Exit deals involving investors in a company that has already been featured under PE/VC-backed IPOs (for that particular investor) are EXCLUDED</p>
                  <p>Real Estate deals are EXCLUDED</p>
                  <p>Exits via M&A include the following types of deals:</p>
                  <p><span className="font-medium">Buyback:</span> Purchase of the PE/VC investors' equity stakes by either the investee company or its founders/promoters.</p>
                  <p><span className="font-medium">Strategic Sale:</span> Sale of the PE/VC investors' equity stakes (or the entire investee company itself) to a third party company (which is typically a larger company in the same sector).</p>
                  <p><span className="font-medium">Secondary Sale:</span> Purchase of the PE/VC investors' equity stakes by another PE/VC investors.</p>
                </div>
              </div>

              <div>
                <h2 className="mt-6 text-xl font-semibold mb-4 text-center">Exits via Public Market Sale</h2>
                <div className="space-y-2">
                  <p><span className="font-medium">Public Market Sale:</span> Sale of the PE/VC investors' equity stakes in listed companies through the public stock market.</p>
                  <p>Note: To avoid double counting the exits, Public Market Sales by investors who had invested more than 6 months before the company's IPO are captured as part of PE-backed IPO database.</p>
                  <p className="font-medium">For the purpose of this database:</p>
                  <p>Exit deals involving investors in a company that has already been featured under PE/VC-backed IPOs (for that particular investor) are EXCLUDED</p>
                  <p>Real Estate deals are EXCLUDED</p>
                </div>
              </div>

              <div>
                <h2 className="mt-6 text-xl font-semibold mb-4 text-center">PE-VC Backed IPOs</h2>
                <div className="space-y-2">
                  <p>Only IPOs by companies in which PE/VC funds had invested more 6 months before the IPO filing are INCLUDED</p>
                  <p>Post IPO sales by investors in these companies are captured as part of the More Info column of the PE-backed IPO deal.</p>
                  <p>Real Estate IPOs are EXCLUDED</p>
                </div>
              </div>
            </div>
          </Section>

          <Section id="vc" title="Venture Capital Database">
            <div className="space-y-6">
              <div>
                <p>Venture Capital is a sub-set of Private Equity.</p>
                <h2 className="font-semibold my-2">Definitions of Stages-of-company-development used (Venture Capital):</h2>
                <p>Venture Capital investments are classified into the following categories:</p>
              </div>
              
              <div className="space-y-2">
                <h3 className="font-semibold mb-2">Early Stage:</h3>
                <div className="pl-4">
                  <p>First or Second Round of institutional investments into companies that are:</p>
                  <p className="ml-4">Less than five years old, AND</p>
                  <p className="ml-4">Not part of a larger business group, AND</p>
                  <p className="ml-4">Investment amount is less than $20 M</p>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold mb-2">Growth Stage:</h3>
                <div className="pl-4">
                  <p className="ml-4">Third / Fourth Round funding of institutional investments OR</p>
                  <p className="ml-4">{`First/Second Round of institutional investments for companies that are >5 years old and < 10 years old OR spin-outs from larger businesses, AND`}</p>
                  <p className="ml-4">Investment amount is less than $20 M</p>
                </div>
              </div>

              <div>
                <p className="mt-4 font-medium">For the purpose of this database:</p>
                <div className="space-y-2">
                  <p>Investments by India-dedicated Funds into Cross-Border Cos. (i.e., firms with headquarters outside India but substantial operations in India) are INCLUDED.</p>
                  <p>Follow-on investments by the same investor(s) into a company are included as separate transactions.</p>
                </div>
              </div>

              <div>
                <h2 className="mt-6 text-xl font-semibold mb-4 text-center">Angel Database</h2>
                <p>By default, this database is restricted to investments by organized Angel Networks and Resident Individuals who have made active investments since 2004.</p>
              </div>
            </div>
          </Section>

          <Section id="ma" title="M&A Database">
            <div className="space-y-6">
              <div className="space-y-2">
                <p>Only transactions involving acquisition of 50% or higher stake are INCLUDED</p>
                <p>M&A that happen in India as a result of a foreign/global transaction (i.e. the merger of the Indian subsidiaries of foreign cos. which have been involved in a transaction overseas) are EXCLUDED</p>
                <p>Joint Venture (Greenfield Projects) deals are EXCLUDED</p>
                <p>Follow-up transactions to the same M&A deal (i.e., Open Offers, additional share purchases) are EXCLUDED unless separated by a 12 month period.</p>
              </div>
            </div>
          </Section>

          <Section id="pere" title="PE-RE Database">
            <div className="space-y-6">
              <div className="space-y-2">
                <p>Real Estate Investments include "cash for equity" investments made by financial investors into Real Estate companies or projects.</p>
                <div className='ml-4'>
                    <p>Investments by firms structured as PE/VC funds (as well as investors who compete with such investors like hedge funds / sovereign wealth funds) are INCLUDED</p>
                    <p>In general, hedge funds / sovereign wealth funds investments into listed RE cos. (at the entity level) are EXCLUDED</p>
                    <p>Pre-IPO deals (6-months within IPO-filing date) by hedge funds / sovereign wealth funds / family offices / individuals / public market investors are EXCLUDED</p>
                    <p>Strategic Investments and Barter Deals (like trading ad space for equity) are EXCLUDED</p>
                    <p>Follow-on investments by the same investor(s) into a company are included as separate transactions as long as the investments fall in different months. Newer investments in the same month (for example, multiple investments via the public markets) are updated as part of the same transaction.</p>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default DatabaseDocs;