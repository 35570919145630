import React, { useContext } from 'react'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { Tooltip } from 'antd';

const DealCmpProfile = ({ profile }) => {
  console.log(profile, "ch prof")

  const { profilekeys } = useContext(TableHeaderContext);

  const renderBoardInfo = (boardInfo) => {
    if (!boardInfo || boardInfo.length === 0) return null;

    
    const concatenatedInfo = boardInfo
      .map(info => `${info.ExecutiveName} (${info.Designation})`)
      .join(", ");

      if (concatenatedInfo?.length > 35) {
        return (
          <Tooltip
            color="white"
            title={
              <div style={{ color: 'black', width: "100%"}}>
                {boardInfo.map((info, index) => {
                  const name = info.ExecutiveName;
                  const additionalInfo = [info.Designation, info.Company].filter(Boolean).join(', ');
                  const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : `${name} site:linkedin.com`;
                  return (
                    <p key={index} className="my-1">
                      <a
                        href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#a07924] hover:text-[#a07924]"
                      >
                        {name}
                      </a>
                      {additionalInfo && `, ${additionalInfo}.`}
                    </p>
                  );
                })}
              </div>
            }
            placement="topLeft"
          >
            <span className='block w-full'>
              <a className="text-[#a07924]">
                {boardInfo[0].ExecutiveName},{" "}
              </a>
              {boardInfo[0].Designation},{" "}{boardInfo[0].Company}{boardInfo?.length > 1 && "..."}
            </span>
          </Tooltip>
        );
      } else {
        return boardInfo.map((info, index) => {
          const name = info.ExecutiveName;
          const additionalInfo = [info.Designation, info.Company].filter(Boolean).join(', ');
          const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : `${name} site:linkedin.com`;
          return (
            <p key={index} className="my-1">
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#a07924]"
              >
                {name}
              </a>
              {additionalInfo ? ', ' + additionalInfo : ""}
            </p>
          );
        });
      }
    };


  return (
    <div className='mx-5'>
        <p className="text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">Company Profile</p>
        <div className="mt-2 grid grid-cols-1  break-words">
          {profilekeys.map((keyObj, index) => {
            const key = keyObj.accessor;
            const label = keyObj.label;
            let valueToRender = null;

            if (key === "management_info") {
              const managementInfo = profile[key];
              if (managementInfo && managementInfo.length > 0) {
                valueToRender = managementInfo
                  .map(
                    (info) => `${info.ExecutiveName}, ${info.Designation}`
                  )
                  .join("; ");
              } else valueToRender = "--";
            } else if (key === "board_info") {
              valueToRender = renderBoardInfo(profile[key]);
            } else if (key === "location") {
              const city = profile[key];

              if (city && city.city) {
                  // Initialize an array to hold the parts of the address
                  const parts = [];

                  // Check each property and add it to the parts array if it's not null or empty
                  if (city.city) parts.push(city.city);
                  if (city.state) parts.push(city.state);
                  if (city.region) parts.push(city.region);
                  if (city.country) parts.push(city.country);

                  // Join the parts with a comma and space, or use "--" if no valid parts exist
                  valueToRender = parts.length > 0 ? parts.join(', ') : '--';
              } else {
                  valueToRender = '--';
              }
            } else {
              valueToRender =
                profile[key] && profile[key] !== ""
                  ? profile[key]
                  : null;
            }
            if (valueToRender !== null) {
              return (
                <div key={index} className="pr-4">
                  <>
                    {valueToRender.length > 30 ? (
                      <div className={`${index === profilekeys.length - 1 ? "" : "border-b"} "leading-[21px] font-sans_book_body mt-1"`}>
                        <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                          <span
                            className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                            readOnly
                          >
                            {label}
                          </span>
                        </div>
                        <Tooltip
                          title={
                            <>
                              <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                <span
                                  className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                  readOnly
                                >
                                  {label}
                                </span>
                              </div>
                              {label === "Website" ? (
                                <a
                                  href={valueToRender}
                                  className="mt-1 mb-2 hover:text-[#bc8b20] text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                  target="_blank"
                                >
                                  {valueToRender}
                                </a>
                              ) : (
                                <p
                                  className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semiboldd"
                                  readOnly
                                >
                                  {valueToRender}
                                </p>
                              )}
                            </>
                          }
                          color="white"
                          placement="topLeft"
                        >
                          {label === "Website" ? (
                            <p
                              className="w-[90%] overflow-ellipsis overflow-hidden whitespace-nowrap mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                              readOnly
                            >
                              <a
                              href={valueToRender}
                              target="_blank"
                              >
                                {valueToRender}
                              </a>
                            </p>
                          ) : (
                            <p
                              className="w-[90%] overflow-ellipsis overflow-hidden whitespace-nowrap mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                              readOnly
                            >
                              {valueToRender}
                            </p>
                          )}
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="border-b leading-[21px] font-sans_book_body mt-1">
                        <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                          <span
                            className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                            readOnly
                          >
                            {label}
                          </span>
                        </div>
                        {label === "Website" ? (
                          <a
                            href={valueToRender}
                            className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                            target="_blank"
                          >
                            {valueToRender}
                          </a>
                        ) : (
                          <p
                            className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                            readOnly
                          >
                            {valueToRender}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
    </div>
  )
}

export default DealCmpProfile