import React, {  useContext } from 'react'
import { useLocation } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'
// import Card from '../../Components/Investor/InvestorPageCard/Card'
import Layout from '../../Components/InvestorLayout'
import MainMenu from '../../Components/Investor/LimitedPartnerMainmenu'
// import RefineSearch from '../../Components/RefineSearch'
import Table from '../../Components/InvestorLimitedPartner/TableComponent'
import FilterTag from '../../Components/Tag'
// import RefineSearch from '../../Components/Investor/RefineSearchComponent'
// import {INVESTOR_TABLE_QUERY} from '../../Queries/Pe';
// import ChatIcon from '../../images/Group 13/png/Group 13.png';
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
// import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
import LimitedPartBreadCrum from './LimitedPartBreadCrum'
import Constants from '../../Constants'
import LimitedPartnerRefineSearch from '../../Components/RefineSearch/LimitedPartnerRefineSearch'
import RefineSearch from '../../Components/RefineSearchComponent';
import { PeFilterContext } from '../../Contexts/PeFilterContext'

/**
 * The function `InvestorLimitedPartnerExitsPmPe` renders a React component that displays a table of
 * limited partner exits in the private equity and venture capital industry.
 * @returns The function `InvestorLimitedPartnerExitsPmPe` returns a JSX element, which represents the
 * structure and content of a React component.
 */
const InvestorLimitedPartnerExitsPmPe = () => { 
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    const {refineSearchFilters,isMobile} = useContext(PeFilterContext);       
    return (
    <div>
        <>
            <Layout>
                <div className="flex h-screen flex-col ">
                    {/* Top nav*/}
                  {/* <div className='sm:full mt-[3rem] pt-2 h-full bg-[#EDEDED]'> */}
                       {/* <div className='border-b-2 border-[#e5e5e5]'></div> */}
                       {/* <TopSection/> */}
                    {/* <Card card1={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Investors':''):''} card2={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Invesstments':''):''}/> */}
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex sm:full mt-[4.25rem] md:mt-[3rem]">
                            {/* Primary column */}
                            <section
                                className="flex h-full min-w-0 mt-3 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                            >
                                {/* Your content */}
                                <FilterTag />                                
                                <LimitedPartBreadCrum subMenu="vc" />
                                    <MainMenu active={2} menu="investor" subMenu="public_pevc" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_pm_limited_partners_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_limited_partner_exit_pm}/>
                                    <div className="overflow-y-auto">
                                        <Table menu="exit-pm-pe-vc" sub_type_master_id={Constants.subCategoryIds.pe_lp_exitspm_id}/> 
                                    </div>                                
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                                    <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                        {/* Your content */}
                            
                                            <RefineSearch>
                                            {/* <InvestmentRefineSearch /> */}
                                            <LimitedPartnerRefineSearch customPathname={location.pathname}/>
                                        </RefineSearch>
                                    </div>
                                </aside> 
                        </main>
                    </div>                   
                </div>
            </Layout>
        </>
    </div>
    )
}

export default InvestorLimitedPartnerExitsPmPe