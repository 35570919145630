import React, { useState, useEffect } from "react";
import Table from "./Table";
import Summary from "./Summary";
import Layout from "../../components/layout/index";
import './league.css'
import CategorySelector from "./CategorySelector";
import headerimg from "../../assets/assets/leage_table.png";

const League = () => {
  const [category, setCategory] = useState("t");
  const [subCategory, setSubCategory] = useState("pe");
  const fromDate = "2013-01-01"
  const toDate = '2023-03-31';
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [sortedData, setSortedData] = useState(tableData);

  const toggleMainTableHandler = (mainTable) => {
    setCategory(mainTable);
  };
  
  const toggleSubTableHandler = (subTable) => {
    setSubCategory(subTable);
  };

  useEffect(() => {
    setSortedData(tableData);
  }, [tableData]);

  return (
    <Layout>
      <div 
        className="relative bg-cover bg-center h-[30vh] md:h-[40vh] lg:h-[50vh]"
        style={{
          backgroundImage: ` url(${headerimg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white tracking-wider">
            LEAGUE TABLES
          </h2>
        </div>
      </div>

      <div className="container mx-auto px-4 -mt-16 relative z-10">
        <div className="bg-white rounded-lg shadow-lg p-6 lg:mx-32 md:mx-2">
          <Summary fromDate={fromDate} toDate={toDate} />
        </div>
      </div>

      <div className="font-sans text-black mt-8">
        <div className="lg:mx-32 md:mx-2">
          <CategorySelector 
            category={category}
            toggleMainTableHandler={toggleMainTableHandler}
            toggleSubTableHandler={toggleSubTableHandler}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            fromDate={fromDate}
            toDate={toDate}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            setTableData={setTableData}
            setLoading={setLoading}
            showSearchForm={showSearchForm}
            setShowSearchForm={setShowSearchForm}
            sortedData={sortedData}
          />
        </div>
        
        <div className="mt-6">
          <Table 
            loading={loading} 
            subCategory={subCategory} 
            sortedData={sortedData} 
            setSortedData={setSortedData}  
            tableData={tableData} 
            setShowSearchForm={setShowSearchForm} 
            showSearchForm={showSearchForm} 
          />
        </div>
      </div>
    </Layout>
  );
};

export default League;