import React from "react";
import Container from "../../../components/layout/Container";
import funding from "../../../assets/Funding DB.jpeg";
import Button from "../../../components/utils/Button";

const Explore = () => {
  return (
    <div className="bg-white">
      <Container>
        <h1 className="my-4 py-4 text-center text-3xl font-bold xl:text-left">
          Ready to explore?
        </h1>
        <div className="flex flex-col items justify-between gap-4 lg:flex-row">
          <div className="lg:max-w-lg">
            <h1 className="my-4 text-gray-800 font-bold text-3xl">
              Comprehensive Coverage across Geos and Stages
            </h1>
            <div className="my-8 flex flex-col gap-2 text-base text-gray-600">
              All funding transactions related data points including Round Type
              (Equity, Debt), Round Name (Seed, Series A, Series B, Series C+),
              Amount raised, Details on lead Investors and facilitators involved
              Coverage of 350k+ funding rounds globally Rounds distributed
              across stages
            </div>
            <Button>View</Button>
          </div>
          <div>
            <img
              src={funding}
              alt=""
              className="shadow-xl rounded-xl md:max-w-xl"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Explore;
