import React, { useContext } from 'react'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext';
import useModule from '../../hooks/useModule';
import { descriptionCharLimit } from '../../Config/config';
import { matchPath, useLocation } from 'react-router';
import routePathSegments from '../../routeSegments';

const DealMoreInfo = (props) => {

    const { isMA } = useModule();
    const { openRequestPopup } = useContext(RaiseRequestContext)
    const location = useLocation();
    const isPeMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname)

    let longText =
        props?.page == "dealPage"
        ? props?.data?.moreInformation
        : props?.page == "madealPage" ?
            props?.data?.deal_details

            : props?.data?.business_desc
    
    const charLength = descriptionCharLimit;

    const component = props?.activeTab == "investments" ? isPeMatch != null ? "moreInfoInvestment" : "moreDealVc"
        : props?.activeTab == "angels" ? "moreInfoAngel"
        : props?.activeTab == "incubations" ? "moreInfoIncubation"
            : props?.activeTab == "ipos" ? "moreInfoIncubation"
            : props?.activeTab == 'exits' ? "moreInfoIncubation"
                : isPeMatch != null ? "moreInfoInvestment" : "moreDealVc"
    
  return (
    <div className="px-2">
    
        {longText != "" ? (
        <>
            {props?.activeTab == "investments" ||
                props?.activeTab == "incubations" ||
                props?.activeTab == "angels" ||
                props?.activeTab == "exits"
                || props?.page == "madealPage"
                ? (
                    <p className="mx-3 text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">Deal Details</p>
                ) : props?.page == "companyPage" || props?.page == "reCompanyPage"|| props.page === "fillingsPage" ? (
                " "
                ) : (
                    <p className="mx-3 text-[14pt] leading-[18pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Ipo Structure:
                </p>
            )}
            <p className="m-3 whitespace-pre-line text-[11pt] leading-[18pt] py-1  lg:text-[14px] text-[#333333] lg:leading-[20px]">
            
                {longText ?? ""}
            
            {props?.page == "companyPage" || props?.page == "reCompanyPage" ? (
                " "
            ) : (
                <p className="mt-6">
                {/* <button className='text-[#A5711A] hover:underline' onClick={()=>openRequestPopup(component)}>Click Here</button> */}
                {props?.activeTab == "investments" ?
                    <>
                    <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isMA ? 'mandaDealAdvisorInfo': component)}>Request more information</button>&nbsp;
                    specifying what details you would like. Note: For recent transactions, regulatory filing based information is typically less than that for older ones.
                    </>
                    : props?.activeTab == "incubations" || props?.activeTab == 'ipos' ?
                    <>
                        <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isMA ? 'mandaDealAdvisorInfo': component)}>Click Here</button>&nbsp;
                        to request more details for this deal.
                    </>
                    : props?.activeTab == 'exits' ?
                        <>
                        <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isMA ? 'mandaDealAdvisorInfo': component)}>Request more information</button>&nbsp;
                        specifying what details you would like.
                        </>
                        : props?.activeTab == "angels" ?
                        <>
                            <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isMA ? 'mandaDealAdvisorInfo': component)}>Click Here</button>&nbsp;
                            to request more details (financials, valuations, etc.) to the extent available for this deal.
                        </>
                        :
                        <>
                            <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isMA ? 'mandaDealAdvisorInfo': component)}>Request more information</button>&nbsp;
                            specifying what details you would like. Note: For recent transactions, regulatory filing based information is typically less than that for older ones.
                        </>
                }</p>
            )}
            </p>
        </>
        )
        :
        ""
        }
    </div>
  )
}

export default DealMoreInfo