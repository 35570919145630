import React from 'react';

const ImageCard = ({ title, description, link, linkText, ds_link }) => {
    return (
        <div className="card lg:w-[248px] xl:w-[248px] md:w-[228px] w-[238px] bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="card__info p-4">
                <span className="card__category font-bold text-sm block mb-2">
                    {title}
                </span>
                <a href={ds_link} className="block mb-4">
                    <p className="card__title font-medium text-[15px] text-gray-700 hover:text-gray-900 transition-colors">
                        {description}
                    </p>
                </a>
                <span className="card__by">
                    <a 
                        href={link} 
                        target="_blank" 
                        rel='noreferrer' 
                        className="card__author  hover:text-[#d6b15f] text-sm font-medium transition-colors"
                    >
                        {linkText}
                    </a>
                </span>
            </div>
        </div>
    );
};

export default ImageCard;