import React from "react";

const Summary = ({ fromDate, toDate }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formattedFromDate = formatDate(fromDate);
  const formattedToDate = formatDate(toDate);

  return (
    <section>
      <div className="text-[white] text-center">
        <h3 className="m-5 mt-8 text-2xl font-bold text-slate-600">
          League Tables - Updated Now For {formattedFromDate} -{" "}
          {formattedToDate}
        </h3>
        <p className="m-4 leading-5 text-slate-700 text-sm md:text-base md:mx-24">
          The Venture Intelligence League Tables, the first such initiative
          exclusively tracking transactions involving India-based companies, are
          based on value of PE and M&A transactions advised by Transaction and
          Legal Advisory firms during the calendar year. The League Tables are
          compiled based on transactions submitted by the advisory firms and
          filtered using Venture Intelligence definitions
        </p>
      </div>
    </section>
  );
};

export default Summary;
