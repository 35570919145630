import { Badge } from 'antd';
import React, { useEffect, useState ,useRef} from 'react'
import { useLocation } from 'react-router';
import MultiSelectEntity from '../RefineSearch/TgMultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import { filterPeCompanies, FilterRecompany , filterTargetCompany } from "../../Config/config"
import useScrollIntoView from './useScrollIntoView';
function TargetCompany({ open, accordionClick, options, setOptions, customPathname, label, menu }) {

    const [companyVal, setCompanyVal] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const shouldScroll = open || fetchedData.length > 0;
    const listItemRef = useScrollIntoView(shouldScroll);


    useEffect(() => {
        if (companyVal !== "") {
            fetchData();
        }
    }, []); 
    const categoryval = customPathname?.includes("exits") ? "exits" :  customPathname?.includes("backed-ipos") ? "ipo" :  customPathname?.includes("angel-invesments") ? "angel" :  customPathname?.includes("incubation") ? "incubation" : "investments"

    const fetchData = () => {
        const payload = {
            search_key: companyVal,
            category : menu
        };
        const filter = customPathname?.includes("/re/") ? FilterRecompany : customPathname?.includes("/ma/") ? filterTargetCompany : filterPeCompanies;

        if (customPathname?.includes("/ma/")) {
            payload.type = 4;
        }

        if(filter === filterPeCompanies){
            payload.category = categoryval
        }
console.log(companyVal,"lkhjytuyg")
        fetch(filter, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data?.results) {
                    // Filter out duplicates from fetched data
                    // const uniqueData = data.results.filter(item => !options.some(option => option.id === item.id));
                    setFetchedData(data?.results); // Store unique fetched data in new state
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }

    useEffect(() => {
        const uniqueFetchedData = fetchedData;
        setOptions(prevOptions => [...prevOptions.filter(item => item.isChecked === true), ...uniqueFetchedData]);
    }, [fetchedData]);

    const handleIndustryChange = ((e) => {
        const { name, checked, id } = e.target;
        let currentIndustry = [];

        if (name === "allSelect") {
            currentIndustry = options.map((industry) =>
                ({ ...industry, isChecked: checked })
            );
            setOptions(currentIndustry);
        } else {
            currentIndustry = options.map((industry) =>
                industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
            );
            setOptions(currentIndustry);
        }
    });


    const selectedIndustryLength = options?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"} ref={listItemRef}>
                <FilterWrapper
                    count={selectedIndustryLength}
                    isSelect={false}
                    label={label}
                    onClick={accordionClick}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        fetchData={fetchData}
                        handleEntityChange={handleIndustryChange}
                        primaryEntity={options}
                        setCompanyVal={setCompanyVal}
                        city="city"
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default TargetCompany;
