const industriesLists = [
    {
        id: '1',
        industryName: 'Advertising & Marketing'
    },
    {
        id: '2',
        industryName: 'Agri-business'
    },
    {
        id: '3',
        industryName: 'BFSI'
    },
    {
        id: '4',
        industryName: 'Diversified'
    },
    {
        id: '5',
        industryName: 'Education'
    },
    {
        id: '6',
        industryName: 'Energy'
    },
    {
        id: '7',
        industryName: 'Engg. & Construction'
    },
    {
        id: '8',
        industryName: 'FMCG'
    },
    {
        id: '9',
        industryName: 'Food & Beverages'
    },
    {
        id: '10',
        industryName: 'Gems & Jewelry'
    },
    {
        id: '11',
        industryName: 'Healthcare & Life Sciences'
    },
    {
        id: '12',
        industryName: 'Hotels & Resorts'
    },
    {
        id: '13',
        industryName: 'IT & ITES'
    },
    {
        id: '14',
        industryName: 'Manufacturing'
    },
    {
        id: '15',
        industryName: 'Media & Entertainment'
    },
    {
        id: '16',
        industryName: 'Mining & Minerals'
    },
    {
        id: '17',
        industryName: 'NBFC'
    },
    {
        id: '18',
        industryName: 'Other Services'
    },
    {
        id: '19',
        industryName: 'Real Estate'
    },
    {
        id: '20',
        industryName: 'Retail'
    },
    {
        id: '21',
        industryName: 'Shipping & Logistics'
    },
    {
        id: '22',
        industryName: 'Sports & Fitness'
    },
    {
        id: '23',
        industryName: 'Telecom'
    },
    {
        id: '24',
        industryName: 'Textiles & Garments'
    },
    {
        id: '25',
        industryName: 'Travel & Transport'
    },
];

export default industriesLists;