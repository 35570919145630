import React from "react";
import Layout from "../../.././Components/AdvisorLayout";
import AdvisorCompanyDetail from "../../.././Components/Advisor/AdvisorDetailPage/NewAdvisorCompanyDetail";
import RefineSearch from "../../.././Components/RefineSearchComponent";
import AdvisorDetailCard from "../../.././Components/Advisor/AdvisorDetailPage/AdvisorDetailCard";
import { LEGAL_ADVISOR_CARD_QUERY } from "../../../Queries/Pe";
import { useSearchParams } from "react-router-dom";
import Constants from "../../../Constants";
import useModule from "../../../hooks/useModule";
import AdvisorInvestmentRefineSearch from "../../../Components/RefineSearch/AdvisorInvestmentsRefineSearch";
import AdvisorExistsRefineSearch from "../../../Components/RefineSearch/AdvisorExistsRefineSearch";
import { routeNames } from "../../../routeSegments";
/**
 * The AdvisorDetail function is a React component that renders a layout with a primary column and a
 * secondary column, and includes a card component and a refine search component.
 * @returns The function `AdvisorDetail` returns a JSX element, which represents the UI component to be
 * rendered on the screen.
 */
function NewAdvisorDetail({ menu, advisorType }) {
  const [URLSearchParams, SetURLSearchParams] = useSearchParams({
    section: Constants.primaryMasterSubIds.pe_vc_advisors_id,
  });

  const section = parseInt(URLSearchParams.get("section"));
  const { isPE } = useModule();

  let exitCustomPath = isPE
    ? routeNames.pe_advisors_ma_advisors_exits
    : routeNames.vc_advisors_ma_vc_advisors_exits;

  if (section === Constants.primaryMasterSubIds.pe_vc_advisors_ma_id) {
    exitCustomPath = isPE
      ? routeNames.pe_advisors_ma_pe_vc_advisors
      : routeNames.vc_advisors_ma_vc_advisors;
  } else if (section === Constants.primaryMasterSubIds.pe_vc_advisors_pm_id) {
    exitCustomPath = isPE
      ? routeNames.pe_advisors_pm_pe_vc_advisors
      : routeNames.vc_advisors_pm_vc_advisors;
  }

  return (
    <div>
      <Layout>
        <div className="flex h-screen flex-col">
          {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
          <AdvisorDetailCard
            LEGAL_ADVISOR_CARD_QUERY={LEGAL_ADVISOR_CARD_QUERY}
          />
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Primary column */}
            <section
              aria-labelledby="primary-heading"
              className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
            >
              {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
              <AdvisorCompanyDetail section={section} menu={menu} advisorType={advisorType}/>

              {/* <Outlet /> */}
              {/* Your content */}
            </section>
            {/* Secondary column (hidden on smaller screens) */}
            <aside className="order-first block flex-shrink-0">
              <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearch>
                  {section ===
                  Constants.primaryMasterSubIds.pe_vc_advisors_id ? (
                    <AdvisorInvestmentRefineSearch
                      customPathname={
                        isPE
                          ? routeNames.pe_advisors_pe_vc_advisors
                          : routeNames.vc_advisors_vc_advisors
                      }
                    />
                  ) : (
                    <AdvisorExistsRefineSearch
                      customPathname={exitCustomPath}
                    />
                  )}
                </RefineSearch>
              </div>
            </aside>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default NewAdvisorDetail;
